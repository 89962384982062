import React, { useState, useEffect } from 'react';

export default function ManualsCategoryManager({
    selectedCategory,
    setSelectedCategory,
    selectedSubcategory,
    setSelectedSubcategory,
    fetchCategories,
    categories,
    setCategories, // Add this
}) {
    const [categoryTitle, setCategoryTitle] = useState('');
    const [subcategoryTitle, setSubcategoryTitle] = useState('');
    const [subSubcategoryTitle, setSubSubcategoryTitle] = useState('');
    const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);
    const [subSubcategories, setSubSubcategories] = useState([]);
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [editCategoryTitle, setEditCategoryTitle] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingSubcategoryId, setEditingSubcategoryId] = useState(null);
    const [editSubcategoryTitle, setEditSubcategoryTitle] = useState('');
    const [newSubSubcategoryTitle, setNewSubSubcategoryTitle] = useState('');
    const [editingSubSubcategoryId, setEditingSubSubcategoryId] = useState(null);
    const [editSubSubcategoryTitle, setEditSubSubcategoryTitle] = useState('');
    const [newSubSubcategoryTitles, setNewSubSubcategoryTitles] = useState({});
    const [view, setView] = useState('main'); // "main", "editCategory", or "addSubcategory"
    
    const [categoryDescription, setCategoryDescription] = useState('');
    const [subcategoryDescription, setSubcategoryDescription] = useState('');
    const [subSubcategoryDescription, setSubSubcategoryDescription] = useState('');
    const [editCategoryDescription, setEditCategoryDescription] = useState('');
    const [editSubcategoryDescription, setEditSubcategoryDescription] = useState('');
    const [editSubSubcategoryDescription, setEditSubSubcategoryDescription] = useState('');
    const [descriptions, setDescriptions] = useState({});

    const [icons, setIcons] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(null); // Selected icon for a category
    const [editSubcategoryIcon, setEditSubcategoryIcon] = useState(null); // For subcategory icon
    const [editSubSubcategoryIcon, setEditSubSubcategoryIcon] = useState(null); // For sub-subcategory icon
    const [subcategoryIcons, setSubcategoryIcons] = useState({}); // Icons for each subcategory
    const [subSubcategoryIcons, setSubSubcategoryIcons] = useState({}); // Icons for each sub-subcategory


    
    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/manuals-icons`); // Endpoint for icons
                if (response.ok) {
                    const data = await response.json();
                    setIcons(data);
                } else {
                    console.error('Failed to fetch icons');
                }
            } catch (error) {
                console.error('Error fetching icons:', error);
            }
        };
    
        fetchIcons();
    }, []);
    


    useEffect(() => {
        fetchCategories();
    }, []);

    // Fetch subcategories when a category is selected
    const fetchSubcategories = async (categoryId) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`
          );
          const data = await response.json();
          setSubcategories(data);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };

       // Fetch subcategories when a category is selected
       const handleCategoryChange = async (categoryId) => {
        // console.log('Manage Categories - Selected Category ID:', categoryId);
        const selectedCategory = categories.find((cat) => cat.id === Number(categoryId));
        setSelectedCategory(selectedCategory);
        setSelectedSubcategory(null);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`);
            const data = await response.json();
            // console.log('Manage Categories - Fetched Subcategories:', data);
            setSubcategories(data);
            // console.log('Fetched Subcategories:', data); 
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };
    
    
    useEffect(() => {
        // console.log('Updated Subcategories:', subcategories); 
    }, [subcategories]);

    const handleCategoryDescriptionChange = (categoryId, value) => {
        setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [categoryId]: value, // Update only the specific category's description
        }));
    };

    const handleSubcategoryDescriptionChange = (subcategoryId, value) => {
        setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [subcategoryId]: value, // Update only the specific subcategory's description
        }));
    };

    const handleSubSubcategoryDescriptionChange = (subSubcategoryId, value) => {
        setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [subSubcategoryId]: value, // Update only the specific sub-subcategory's description
        }));
    };
    

    // Fetch sub-subcategories when a subcategory is selected
    const handleSubcategoryChange = async (subcategoryId) => {
    
        const selectedSubcategory = subcategories.find((sub) => sub.id === subcategoryId);
        setSelectedSubcategory(selectedSubcategory);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}/sub-subcategories`);
            if (response.ok) {
                const data = await response.json();
                console.log('Fetched sub-subcategories:', data); // Debugging
                // Attach sub-subcategories to the specific subcategory
                setSubcategories((prevSubcategories) =>
                    prevSubcategories.map((sub) =>
                        sub.id === subcategoryId ? { ...sub, subSubcategories: data } : sub
                    )
                );
            } else {
                console.error('Failed to fetch sub-subcategories.');
            }
        } catch (error) {
            console.error('Error fetching sub-subcategories:', error);
        }
    };
    
    

    // Fetch manuals when a sub-subcategory is selected
   
    
    // Handle create category
    // const handleCreateCategory = async () => {
    //     if (!categoryTitle.trim()) {
    //         alert('Category title cannot be empty.');
    //         return;
    //     }
    
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ title: categoryTitle }),
    //         });
    
    //         if (response.ok) {
    //             const newCategory = await response.json(); // Get the created category
    //             setCategories((prevCategories) => [...prevCategories, newCategory]); // Update state immediately
    //             setCategoryTitle(''); // Clear input
    //             fetchCategories();
    //             alert('Category created successfully!');
    //         } else {
    //             console.error('Failed to create category');
    //             alert('Error creating category.');
    //         }
    //     } catch (error) {
    //         console.error('Error creating category:', error);
    //     }
    // };

    const handleCreateCategory = async () => {
      if (!categoryTitle.trim()) {
          alert('Category title is required.');
          return;
      }
  
      try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  title: categoryTitle,
                  description: categoryDescription || null,
                  icon: selectedIcon, // Pass selected icon
              }),
          });
  
          if (response.ok) {
              const newCategory = await response.json();
              setCategories((prev) => [...prev, newCategory]);
              setCategoryTitle('');
              setCategoryDescription('');
              setSelectedIcon(null); // Reset icon
              alert('Category created successfully!');
          }
      } catch (error) {
          console.error('Error creating category:', error);
      }
  };
  

    
    
    
    // const handleCreateSubcategory = async () => {
    //     if (!subcategoryTitle.trim()) {
    //         alert('Subcategory title cannot be empty.');
    //         return;
    //     }
    
    //     if (!editingCategory) {
    //         alert('Please select a category to add the subcategory.');
    //         return;
    //     }
    
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ categoryId: editingCategory.id, title: subcategoryTitle }),
    //         });
    
    //         if (response.ok) {
    //             const newSubcategory = await response.json(); // Get the created subcategory
    //             setSubcategories((prevSubcategories) => [...prevSubcategories, newSubcategory]); // Update state immediately
    //             setSubcategoryTitle(''); // Clear input
    //             handleCategoryChange(editingCategory.id);
    //             alert('Subcategory created successfully!');
    //         } else {
    //             console.error('Failed to create subcategory');
    //             alert('Error creating subcategory.');
    //         }
    //     } catch (error) {
    //         console.error('Error creating subcategory:', error);
    //     }
    // };
    
    const handleCreateSubcategory = async () => {
      if (!subcategoryTitle.trim()) {
        alert('Subcategory title is required.');
        return;
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            categoryId: editingCategory.id,
            title: subcategoryTitle,
            description: subcategoryDescription || null, // Optional description
            icon: editSubcategoryIcon || null, // Optional icon
          }),
        });
    
        if (response.ok) {
          const newSubcategory = await response.json();
          setSubcategories((prev) => [...prev, newSubcategory]);
          setSubcategoryTitle('');
          setSubcategoryDescription('');
          setEditSubcategoryIcon(null); // Reset selected icon
          alert('Subcategory created successfully!');
        }
      } catch (error) {
        console.error('Error creating subcategory:', error);
      }
    };
    
    

// Create a new sub-subcategory
// const handleCreateSubSubcategory = async (subcategoryId) => {
//     const title = newSubSubcategoryTitles[subcategoryId] || ''; // Get the title for this subcategory

//     if (!title.trim()) {
//         alert('Sub-subcategory title cannot be empty.');
//         return;
//     }

//     try {
//         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ subcategoryId, title }),
//         });

//         if (response.ok) {
//             const data = await response.json();
//             console.log('Sub-subcategory created:', data);

//             // Clear the input field
//             setNewSubSubcategoryTitles((prevTitles) => ({
//                 ...prevTitles,
//                 [subcategoryId]: '',
//             }));

//             // Fetch and update the sub-subcategories for this subcategory
//             await handleSubcategoryChange(subcategoryId);
//         } else {
//             console.error('Failed to create sub-subcategory.');
//             alert('Error creating sub-subcategory.');
//         }
//     } catch (error) {
//         console.error('Error creating sub-subcategory:', error);
//     }
// };

// const handleCreateSubSubcategory = async (subcategoryId) => {
//   if (!newSubSubcategoryTitles[subcategoryId]?.trim()) {
//     alert('Sub-Subcategory title is required.');
//     return;
//   }

//   try {
//     const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories`, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         subcategoryId,
//         title: newSubSubcategoryTitles[subcategoryId],
//         description: subSubcategoryDescription || null,
//         icon: editSubSubcategoryIcon || null, // Optional icon
//       }),
//     });

//     if (response.ok) {
//       await handleSubcategoryChange(subcategoryId); // Refresh sub-subcategories
//       setEditSubSubcategoryIcon(null); // Reset selected icon
//       setSubSubcategoryDescription('');
//       alert('Sub-Subcategory created successfully!');
//     }
//   } catch (error) {
//     console.error('Error creating sub-subcategory:', error);
//   }
// };

const handleCreateSubSubcategory = async (subcategoryId) => {
  const title = newSubSubcategoryTitles[subcategoryId];
  const description = subSubcategoryDescription || null;
  const icon = editSubSubcategoryIcon || null;

  if (!title?.trim()) {
    alert('Sub-subcategory title is required.');
    return;
  }

  console.log('Creating sub-subcategory with:', {
    subcategoryId,
    title,
    description,
    icon, // Check if the icon is being sent
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subcategoryId,
        title,
        description,
        icon, // Include the icon in the payload
      }),
    });

    if (response.ok) {
      console.log('Sub-subcategory created successfully.');
      await handleSubcategoryChange(subcategoryId); // Refresh subcategories
      setEditSubSubcategoryIcon(null); // Reset icon selection
      setSubSubcategoryDescription('');
      alert('Sub-subcategory created successfully!');
    } else {
      const errorText = await response.text();
      console.error('Failed to create sub-subcategory:', errorText);
    }
  } catch (error) {
    console.error('Error creating sub-subcategory:', error);
  }
};




// Delete subcategory
const handleDeleteSubcategory = async (subcategoryId) => {
    if (!window.confirm('Are you sure you want to delete this subcategory?')) return;
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Failed to delete subcategory:', errorText);
        } else {
            // console.log('Subcategory deleted successfully');
            handleCategoryChange(selectedCategory.id); // Refresh subcategories
        }
    } catch (error) {
        console.error('Error deleting subcategory:', error);
    }
};


 // Delete sub-subcategory
 const handleDeleteSubSubcategory = async (subSubcategoryId) => {
    if (!window.confirm('Are you sure you want to delete this sub-subcategory?')) return;

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategoryId}`, {
            method: 'DELETE',
        });

        if (response.ok) {
            // console.log('Sub-subcategory deleted successfully.');
            // Re-fetch subcategories and sub-subcategories for the selected subcategory
            if (selectedSubcategory) {
                await handleSubcategoryChange(selectedSubcategory.id);
            }
        } else {
            console.error('Failed to delete sub-subcategory.');
        }
    } catch (error) {
        console.error('Error deleting sub-subcategory:', error);
    }
};


const handleSubSubcategoryTitleChange = (subcategoryId, value) => {
    setNewSubSubcategoryTitles((prevTitles) => ({
        ...prevTitles,
        [subcategoryId]: value, // Set title for the specific subcategory
    }));
};

    // Function to update a category
    // const handleUpdateCategory = async () => {
    //     if (!editingCategory || editCategoryTitle.trim() === '') return;

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${editingCategory.id}`, {
    //             method: 'PUT',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ title: editCategoryTitle }),
    //         });

    //         if (response.ok) {
    //             setEditingCategory(null);
    //             setEditCategoryTitle('');;
    //             fetchCategories();
    //             setView('main');
    //         }
    //     } catch (error) {
    //         console.error('Error updating category:', error);
    //     }
    // };

    const handleUpdateCategory = async (e) => {
      e.preventDefault();
  
      if (!editCategoryTitle.trim()) {
          alert('Title is required.');
          return;
      }
  
      try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${editingCategory.id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  title: editCategoryTitle,
                  description: editCategoryDescription.trim() || null,
                  icon: selectedIcon || editingCategory.icon, // Update or keep the existing icon
              }),
          });
  
          if (response.ok) {
              setEditingCategory(null);
              setEditCategoryTitle('');
              setEditCategoryDescription('');
              setSelectedIcon(null); // Reset icon
              await fetchCategories();
              alert('Category updated successfully!');
              setView('main');
          } else {
              alert('Failed to update the category.');
          }
      } catch (error) {
          console.error('Error updating category:', error);
      }
  };
  
  

    // Handle edit category
   const handleEditCategory = async (category) => {
    setEditingCategory(category);
    setEditCategoryTitle(category.title);
    setView('editCategory');

    // Fetch subcategories for the selected category
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${category.id}/subcategories`);
        const data = await response.json();
        setSubcategories(data); // Update state with fetched subcategories
    } catch (error) {
        console.error('Error fetching subcategories:', error);
    }
};

const handleBackToMain = () => {
    setView('main'); // Go back to the main view
};

const handleEditSubcategoryView = (subcategory) => {
    setEditingSubcategoryId(subcategory.id);
    setEditSubcategoryTitle(subcategory.title);
    setView('editSubcategory'); // Set view to 'editSubcategory'
};


    // Function to delete a category
    const handleDeleteCategory = async (categoryId) => {
        if (!window.confirm('Are you sure you want to delete this category?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                fetchCategories(); // Refresh categories
            } else {
                console.error('Failed to delete category');
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    // Edit subcategory (enter edit mode)
    const handleEditSubcategory = (subcategory) => {
      if (!subcategory || !subcategory.id) {
        console.error('Error: Invalid subcategory for editing:', subcategory);
        return;
      }
    
      setEditingSubcategoryId(subcategory.id);
      setEditSubcategoryTitle(subcategory.title || ''); // Default to empty string
      setEditSubcategoryDescription(subcategory.description || ''); // Default to empty string
      setEditSubcategoryIcon(subcategory.icon || null); // Default to null
    };
    
  

  const handleEditSubcategorySave = () => {
    const subcategory = subcategories.find((sub) => sub.id === editingSubcategoryId);

    if (!subcategory) {
        console.error('Error: Could not find subcategory with id:', editingSubcategoryId);
        return;
    }

    handleUpdateSubcategory(subcategory);
};


  
  

// Edit sub-subcategory (enter edit mode)
const handleEditSubSubcategory = (subSubcategory) => {
    setEditingSubSubcategoryId(subSubcategory.id); // Set the sub-subcategory being edited
    setEditSubSubcategoryTitle(subSubcategory.title || ''); // Autofill title
    setEditSubSubcategoryDescription(subSubcategory.description || ''); // Autofill description
};

const handleSubcategoryIconSelect = (subcategoryId, icon) => {
  setSubcategoryIcons((prev) => ({
    ...prev,
    [subcategoryId]: icon, // Store the icon for the specific subcategory
  }));
};

const handleSubSubcategoryIconSelect = (subSubcategoryId, icon) => {
  console.log(`Icon selected for sub-subcategory ${subSubcategoryId}:`, icon);
  setSubSubcategoryIcons((prev) => ({
    ...prev,
    [subSubcategoryId]: icon, // Store the selected icon
  }));
  setEditSubSubcategoryIcon(icon); // Update the current icon state
};




// Update subcategory
// const handleUpdateSubcategory = async () => {
//     if (!editSubcategoryTitle.trim()) return;

//     try {
//         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${editingSubcategoryId}`, {
//             method: 'PUT',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ title: editSubcategoryTitle }),
//         });

//         if (response.ok) {
//             // Update the subcategory list by fetching them again
//             const updatedSubcategories = subcategories.map((subcategory) =>
//                 subcategory.id === editingSubcategoryId
//                     ? { ...subcategory, title: editSubcategoryTitle }
//                     : subcategory
//             );
//             setSubcategories(updatedSubcategories);
//             setEditingSubcategoryId(null); // Exit edit mode
//             setEditSubcategoryTitle(''); // Clear input
//         } else {
//             console.error('Failed to update subcategory');
//         }
//     } catch (error) {
//         console.error('Error updating subcategory:', error);
//     }
// };

const handleUpdateSubcategory = async (subcategory) => {
  // Find subcategory using state if not explicitly passed
  const validSubcategory =
    subcategory ||
    subcategories.find((sub) => sub.id === editingSubcategoryId);

  // Check if the subcategory is valid
  if (!validSubcategory || !validSubcategory.id) {
    console.error('Error: Invalid subcategory or subcategory ID:', validSubcategory);
    return; // Exit early to prevent further errors
  }

  // Ensure the title is not empty
  if (!editSubcategoryTitle.trim()) {
    alert('Subcategory title is required.');
    return;
  }

  try {
    const icon = subcategoryIcons[validSubcategory.id] || null;

    console.log('Updating subcategory with:', {
      title: editSubcategoryTitle,
      description: editSubcategoryDescription || null,
      icon,
    });

    const response = await fetch(
      `${process.env.REACT_APP_SITE_URL}/oc/subcategories/${validSubcategory.id}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          title: editSubcategoryTitle,
          description: editSubcategoryDescription || null,
          icon,
        }),
      }
    );

    if (response.ok) {
      console.log('Subcategory updated successfully');

      // Cleanup states after update
      setEditingSubcategoryId(null);
      setEditSubcategoryTitle('');
      setEditSubcategoryDescription('');
      setSubcategoryIcons((prevIcons) => {
        const updatedIcons = { ...prevIcons };
        delete updatedIcons[validSubcategory.id]; // Remove updated icon
        return updatedIcons;
      });

      // Refresh subcategories after updating
      await handleCategoryChange(selectedCategory.id);

      alert('Subcategory updated successfully!');
    } else {
      const errorText = await response.text();
      console.error('Failed to update subcategory:', errorText);
    }
  } catch (error) {
    console.error('Error updating subcategory:', error);
  }
};


// const handleUpdateSubSubcategory = async (subSubcategory) => {
//     if (!editSubSubcategoryTitle.trim()) {
//         alert('Sub-subcategory title cannot be empty.');
//         return;
//     }

//     try {
//         const response = await fetch(
//             `${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategory.id}`,
//             {
//                 method: 'PUT',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ title: editSubSubcategoryTitle }),
//             }
//         );

//         if (response.ok) {
//             setEditingSubSubcategoryId(null);
//             setEditSubSubcategoryTitle('');
//             handleSubcategoryChange(subSubcategory.parent_subcategory_id); // Refresh list
//         } else {
//             alert('Failed to update sub-subcategory.');
//         }
//     } catch (error) {
//         console.error('Error updating sub-subcategory:', error);
//     }
// };

// const handleUpdateSubSubcategory = async (subSubcategory) => {
//   if (!editSubSubcategoryTitle.trim()) {
//     alert('Sub-Subcategory title is required.');
//     return;
//   }

//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategory.id}`,
//       {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           title: editSubSubcategoryTitle,
//           description: editSubSubcategoryDescription || null,
//           icon: editSubSubcategoryIcon || null, // Optional icon
//         }),
//       }
//     );

//     if (response.ok) {
//       // Clear editing state
//       setEditingSubSubcategoryId(null);
//       setEditSubSubcategoryTitle('');
//       setEditSubSubcategoryDescription('');
//       setEditSubSubcategoryIcon(null); // Reset selected icon

//       // Refresh sub-subcategories
//       if (selectedSubcategory?.id) {
//         await handleSubcategoryChange(selectedSubcategory.id); // Use existing logic to refresh
//       }

//       alert('Sub-Subcategory updated successfully!');
//     } else {
//       const errorText = await response.text();
//       console.error('Failed to update sub-subcategory:', errorText);
//     }
//   } catch (error) {
//     console.error('Error updating sub-subcategory:', error);
//   }
// };

const handleUpdateSubSubcategory = async (subSubcategory) => {
  if (!editSubSubcategoryTitle.trim()) {
    alert('Sub-subcategory title is required.');
    return;
  }

  const payload = {
    title: editSubSubcategoryTitle,
    description: editSubSubcategoryDescription || null,
    icon: editSubSubcategoryIcon || subSubcategory.icon || null, // Ensure icon is included
  };

  console.log('Payload being sent to update sub-subcategory:', payload);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategory.id}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }
    );

    if (response.ok) {
      console.log('Sub-subcategory updated successfully.');
      await handleSubcategoryChange(subSubcategory.subcategory_id); // Refresh subcategories
      setEditSubSubcategoryIcon(null); // Reset icon selection
      setEditSubSubcategoryDescription('');
      alert('Sub-subcategory updated successfully!');
    } else {
      const errorText = await response.text();
      console.error('Failed to update sub-subcategory:', errorText);
    }
  } catch (error) {
    console.error('Error updating sub-subcategory:', error);
  }
};





return (
    <div>
      {view === "main" && (
        <div>
          <h4>Main Categories</h4>
          <ul className="manuals-modal-categories-list">
            {categories.map((category) => (
              <div key={category.id} className="manuals-modal-categories-list-item">
                <div className="manuals-modal-category-item">
                  <span>{category.title}</span>
                  <button
                    className="manuals-modal-button"
                    onClick={() => handleEditCategory(category)}
                  >
                    Edit
                  </button>
                  <button
                    className="manuals-modal-button"
                    onClick={() => handleDeleteCategory(category.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </ul>
          <div>
                    <div>
              <h4>Select Icon</h4>
              <div className="icons-grid">
                  {icons.map((icon) => (
                      <img
                          key={icon}
                          src={`/assets/manualsicons/${icon}`} // Path to your icons folder
                          alt={icon.name}
                          className={`icon-item ${selectedIcon === icon ? 'selected' : ''}`}
                          onClick={() => setSelectedIcon(icon)} // Set the selected icon
                      />
                  ))}
              </div>
          </div>
            <h4>Create New Category</h4>
            <input
              type="text"
              placeholder="New category title"
              value={categoryTitle}
              onChange={(e) => setCategoryTitle(e.target.value)}
              className="manuals-modal-input"
            />
            <input
              type="text"
              placeholder="Category description"
              value={categoryDescription}
              onChange={(e) => setCategoryDescription(e.target.value)}
              className="manuals-modal-input"
            />
            <button
              className="manuals-modal-button create"
              onClick={handleCreateCategory}
            >
              Create Category
            </button>
          </div>
        </div>
      )}
  
  {view === "editCategory" && editingCategory && (
    <div>
        <h4>Edit Category: {editingCategory?.title}</h4>

        <div>
    <h4>Edit Icon</h4>
    <div className="icons-grid">
        {icons.map((icon) => (
            <img
                key={icon.id}
                src={`/assets/manualsicons/${icon}`}
                alt={icon.name}
                className={`icon-item ${selectedIcon === icon ? 'selected' : ''}`}
                onClick={() => setSelectedIcon(icon)}
            />
        ))}
    </div>
</div>


        <input
            type="text"
            placeholder="Edit category title"
            value={editCategoryTitle}
            onChange={(e) => setEditCategoryTitle(e.target.value)}
            className="manuals-modal-input"
        />
        <input
            type="text"
            placeholder="Category description"
            value={editCategoryDescription}
            onChange={(e) => setEditCategoryDescription(e.target.value)}
            className="manuals-modal-input"
        />
        <button
            className="manuals-modal-button save"
            onClick={(e) => handleUpdateCategory(e)} // Explicitly pass the event
        >
            Save Changes
        </button>
        <button
            className="manuals-modal-button back"
            onClick={() => setView("main")}
        >
            Back to Categories
        </button>
    
  
          <div>
  <h4 className='manuals-center'>Subcategories</h4>
  {subcategories.length > 0 ? (
    <ul className="manuals-modal-categories-list">
      {subcategories.map((subcategory) => (
        <div key={subcategory.id} className="manuals-modal-subcategory-item">
          <div>
            {/* Subcategory Header */}
          {editingSubcategoryId === subcategory.id ? (
            <div>
              {/* Title Input */}
              <input
                type="text"
                placeholder="Edit Subcategory Title"
                value={editSubcategoryTitle}
                onChange={(e) => setEditSubcategoryTitle(e.target.value)}
                className="manuals-modal-input"
              />

              {/* Description Input */}
              <input
                type="text"
                placeholder="Edit Subcategory Description"
                value={editSubcategoryDescription}
                onChange={(e) => setEditSubcategoryDescription(e.target.value)}
                className="manuals-modal-input"
              />

              {/* Icon Selector */}
              <label>Select Icon (Optional):</label>
              <div className="icon-gallery">
                {icons.map((icon) => (
                  <div
                    key={icon}
                    className={`icon-item ${
                      subcategoryIcons[editingSubcategoryId] === icon ? 'selected' : ''
                    }`}
                    onClick={() => handleSubcategoryIconSelect(editingSubcategoryId, icon)}
                  >
                    <img
                      src={`/assets/manualsicons/${icon}`}
                      alt={icon.name || 'Icon'}
                      className="icon-preview"
                    />
                  </div>
                ))}
              </div>

              {/* Save and Cancel Buttons */}
              <div className="manuals-modal-buttons">
              <button
    className="manuals-modal-button save"
    onClick={handleEditSubcategorySave}
>
    Save
</button>
                <button
                  className="manuals-modal-button cancel"
                  onClick={() => setEditingSubcategoryId(null)}
                >
                  Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className='manuals-center'>
                <span
                    className="subcategory-title"
                    onClick={() => {
                        // Toggle visibility of the subcategory content
                        const isCurrentlySelected = selectedSubcategory?.id === subcategory.id;

                        if (!isCurrentlySelected) {
                        handleSubcategoryChange(subcategory.id); // Fetch sub-subcategories if not already selected
                        }

                        setSelectedSubcategory(isCurrentlySelected ? null : subcategory); // Toggle selection
                    }}
                    >
                    {subcategory.title}
                    </span>
                                    
                    <div className="manuals-modal-buttons">
                        <button
                        className="manuals-modal-button edit"
                        onClick={() => handleEditSubcategory(subcategory)}
                        >
                        Edit
                        </button>
                        <button
                        className="manuals-modal-button delete"
                        onClick={() => handleDeleteSubcategory(subcategory.id)}
                        >
                        Delete
                        </button>

                         {/* {selectedSubcategory?.id === subcategory.id && (
      <button
        className="close-button"
        onClick={() => {
          setSelectedSubcategory(null);
          console.log('Subcategory closed');
        }}
      >
        Close
      </button>
                        )} */}
                    </div>
              </div>
            )}
          </div>

          {selectedSubcategory?.id === subcategory.id && (
  <div className="manuals-modal-subsubcategory-grid">
    {subcategory.subSubcategories?.length > 0 &&
      subcategory.subSubcategories.map((subSubcategory) => (
      <div key={subSubcategory.id} className="manuals-modal-subsubcategory-item">
        {editingSubSubcategoryId === subSubcategory.id ? (
          <div>
            <input
              type="text"
              placeholder="Edit Sub-Subcategory Title"
              value={editSubSubcategoryTitle}
              onChange={(e) => setEditSubSubcategoryTitle(e.target.value)}
              className="manuals-modal-input"
            />
            <input
              type="text"
              placeholder="Edit Sub-Subcategory Description"
              value={editSubSubcategoryDescription}
              onChange={(e) => setEditSubSubcategoryDescription(e.target.value)}
              className="manuals-modal-input"
            />
{/* Icon Selector for Sub-Subcategory */}
{/* <div className="icon-selector">
    <p>Select an icon for the subcategory:</p>
    <div className="icon-no-icon">
        <label className={`radio-button ${editSubcategoryIcon === null ? 'selected' : ''}`}>
            <input
                type="radio"
                name="icon"
                checked={editSubcategoryIcon === null}
                onChange={() => setEditSubcategoryIcon(null)}
            />
            <span className="radio-custom"></span>
            No Icon
        </label>
    </div> */}

<div className="icon-selector">
    <h4>Select an Icon</h4>
    <div className="icons-grid">
        {icons.map((icon) => (
            <div
                key={icon}
                className={`icon-item ${editSubSubcategoryIcon === icon ? 'selected' : ''}`}
                onClick={() => handleSubSubcategoryIconSelect(editingSubSubcategoryId, icon)}
            >
                <img
                    src={`/assets/manualsicons/${icon}`}
                    alt={`Icon ${icon}`}
                    className="icon-preview"
                />
            </div>
        ))}
    </div>


    {/* <div className="icon-grid">
        {icons.map((icon) => (
            <div
                key={icon}
                className={`icon-wrapper ${editSubcategoryIcon === icon ? 'selected' : ''}`}
                onClick={() => setEditSubcategoryIcon(icon)}
            >
                <img
                    src={`/assets/manualsicons/${icon}`}
                    alt={`Icon ${icon}`}
                    className="icon"
                />
            </div>
        ))}
    </div> */}
</div>




            <div className="manuals-modal-buttons">
              <button
                className="manuals-modal-button save"
                onClick={() => handleUpdateSubSubcategory(subSubcategory)}
              >
                Save
              </button>
              <button
                className="manuals-modal-button cancel"
                onClick={() => setEditingSubSubcategoryId(null)}
              >
                Cancel
              </button>
            </div>

            
          </div>
        ) : (
          <div>
            
            <span
              className="subsubcategory-title"
              onClick={() => handleEditSubSubcategory(subSubcategory)}
            >
              {subSubcategory.title}
            </span>
            <div className="manuals-modal-buttons">
              <button
                className="manuals-modal-button delete"
                onClick={() => handleDeleteSubSubcategory(subSubcategory.id)}
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
      
    ))}
     <div className="manuals-modal-add-subsubcategory">
      <input
        type="text"
        placeholder="New sub-subcategory title"
        value={newSubSubcategoryTitles[subcategory.id] || ""}
        onChange={(e) =>
          handleSubSubcategoryTitleChange(subcategory.id, e.target.value)
        }
        className="manuals-modal-input"
      />
      <button
        className="manuals-modal-button create"
        onClick={() => handleCreateSubSubcategory(subcategory.id)}
      >
        Add Sub-Subcategory
      </button>
          </div>
  </div>
  
)}

        </div>
      ))}
    </ul>
  ) : (
    <p>No subcategories available.</p>
  )}

  {/* Add New Subcategory */}
  <div>
    <h4>Create New Subcategory</h4>
    <input
      type="text"
      placeholder="New subcategory title"
      value={subcategoryTitle}
      onChange={(e) => setSubcategoryTitle(e.target.value)}
      className="manuals-modal-input"
    />
    <input
      type="text"
      placeholder="Subcategory description"
      value={subcategoryDescription}
      onChange={(e) => setSubcategoryDescription(e.target.value)}
      className="manuals-modal-input"
    />
    <button
      className="manuals-modal-button create"
      onClick={handleCreateSubcategory}
    >
      Create Subcategory
    </button>
  </div>
</div>
        </div>
      )}
    </div>
  );
}  