import React, { useState, useEffect } from 'react';
import FileDetailsAddVehicle from './FileDetailsAddVehicle';

function AddVeh({ onClose }) {
  const [modelName, setModelName] = useState('');
  const [typeId, setTypeId] = useState('');
  const [manufacturerId, setManufacturerId] = useState('');
  const [rangeId, setRangeId] = useState('');
  const [powerOpti, setPowerOpti] = useState('');
  const [torqueOpti, setTorqueOpti] = useState('');
  const [power, setPower] = useState('');
  const [torque, setTorque] = useState('');
  const [obdLocation, setObdLocation] = useState('');
  const [ecuLocation, setEcuLocation] = useState('');
  const [acmLocation, setAcmLocation] = useState('');
  const [notes, setNotes] = useState('');
  const [fueltype, setFueltype] = useState('');
  const [rangeName, setRangeName] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');
  const [ranges, setRanges] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [types, setTypes] = useState([]);
  const [activeForm, setActiveForm] = useState('vehicle');
  const [originalPower, setOriginalPower] = useState('');
  const [originalTorque, setOriginalTorque] = useState('');
  const [otherVehicles, setOtherVehicles] = useState([]);
  const [selectedFileDetails, setSelectedFileDetails] = useState(null);
  const [totalUploadsWithCmodel, setTotalUploadsWithCmodel] = useState(0);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SITE_URL}/uploads/total-with-cmodel`)
      .then((response) => response.json())
      .then((data) => {
        setTotalUploadsWithCmodel(data.total);
      })
      .catch((error) => {
        console.error('Error fetching total uploads with cmodel:', error);
      });
  }, []);

  
  useEffect(() => {
    // Hent de siste 5 "other" kjøretøyene fra API-et
    fetch(`${process.env.REACT_APP_SITE_URL}/last-5-other-vehicles`)
      .then((response) => response.json())
      .then((data) => {
        setOtherVehicles(data);
      })
      .catch((error) => {
        console.error('Feil ved henting av other kjøretøy:', error);
      });
  }, []);
  
  const handleOpenDetails = (vehicle) => {
    setSelectedFileDetails(vehicle); // Sett kjøretøydata for modal
  };

  const handleCloseDetails = () => {
    setSelectedFileDetails(null); // Lukk modal
  };

  useEffect(() => {
    // Fetch vehicle types
    fetch(process.env.REACT_APP_SITE_URL + '/types')
      .then(response => response.json())
      .then(data => setTypes(data))
      .catch(error => console.error('Error fetching types:', error));
  }, []);

  useEffect(() => {
    if (typeId) {
      // Fetch manufacturers when a type is selected
      fetch(`${process.env.REACT_APP_SITE_URL}/manufacturers?typeId=${typeId}`)
        .then(response => response.json())
        .then(data => setManufacturers(data))
        .catch(error => console.error('Error fetching manufacturers:', error));
    }
  }, [typeId]);

  useEffect(() => {
    if (manufacturerId) {
      // Fetch ranges when a manufacturer is selected
      fetch(`${process.env.REACT_APP_SITE_URL}/ranges?manufacturerId=${manufacturerId}`)
        .then(response => response.json())
        .then(data => setRanges(data))
        .catch(error => console.error('Error fetching ranges:', error));
    }
  }, [manufacturerId]);

  // Function to round up to the nearest number ending with 0 or 5
  const roundUpToNearest5or0 = (num) => {
    return Math.ceil(num / 5) * 5;
  };

  // Automatically suggest power and torque based on powerOpti and torqueOpti
  useEffect(() => {
    if (powerOpti) {
      const suggestedPower = roundUpToNearest5or0(parseFloat(powerOpti) * 1.1);
      setPower(suggestedPower.toString());
    }
  }, [powerOpti]);

  useEffect(() => {
    if (torqueOpti) {
      const suggestedTorque = roundUpToNearest5or0(parseFloat(torqueOpti) * 1.1);
      setTorque(suggestedTorque.toString());
    }
  }, [torqueOpti]);

  const handleSubmitVehicle = (e) => {
    e.preventDefault();
    const vehicleData = {
      modelName,
      rangeId,
      manufacturerId,
      typeId,
      power,
      torque,
      obdLocation,
      ecuLocation,
      acmLocation,
      notes,
      fueltype,
      power_opti: powerOpti,
      torque_opti: torqueOpti,
      power_original: originalPower,
      torque_original: originalTorque
    };

    fetch(process.env.REACT_APP_SITE_URL + '/addVehicleModel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(vehicleData)
    })
    .then(response => response.json())
    .then(data => {
      alert('Vehicle model added successfully');
      onClose();
    })
    .catch(error => {
      console.error('Error adding vehicle model:', error);
    });
  };

  const handleSubmitRange = (e) => {
    e.preventDefault();
    const rangeData = {
      typeId,
      manufacturerId,
      rangeName,
    };

    fetch(process.env.REACT_APP_SITE_URL + '/addRange', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(rangeData)
    })
    .then(response => response.json())
    .then(data => {
      alert('Range added successfully');
      onClose();
    })
    .catch(error => {
      console.error('Error adding range:', error);
    });
  };

  const handleSubmitManufacturer = (e) => {
    e.preventDefault();
    const manufacturerData = {
      typeId,
      manufacturerName,
    };

    fetch(process.env.REACT_APP_SITE_URL + '/addManufacturer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(manufacturerData)
    })
    .then(response => response.json())
    .then(data => {
      alert('Manufacturer added successfully');
      onClose();
    })
    .catch(error => {
      console.error('Error adding manufacturer:', error);
    });
  };

  return (
    <div className="addveh-modal-background" onClick={onClose}>
      <div className="addveh-modal-content" onClick={e => e.stopPropagation()}>
      <p>Total uploads with cmodel: {totalUploadsWithCmodel}</p>

      <ul>
          {otherVehicles.length > 0 ? (
            otherVehicles.map((vehicle) => (
              <li key={vehicle.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>
                  {vehicle.manufacturerName} {vehicle.rangeName} {vehicle.modelName} - {vehicle.power} HP / {vehicle.torque} NM
                </span>
                <button onClick={() => handleOpenDetails(vehicle)}>Legg Til</button>
              </li>
            ))
          ) : (
            <p>Ingen "Other" kjøretøy funnet.</p>
          )}
        </ul>
        {selectedFileDetails && (
          <FileDetailsAddVehicle
            fileDetails={selectedFileDetails}
            onClose={handleCloseDetails}
          />
        )}
        <div className="form-container">
          <form className="vehicle-form" onSubmit={handleSubmitVehicle}>
            <label className="addveh-select-label">
              <select className="addveh-select" value={typeId} onChange={(e) => setTypeId(e.target.value)} required>
                <option value="">Select Type</option>
                {types.map(type => (
                  <option key={type.id} value={type.id}>{type.value}</option>
                ))}
              </select>
            </label>
            {typeId && (
              <>
          <label className="addveh-select-label">

            <select
              className="addveh-select"
              value={manufacturerId}
              onChange={(e) => setManufacturerId(e.target.value)}
              required
            >
              <option value="">Select Manufacturer</option>
              {manufacturers.map((manufacturer) => (
                <option key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.value}
                </option>
              ))}
            </select>
          </label>

              </>
            )}
            {manufacturerId && (
              <>
                <label className="addveh-select-label">
                  <select className="addveh-select" value={rangeId} onChange={(e) => setRangeId(e.target.value)} required>
                    <option value="">Select Range</option>
                    {ranges.map(range => (
                      <option key={range.id} value={range.id}>{range.value}</option>
                    ))}
                  </select>
                </label>
              </>
            )}
            {rangeId && (
              <>
              <label className="addveh-select-label">

                  <select className="addveh-select" value={fueltype} onChange={(e) => setFueltype(e.target.value)} required>
                    <option value="">Select Fuel Type</option>
                    <option value="2">Diesel</option>
                    <option value="3">Petrol</option>
                    <option value="5">Hybrid</option>
                  </select>
                </label>
                <label>
                  <input
                    type="text"
                            class="addveh-input"
                    value={modelName}
                    onChange={(e) => setModelName(e.target.value)}
                    required
                  />
                </label>
                Example:<br></br> 25d - 2.0 - 218 HP (07/2013 - 01/2017)<br></br>3.0 CDI - 190 HP (01/2013 - 01/2018)<br></br>2.2 D-4D - 176 HP (01/2006 - 01/2009)
                <br></br> 
                <div class="addveh-input-container">
  <div class="addveh-input-row">
    <label class="addveh-label">
      Original Power (HP):
      <input
        class="addveh-input"
        type="text"
        value={originalPower}
        onChange={(e) => setOriginalPower(e.target.value)}
        required
      />
    </label>
    <label class="addveh-label">
      Opti (HP):
      <input
        class="addveh-inputopti"
        type="text"
        value={powerOpti}
        onChange={(e) => setPowerOpti(e.target.value)}
        required
      />
    </label>
    <label class="addveh-label">
      Power (HP):
      <input
        class="addveh-inputpower"
        type="text"
        value={power}
        onChange={(e) => setPower(e.target.value)}
        required
      />
    </label>
  </div>
  <div class="addveh-input-row">
    <label class="addveh-label">
      Original Torque (NM):
      <input
        class="addveh-input"
        type="text"
        value={originalTorque}
        onChange={(e) => setOriginalTorque(e.target.value)}
        required
      />
    </label>
    <label class="addveh-label">
      Opti (NM):
      <input
        class="addveh-inputopti"
        type="text"
        value={torqueOpti}
        onChange={(e) => setTorqueOpti(e.target.value)}
        required
      />
    </label>
    <label class="addveh-label">
      Power (NM):
      <input
        class="addveh-inputpower"
        type="text"
        value={torque}
        onChange={(e) => setTorque(e.target.value)}
        required
      />
    </label>
  </div>
</div>

                <label>
                  OBD Location:
                  <input
                    type="text"
                    value={obdLocation}
                    onChange={(e) => setObdLocation(e.target.value)}
                  />
                </label>
                <label>
                  ECU Location:
                  <input
                    type="text"
                    value={ecuLocation}
                    onChange={(e) => setEcuLocation(e.target.value)}
                  />
                </label>
                <label>
                  ACM Location:
                  <input
                    type="text"
                    value={acmLocation}
                    onChange={(e) => setAcmLocation(e.target.value)}
                  />
                </label>
                <label>
                  Notes:
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </label>
                <button type="submit">Add Vehicle Model</button>
              </>
            )}
          </form>

          <div className="side-forms">
                      <form className="manufacturer-form" onSubmit={handleSubmitManufacturer}>
                        <h2>Add Manufacturer</h2>
                        <label>
                          Vehicle Type:
                          <select value={typeId} onChange={(e) => setTypeId(e.target.value)} required>
                            <option value="">Select Type</option>
                            {types.map(type => (
                              <option key={type.id} value={type.id}>{type.value}</option>
                            ))}
                          </select>
                        </label>
                        <label>
                          Manufacturer Name:
                          <input
                            type="text"
                            value={manufacturerName}
                            onChange={(e) => setManufacturerName(e.target.value)}
                            required
                          />
                        </label>
                        <button type="submit">Add Manufacturer</button>
                      </form>
                      <form className="range-form" onSubmit={handleSubmitRange}>
                        <h2>Add Range</h2>
                        <label>
                          Vehicle Type:
                          <select value={typeId} onChange={(e) => setTypeId(e.target.value)} required>
                            <option value="">Select Type</option>
                            {types.map(type => (
                              <option key={type.id} value={type.id}>{type.value}</option>
                            ))}
                          </select>
                        </label>
                        {typeId && (
                          <>
                            <label>
                              Manufacturer:
                              <select value={manufacturerId} onChange={(e) => setManufacturerId(e.target.value)} required>
                                <option value="">Select Manufacturer</option>
                                {manufacturers.map(manufacturer => (
                                  <option key={manufacturer.id} value={manufacturer.id}>{manufacturer.value}</option>
                                ))}
                              </select>
                            </label>
                          </>
                        )}
                        <label>
                          Range Name:
                          <input
                            type="text"
                            value={rangeName}
                            onChange={(e) => setRangeName(e.target.value)}
                            required
                          />
                        </label>
                        <button type="submit">Add Range</button>
                      </form>
                    </div>
        </div>
      </div>
    </div>
  );
}

export default AddVeh;
