import React, { useEffect, useState } from 'react';

const Logsite = () => {
  const [logs, setLogs] = useState([]);
  const [displayedLogs, setDisplayedLogs] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState('');
  const [displayCount, setDisplayCount] = useState(500);

  useEffect(() => {
    fetchLogs();
    fetchDealers();
  }, []);

  const fetchLogs = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/logsite/logs');
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(b.created) - new Date(a.created));
      setLogs(sortedData);
      setDisplayedLogs(sortedData.slice(0, displayCount));
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const fetchDealers = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/logsite/dealers');
      const data = await response.json();
      setDealers(data);
    } catch (error) {
      console.error('Error fetching dealers:', error);
    }
  };

  const handleDealerChange = (e) => {
    const dealerName = e.target.value; // Bruk dealerName for sammenligning
    setSelectedDealer(dealerName);
  
    if (dealerName === '') {
      // Vis alle logger hvis ingen dealer er valgt
      setDisplayedLogs(logs.slice(0, displayCount));
    } else {
      // Filtrer logger basert på valgt dealer
      const filteredLogs = logs.filter(
        (log) => log.dealer_name && log.dealer_name.includes(dealerName)
      );
      setDisplayedLogs(filteredLogs.slice(0, displayCount));
    }
  };
  
  

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 500);
    setDisplayedLogs(logs.slice(0, displayCount + 500));
  };

  return (
    <div>
      <h3>Logs</h3>
      <div>
        <label htmlFor="dealer-filter">Filter by Dealer:</label>
        <select id="dealer-filter" value={selectedDealer} onChange={handleDealerChange}>
  <option value="">All Dealers</option>
  {dealers.map((dealer) => (
    <option key={dealer.id} value={dealer.name}>
      {dealer.name}
    </option>
  ))}
</select>

      </div>
      <table>
        <thead>
          <tr>
            <th>Log ID</th>
            <th>Log Text</th>
            <th>Dealer</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {displayedLogs.map((log) => (
            <tr key={log.id}>
              <td>{log.id}</td>
              <td>{log.op_text}</td>
              <td>{log.dealer_name || 'N/A'}</td>
              <td>{new Date(log.created).toLocaleString('no-NO', { timeZone: 'UTC' })}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {displayedLogs.length < logs.length && (
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <button onClick={handleLoadMore}>Load More</button>
        </div>
      )}
    </div>
  );
};

export default Logsite;
