import React, { useState } from 'react';

const ServiceStatus = React.memo(({ overrideDates }) => {

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [notes, setNotes] = useState(''); // For å lagre brukerens notater

  const isOpen = (serviceType) => {
    const now = new Date();
    const day = now.getDay();
    const hour = now.getHours();
    const today = now.toISOString().split('T')[0];
    
    if (serviceType === 'AUTORESPONDER') {
      return true;
    }
    if (overrideDates.includes(today)) {
      return false;
    }
    if (serviceType === 'FILESERVICE') {
      if (day >= 1 && day <= 5 && hour >= 9 && hour < 21) {
        return true;
      } else if ((day === 6 || day === 0) && hour >= 10 && hour < 14) {
        return true;
      }
    }
    return false;
  };

  const timeUntilClosing = (serviceType) => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];
    if (overrideDates.includes(today)) {
      return null;
    }

    let closingTime;
    if (serviceType === 'FILESERVICE') {
      const day = now.getDay();
      if (day >= 1 && day <= 5) {
        closingTime = new Date(now);
        closingTime.setHours(21, 0, 0, 0);
      } else if (day === 6 || day === 0) {
        closingTime = new Date(now);
        closingTime.setHours(14, 0, 0, 0);
      }
    } else {
      return null;
    }

    const diff = closingTime - now;
    if (diff <= 0) return null;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if (hours < 2) {
      return `(${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''} until closing)`;
    }
    return null;
  };

  const nuh = new Date().toLocaleTimeString('no-NO', { timeZone: 'Europe/Oslo' });

  const handleButtonClick = () => {
    setShowConfirmationPopup(true);
  };

  const confirmAction = () => {
    // console.log('Action confirmed');
    setShowConfirmationPopup(false);
  };

  const cancelAction = () => {
    // console.log('Action canceled');
    setShowConfirmationPopup(false);
  };

  return (
    <div className="all-column">
      <div>
        
        FILESERVICE STATUS: <span className={isOpen('FILESERVICE') ? 'open' : 'closed'}></span><br />
        <span>Mon-Fri: 09:00-21:00</span><br />
        <span>Saturday: 10:00-14:00</span><br />

        {timeUntilClosing('FILESERVICE') && (
          <span>{timeUntilClosing('FILESERVICE')}</span>
        )}
              {/* {!isOpen('FILESERVICE') && (
          <button
          className="reopen-button"
          onClick={handleButtonClick}
        >
          Request tuner now
        </button>
      )} */}
      </div>
      <div>
        AUTORESPONDER: <span className={isOpen('AUTORESPONDER') ? 'open' : 'closed'}></span><br />
        <span>Mon-Sun: 24/7</span><br />
        <span>Only for Autotuner-uploads</span><br />

      </div>
      {showConfirmationPopup && (
        <div className="requestmodal-background">

    <div className="edit-modal-content" onClick={(e) => e.stopPropagation()}>
      <h3>Confirm Immediate Tuner Request</h3>
      <p>
        Are you sure you want to send a request for immediate tuner support? This action will notify all tuners, asking if they can provide immediate assistance. 
        Using this feature will incur an additional cost of <b>5 credits</b>, and there may be limited or no support available after the file is delivered.
      </p>
      <p>
        This function is intended as a request for immediate file support. If you have already uploaded a vehicle and selected <b>ASAP</b>, there is no need to use this request button. A tuner will be on it if available.
        If your request gets accepted, you will get a notification on your phone.
      </p>

      <label>Add notes for when tuner is needed:</label><br></br>
      <textarea
        placeholder="Add any relevant notes here..."
        value={notes} // Replace 'notes' with the corresponding state variable
        onChange={(e) => setNotes(e.target.value)} // Replace 'setNotes' with the corresponding setter function
        className="modal-input"
      />


      <p style={{ color: 'red' }}>
        Please confirm that you understand the additional costs and limited support conditions before proceeding.
      </p>
      {notes.length < 5 && (
        <p style={{ color: 'red' }}>Notes must be at least 5 characters long.</p>
      )}
      <button
        className="admin-button"
        onClick={() => {
          if (notes.length >= 5) {
            confirmAction();
          }
        }}
        disabled={notes.length < 5}
      >
        CONFIRM
      </button>
      <button className="tos-button" onClick={cancelAction}>CANCEL</button>
    </div>
  </div>
)}



    </div>
  );
});

export default ServiceStatus;