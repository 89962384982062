import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const ManualDetail = () => {
    const { manualId } = useParams();
    const [manualContent, setManualContent] = useState(null);

    useEffect(() => {
        const fetchManual = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/manual/${manualId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch manual');
                }
                const data = await response.json();
                setManualContent(data);
            } catch (error) {
                console.error('Error fetching manual:', error);
            }
        };

        fetchManual();
    }, [manualId]);

    if (!manualContent) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            {/* Breadcrumb navigation */}
            <div className="breadcrumb">
                <Link to="/opticenter">Home</Link> {'>'} <span>{manualContent.title}</span>
            </div>
            {/* Manual content */}
            <div>
                <h1>{manualContent.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: manualContent.content }} />
            </div>
        </div>
    );
};

export default ManualDetail;
