import React, { useState, useRef, useEffect } from "react";

import tasknote from '../assets/tasknote.png';
import taskperson from '../assets/taskperson.png';
import taskcalender from '../assets/taskcalender.png';
import taskhidden from '../assets/taskhidden.png';
import taskchat from '../assets/taskchat.png';
import './Tasks.css';

export default function TaskDetails({
  selectedTask,
  setSelectedTask,
  handleSaveTask,
  handleDeleteTask,
  members,
  comments,
  newComment,
  setNewComment,
  setComments,
  addComment,
  updateTask, // Legg til her
  userProfile, // Legg til userProfile som prop
  fetchTasks,
}) {
  const [isEditing, setIsEditing] = useState({}); // Holder oversikt over hvilke felter som redigeres
  const modalRef = useRef(null); // Referanse til modal-elementet
  const [editedTask, setEditedTask] = useState(selectedTask || {});
  const [isNotificationChecked, setIsNotificationChecked] = useState(false);

  const handleNotificationCheckboxChange = (e) => {
    setIsNotificationChecked(e.target.checked);
  };

  useEffect(() => {
    setEditedTask(selectedTask || {});
  }, [selectedTask]);

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formatDateTimeForMySQL = (isoDateString) => {
    if (!isoDateString) return null;
    const date = new Date(isoDateString);
    const formattedDate = date.toLocaleString('no-NO', { timeZone: 'UTC' });
    const [datePart, timePart] = formattedDate.split(',');
    const [day, month, year] = datePart.trim().split('.');
    const [hours, minutes, seconds] = timePart.trim().split(':');
    
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  };
  
  const saveTask = async () => {
    try {
      // Formatér `datetime` og `enddatetime` før lagring
      const formattedTask = {
        ...editedTask,
        datetime: formatDateTimeForMySQL(editedTask.datetime),
        enddatetime: formatDateTimeForMySQL(editedTask.enddatetime),
      };
  
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${editedTask.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formattedTask),
      });
  
      if (response.ok) {
        // console.log('Task saved successfully');
  
        // Oppdater både `editedTask` og `selectedTask` for å reflektere endringene
        setEditedTask(formattedTask);
        setSelectedTask(formattedTask);
        updateTask(formattedTask);
        // console.log('UI oppdatert med de nye verdiene');
      } else {
        const errorData = await response.json();
        console.error('Error saving task:', errorData);
      }
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };
  
  
  const saveTime = async (taskToSave) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${taskToSave.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(taskToSave), // Send oppdatert oppgave
      });
  
      if (response.ok) {
        // console.log('Task saved successfully');
  
        // Oppdater UI
        setSelectedTask(taskToSave);
        updateTask(taskToSave);
        if (fetchTasks) {
          await fetchTasks();
        }
        // console.log('UI oppdatert med de nye verdiene');
      } else {
        const errorData = await response.json();
        console.error('Error saving task:', errorData);
      }
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };
  
  
  
  const handleFieldEdit = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleFieldBlur = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
    saveTask();
  };
  
  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setIsEditing((prev) => ({ ...prev, [field]: false }));
      saveTask();
    }
  };
  

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      Object.keys(isEditing).forEach((field) => {
        if (isEditing[field]) {
          handleFieldBlur(field);
        }
      });
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  const sendNotificationToAdmin = async (title, body, data = {}) => {
    // Konverter alle verdier i `data` til strenger
    const sanitizedData = Object.entries(data).reduce((acc, [key, value]) => {
        acc[key] = String(value); // Konverterer verdier til streng
        return acc;
    }, {});

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title, body, data: sanitizedData }),
        });

        if (!response.ok) {
            throw new Error('Failed to send notification to admin');
        }
        const result = await response.json();
        // console.log('Notification sent to admin:', result);
    } catch (error) {
        console.error('Error sending notification to admin:', error);
    }
};

// const adjustDateTime = async (minutesToAdd) => {
//   try {
//     const currentDateTime = new Date(editedTask.datetime || new Date());
//     currentDateTime.setMinutes(currentDateTime.getMinutes() + minutesToAdd);
    
//     const year = currentDateTime.getFullYear();
//     const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
//     const day = String(currentDateTime.getDate()).padStart(2, '0');
//     const hours = String(currentDateTime.getHours()).padStart(2, '0');
//     const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
//     const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');
    
//     const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
//     const updatedTask = { ...editedTask, datetime: formattedDateTime };
//     setEditedTask(updatedTask);
//     await saveTime(updatedTask);
//   } catch (error) {
//     console.error("Error adjusting datetime:", error);
//   }
// };

const adjustDateTime = async (minutesToAdd) => {
  try {
    const currentDateTime = new Date(editedTask.datetime);
    currentDateTime.setMinutes(currentDateTime.getMinutes() + minutesToAdd);
    
    // Get the local time components
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
    const day = String(currentDateTime.getDate()).padStart(2, '0');
    const hours = String(currentDateTime.getHours()).padStart(2, '0');
    const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
    const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');
    
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
    const updatedTask = { ...editedTask, datetime: formattedDateTime };
    setEditedTask(updatedTask);
    await saveTime(updatedTask);
    
    if (setSelectedTask) {
      setSelectedTask(updatedTask);
    }
  } catch (error) {
    console.error("Error adjusting datetime:", error);
  }
};

const handleAddComment = async () => {
  if (!newComment.trim()) {
    console.error("Comment is empty");
    return;
  }
  if (!selectedTask || !selectedTask.id) {
    console.error("No task selected");
    return;
  }

  const payload = {
    taskid: selectedTask.id,
    user: userProfile?.name || "Unknown User", // Bruker userProfile hvis tilgjengelig
    message: newComment,
  };

  try {
    // Legg til kommentaren
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/taskcom-with-check`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const addedComment = await response.json();
      setComments((prevComments) => [
        ...prevComments,
        { ...payload, id: addedComment.commentId, date: new Date() },
      ]);
      setNewComment("");

      // Send notifikasjon kun hvis checkboxen er tick-et
      if (isNotificationChecked) {
        const notificationTitle = `${userProfile.companyName} (${userProfile?.name || "Unknown User"})`;
        const notificationBody = `(Customer) ${selectedTask.vehicle}: ${newComment}`;
        const notificationData = { taskId: String(selectedTask.id), commentId: String(addedComment.commentId) };

        await sendNotificationToAdmin(notificationTitle, notificationBody, notificationData);
      }
    } else {
      const errorResponse = await response.json();
      console.error("Server error:", errorResponse);
    }
  } catch (error) {
    console.error("Error adding comment:", error);
  }
};



  if (!selectedTask) return null;

  return (
  <div className="task-details">
        
        <div className="task-header">
          <button
            className="close-btn"
            onClick={() => setSelectedTask(null)}
          >
            X
          </button>
        </div>
        <div className="task-body">
          <div className="task-details-left">
          <div className="task-detail underline">
  <div className={`status-dot ${selectedTask.done ? "done" : "not-done"}`}></div>
  
  <h2
    onClick={() => handleFieldEdit("vehicle")}
    style={{ cursor: "pointer" }}
  >
    {isEditing.vehicle ? (
      <input
        className="custom-input"
        type="text"
        name="vehicle"
        value={editedTask.vehicle || ""}
        onChange={handleModalInputChange}
        onBlur={() => handleFieldBlur("vehicle")}
        autoFocus
      />
    ) : (
      <span onClick={() => handleFieldEdit("vehicle")}>
        {selectedTask.vehicle || "Click to edit title"}
      </span>
    )}
  </h2>
</div>
            <div className="task-detail">
  <img src={taskcalender} alt="taskcalender" className="task-icon" />
  <span
    onClick={() => handleFieldEdit("datetime")}
    style={{ cursor: "pointer" }}
  >
    {isEditing.datetime ? (
      <input
        type="datetime-local"
        name="datetime"
        value={editedTask.datetime || ""}
        onChange={handleModalInputChange}
        onBlur={() => handleFieldBlur("datetime")}
        autoFocus
      />
    ) : (
      <span>
        {editedTask.datetime
          ? new Date(editedTask.datetime).toLocaleString("no-NO", {
              dateStyle: "short",
              timeStyle: "short",
            })
          : "Set date"}
      </span>
    )}
  </span>

</div>
<div className="datetime-buttons">

<button onClick={() => adjustDateTime(-1440)}>-1d</button>
<button onClick={() => adjustDateTime(-60)}>-1t</button>

<button onClick={() => adjustDateTime(-10)}>-10m</button>
<button onClick={() => adjustDateTime(10)}>+10m</button>

<button onClick={() => adjustDateTime(60)}>+1t</button>
<button onClick={() => adjustDateTime(1440)}>+1d</button>
</div>
<br></br>
<div className="task-detail underline">
  <img src={tasknote} alt="tasknote" className="task-icon" />
<div
  onClick={() => handleFieldEdit("info")}
  style={{ cursor: "pointer" }}
>
  {isEditing.info ? (
    <textarea
      className="custom-textarea" /* Bruk den nye klassen her */
      name="info"
      value={editedTask.info || ""}
      onChange={handleModalInputChange}
      onBlur={() => handleFieldBlur("info")}
      autoFocus
      rows="5" /* Angi ønsket antall rader */
      style={{ width: "200%" }} /* Angi bredden direkte med style */
    />
  ) : (
    <span>
      {editedTask.info || "Click to add shared (Fileservice) notes"}
    </span>
  )}
</div>

</div>



<div className="task-detail underline">
  <img src={taskhidden} alt="taskhidden" className="task-icon" />
  <div
    onClick={() => handleFieldEdit("customer")}
    style={{ cursor: "pointer" }}
  >
    {isEditing.customer ? (
      <textarea
        className="custom-textarea"
        name="customer"
        value={editedTask.customer || ""}
        onChange={handleModalInputChange}
        onBlur={() => handleFieldBlur("customer")}
        autoFocus
        rows="3"
        style={{ width: "200%" }}
      />
    ) : (
      <span>
        {editedTask.customer || "Click to add internal (hidden) notes"}
      </span>
    )}
  </div>
</div>

<div className="task-detail underline">
  <img src={taskperson} alt="taskperson" className="task-icon" />
  <div
    onClick={() => handleFieldEdit("taskowner")}
    style={{ cursor: "pointer" }}
  >
    {isEditing.taskowner ? (
      <select
        name="taskowner"
        value={editedTask.taskowner || ""}
        onChange={handleModalInputChange}
        onBlur={() => handleFieldBlur("taskowner")}
        autoFocus
      >
        <option value="">Unassigned</option>
        {members.map((member) => (
          <option key={member.id} value={member.id}>
            {member.name}
          </option>
        ))}
      </select>
    ) : (
      <span>
        {members.find((m) => m.id === editedTask.taskowner)?.name ||
          "Assign to someone"}
      </span>
      
    )}
    
  </div>
  
</div>
<div className="taskdetails-commentcolumn">
  <div className="taskdetails-comments-section">
    {/* Kommentarlisten */}
    <div className="taskdetails-comments-list">
      {comments.length > 0 ? (
        comments.map((comment) => (
          <div key={comment.id} className="taskdetails-comment">
            <div className="taskdetails-comment-header">
              <strong>{comment.user}</strong>{" "}
              <span>{new Date(comment.date).toLocaleString()}</span>
            </div>
            <p>{comment.message}</p>
          </div>
        ))
      ) : (
        <p>No comments yet. Start the conversation!</p>
      )}
    </div>

    {/* Kommentarfeltet som alltid vises i bunnen */}
    <div className="taskdetails-comment-input-container">



  {/* Kommentarfelt */}
  <textarea
    id="taskdetails-comment-textarea"
    placeholder="Add a comment..."
    value={newComment}
    onChange={(e) => setNewComment(e.target.value)}
    rows="3"
  ></textarea>
  <button
    onClick={handleAddComment}
    className="taskdetails-add-comment-button"
    disabled={!newComment.trim()}
  >
    Send
  </button>
  
    </div>
    <div className="taskdetails-notification-checkbox">
        <label>
          <input
            type="checkbox"
            checked={isNotificationChecked}
            onChange={handleNotificationCheckboxChange}
          />
          Alert to tuner
        </label>
        (Check this when you want to notify about change in time`s, or you simply want to keep fileservice updated`)
      </div>
      </div>
    </div>






          </div>


        </div>
      </div>
  );
}
