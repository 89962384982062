import React, { useState, useEffect } from 'react';

function OptischoolAdmin() {
  const [categories, setCategories] = useState([]);
  const [modules, setModules] = useState([]);
  const [videos, setVideos] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newModule, setNewModule] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [newVideo, setNewVideo] = useState({ name: '', url: '' });
  const [videoName, setVideoName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ question: '', answers: ['', '', '', ''], correct: 0 });
  const [selectedVideo, setSelectedVideo] = useState(null);
  
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/categories`);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchModules = async (categoryId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/modules?catid=${categoryId}`);
      const data = await response.json();
      setModules(data);
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  };

  const fetchVideos = async (moduleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/videos?modulid=${moduleId}`);
      const data = await response.json();
      setVideos(data);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  const handleAddCategory = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/categories`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: newCategory }),
      });
      if (response.ok) {
        fetchCategories();
        setNewCategory('');
      }
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleAddModule = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/modules`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ catid: selectedCategory, name: newModule }),
      });
      if (response.ok) {
        fetchModules(selectedCategory);
        setNewModule('');
      }
    } catch (error) {
      console.error('Error adding module:', error);
    }
  };




const handleVideoUpload = async (e) => {
  const file = e.target.files[0];
  
  if (!file) {
    console.log('No file selected');
    return;
  }

  if (!selectedModule) {
    console.error('No module selected');
    return;
  }

  const formData = new FormData();
  formData.append('videoFile', file); // `videoFile` må samsvare med multer-konfigurasjonen
  formData.append('modulid', selectedModule); // Send `selectedModule` direkte
  formData.append('name', videoName); // Brukeren angir navnet på videoen

  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/uploadVideo`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Video upload failed');
    }

    const data = await response.json();
    console.log('Video uploaded successfully:', data);

    // Oppdater video-listen
    fetchVideos(selectedModule);
  } catch (error) {
    console.error('Error uploading video:', error);
  } finally {
    e.target.value = ''; // Tilbakestill filinput
  }
};


const fetchQuestions = async (videoId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/questions?videoid=${videoId}`);
    const data = await response.json();
    setQuestions(data);
  } catch (error) {
    console.error('Error fetching questions:', error);
  }
};

const handleAddQuestion = async () => {
  if (!selectedVideo) {
    console.error('No video selected');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/questions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        videoid: selectedVideo.videoid,
        question: newQuestion.question,
        answers: newQuestion.answers,
        correct: newQuestion.correct,
      }),
    });

    if (response.ok) {
      fetchQuestions(selectedVideo.videoid);
      setNewQuestion({ question: '', answers: ['', '', '', ''], correct: 0 });
    } else {
      console.error('Error adding question:', await response.text());
    }
  } catch (error) {
    console.error('Error adding question:', error);
  }
};



  return (
    <div className="optischool-admin">
      <h1>Optischool Admin</h1>

      <div className="section">
        <h2>Categories</h2>
        <input
          type="text"
          placeholder="New category name"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <button onClick={handleAddCategory}>Add Category</button>
        <ul>
          {categories.map((category) => (
            <li key={category.catid} onClick={() => {
              setSelectedCategory(category.catid);
              fetchModules(category.catid);
            }}>
              {category.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="section">
        <h2>Modules</h2>
        {selectedCategory && (
          <>
            <input
              type="text"
              placeholder="New module name"
              value={newModule}
              onChange={(e) => setNewModule(e.target.value)}
            />
            <button onClick={handleAddModule}>Add Module</button>
            <ul>
              {modules.map((module) => (
                <li key={module.modulid} onClick={() => {
  setSelectedModule(module.modulid); // modulid settes direkte
  fetchVideos(module.modulid);
}}>
  {module.name}
</li>

              ))}
            </ul>
          </>
        )}
      </div>

      <div className="section">
  <h2>Videos</h2>
  {selectedModule && (
          <>
            <ul>
              {videos.map((video) => (
                <li
                  key={video.videoid}
                  onClick={() => {
                    setSelectedVideo(video);
                    fetchQuestions(video.videoid);
                  }}
                >
                  {video.name}
                </li>
              ))}
            </ul>
          </>
        )}
</div>
{selectedVideo && (
        <div className="section">
          <h2>Questions for {selectedVideo.name}</h2>
          <ul>
            {questions.map((q, index) => (
              <li key={index}>
                {q.question}
                <ul>
                  <li>{q.answer1}</li>
                  <li>{q.answer2}</li>
                  <li>{q.answer3}</li>
                  <li>{q.answer4}</li>
                </ul>
              </li>
            ))}
          </ul>
          <div>
            <h3>Add New Question</h3>
            <input
              type="text"
              placeholder="Question"
              value={newQuestion.question}
              onChange={(e) =>
                setNewQuestion((prev) => ({ ...prev, question: e.target.value }))
              }
            />
            {newQuestion.answers.map((answer, index) => (
              <input
                key={index}
                type="text"
                placeholder={`Answer ${index + 1}`}
                value={answer}
                onChange={(e) =>
                  setNewQuestion((prev) => {
                    const updatedAnswers = [...prev.answers];
                    updatedAnswers[index] = e.target.value;
                    return { ...prev, answers: updatedAnswers };
                  })
                }
              />
            ))}
            <select
              value={newQuestion.correct}
              onChange={(e) =>
                setNewQuestion((prev) => ({
                  ...prev,
                  correct: parseInt(e.target.value, 10),
                }))
              }
            >
              <option value={0}>Select Correct Answer</option>
              <option value={1}>Answer 1</option>
              <option value={2}>Answer 2</option>
              <option value={3}>Answer 3</option>
              <option value={4}>Answer 4</option>
            </select>
            <button onClick={handleAddQuestion}>Add Question</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default OptischoolAdmin;
