import React, { useState, useEffect } from 'react';

import './FileDetails.css';

function FileDetailsAddVehicle({ onClose, fileDetails }) {
  const [models, setModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState('');
  const [selectedModelDetails, setSelectedModelDetails] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SITE_URL}/vehicle-models`)
      .then((response) => response.json())
      .then((data) => { 
        setModels(data);
        setFilteredModels(data.slice(0, 10)); // Initially display the first 10 models
      })
      .catch((error) => console.error('Error fetching vehicle models:', error));
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const lowercasedValue = value.toLowerCase().trim();

    if (lowercasedValue === '') {
      setFilteredModels(models.slice(0, 10)); // Reset to first 10 models when search is empty
    } else {
      const searchTerms = lowercasedValue.split(' ');
      const filtered = models.filter((model) => {
        const fullModelString = `${model.manufacturerName} ${model.rangeName} ${model.modelName}`.toLowerCase();
        return searchTerms.every((term) => fullModelString.includes(term));
      });
      setFilteredModels(filtered.slice(0, 10)); // Limit results to 10
    }
  };
  

  const selectModel = (model) => {
    setSelectedModelId(model.id);
    setSelectedModelDetails(model);
    setSearchTerm(''); // Clear the search input
    setFilteredModels([]); // Clear the search results
    // console.log('Selected Model Details:', model);
  };

  const handleSelectModel = async () => {
    if (!selectedModelId || !selectedModelDetails) {
      console.error("No model selected or model details are missing.");
      return;
    }

    const preparedData = {
      fileId: fileDetails.id,
      modelId: selectedModelDetails.id,
      manufacturer: selectedModelDetails.manufacturerId,
      range: selectedModelDetails.rangeId,
      type: selectedModelDetails.typeId,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(preparedData),
      });

      if (!response.ok) {
        throw new Error("Failed to update upload.");
      }

      const result = await response.json();
      // console.log("Response from server:", result);
      alert("Upload updated successfully.");
      onClose();
    } catch (error) {
      console.error("Error updating upload:", error);
    }
  };

  return (
    <div className="editveh-modal-background">
      <div className="editveh-content">
        <div className="modal-header">
          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>
        <div className="modal-body">
          <div className="vehicle-section-container">
            <div className="select-vehicle-section">
              
 
              <h4>Select Existing Vehicle</h4>


              <span className="file-addveh-details">{fileDetails.vehicleDescription}</span>
              <span className="file-addveh-details">{fileDetails.engine_volume} CCM3</span>
              <span className="file-addveh-details">{fileDetails.horse_power} HP</span>
              <span className="file-addveh-details">MY: {fileDetails.year}</span>

              <div>
                <input
                  type="text"
                  placeholder="Search vehicle model..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="search-input"
                />
                <div className="search-results">
                  {filteredModels.map((model) => (
                    <div
                      key={model.id}
                      className={`search-result-item ${selectedModelId === model.id ? 'selected' : ''}`}
                      onClick={() => selectModel(model)}
                    >
                      {model.manufacturerName} {model.rangeName} {model.modelName} - {model.power}HP / {model.torque}NM
                    </div>
                  ))}
                </div>
                {selectedModelDetails && (
                  <div className="selected-model">
                    <h5>Selected Model:</h5>
                    <p>{selectedModelDetails.manufacturerName} {selectedModelDetails.rangeName} {selectedModelDetails.modelName} - {selectedModelDetails.power}HP / {selectedModelDetails.torque}NM</p>
                  </div>
                )}
                <button onClick={handleSelectModel} disabled={!selectedModelDetails} className="updownload-button">
                  Use Selected Model
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileDetailsAddVehicle;
