import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const generateRandomVariation = (baseValue, variationRange) => {
  return baseValue + (Math.random() * variationRange - variationRange / 2);
};

const generateDynoData = (rpmValues, maxPower, maxTorque, variationRange) => {
  const powerData = rpmValues.map((rpm, idx) => {
    const normalizedRPM = rpm / Math.max(...rpmValues); // Normalisert RPM
    const basePower = maxPower * Math.pow(normalizedRPM, 1.5); // Simuler HP-kurve
    return generateRandomVariation(basePower, variationRange);
  });

  const torqueData = rpmValues.map((rpm, idx) => {
    const normalizedRPM = rpm / Math.max(...rpmValues); // Normalisert RPM
    const baseTorque = maxTorque * (1 - 0.3 * normalizedRPM); // Simuler NM-kurve
    return generateRandomVariation(baseTorque, variationRange);
  });

  return { powerData, torqueData };
};

const DynoChart = ({ powerOriginal, torqueOriginal, powerOpti, torqueOpti }) => {
  const rpmValues = Array.from({ length: 20 }, (_, i) => i * 500 + 1000); // RPM fra 1000 til 10000
  const variationRange = 10; // Variasjonsområde for dataene

  const originalData = generateDynoData(rpmValues, powerOriginal, torqueOriginal, variationRange);
  const optiData = generateDynoData(rpmValues, powerOpti, torqueOpti, variationRange);

  const data = {
    labels: rpmValues.map((rpm) => `${rpm} RPM`),
    datasets: [
      {
        label: 'Original HP',
        data: originalData.powerData,
        borderColor: 'blue',
        borderWidth: 2,
        fill: false,
        pointRadius: 0, // Fjerner dottene
      },
      {
        label: 'Original NM',
        data: originalData.torqueData,
        borderColor: 'green',
        borderWidth: 2,
        fill: false,
        yAxisID: 'y-axis-nm',
        pointRadius: 0, // Fjerner dottene
      },
      {
        label: 'Opti HP',
        data: optiData.powerData,
        borderColor: 'red',
        borderWidth: 2,
        borderDash: [5, 5],
        fill: false,
        pointRadius: 0, // Fjerner dottene
      },
      {
        label: 'Opti NM',
        data: optiData.torqueData,
        borderColor: 'orange',
        borderWidth: 2,
        borderDash: [5, 5],
        fill: false,
        yAxisID: 'y-axis-nm',
        pointRadius: 0, // Fjerner dottene
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Engine RPM',
        },
      },
      'y-axis-hp': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Horsepower (HP)',
        },
        ticks: {
          stepSize: 50,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      'y-axis-nm': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Torque (NM)',
        },
        ticks: {
          stepSize: 50,
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default DynoChart;
