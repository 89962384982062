import React, { useEffect, useState } from 'react';

const LatestDatabaseEntries = () => {
    const [logEntries, setLogEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchLogEntries();
    }, []);

    const fetchLogEntries = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/latest-log-entries`);
          const data = await response.json();
          setLogEntries(data); // Setter de siste 5 oppføringene
        } catch (error) {
          console.error('Error fetching log entries:', error);
        } finally {
          setIsLoading(false);
        }
      };

    return (
        <div className="c1">
            {isLoading ? (
                <p>Loading...</p>
               
            ) : logEntries.length === 0 ? (
                <p>No entries found.</p>
            ) : (
                <ul className="latest-topics-list">
                    {logEntries.map((entry, index) => (
                        <li key={index} className="log-entry">
                            <strong>{entry.user}</strong>({entry.company}) updated  <span className="model-name"> {entry.model} </span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LatestDatabaseEntries;
