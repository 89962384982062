import React, { useState, useEffect, useRef } from 'react';

import './Tickets.css';
const Tickets = ({ userProfile }) => {
    const [tickets, setTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [ticketComments, setTicketComments] = useState([]);
    const [comment, setComment] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [newTicket, setNewTicket] = useState({
      title: '',
      vehicle: '',
      tool: '',
      priority: 1, // Standard prioritet (Low)
    });
    
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [members, setMembers] = useState([]);
    const commentsEndRef = useRef(null); // Referanse til siste kommentar
    const toggleModal = () => setShowModal(!showModal);

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/members/tickets`);
                if (response.ok) {
                    const data = await response.json();

                    setMembers(data);
                } else {
                    console.error('Failed to fetch full members:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching full members:', error);
            }
        };
    
        fetchMembers();
    }, []);
    
    const scrollToBottom = () => {
        if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const fetchTickets = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tickets`);
            if (response.ok) {
                const data = await response.json();
                const sortedTickets = data.sort((a, b) => new Date(b.created) - new Date(a.created));
                const filteredTickets =
                    userProfile.admin === 1 || userProfile.support === 1
                        ? sortedTickets // Hvis admin eller support, vis alle tickets
                        : sortedTickets.filter((ticket) => {

                              return ticket.mid === userProfile.did; // Vis kun tickets der mid samsvarer med did
                          });
                setTickets(filteredTickets);
            } else {
                console.error('Failed to fetch tickets');
            }
        } catch (error) {

        } finally {
            setLoading(false);

        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    if (loading) {
        return <p>Loading tickets...</p>;
    }


    const fetchTicketComments = async (ticketId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ticketcom/${ticketId}`);
            if (response.ok) {
                const data = await response.json();
                setTicketComments(data);
                setTimeout(scrollToBottom, 100); // Scroll til bunnen etter at kommentarer er lastet
            } else {
                console.error('Failed to fetch ticket comments');
            }
        } catch (error) {
            console.error('Error fetching ticket comments:', error);
        }
    };

    const handleRowClick = (ticket) => {
        setSelectedTicket(ticket);
        fetchTicketComments(ticket.id);
    };

    
    const handleAddComment = async () => {
        try {
          const newMessageStatus = userProfile.admin === 1 || userProfile.tuner === 1 ? 2 : 1;
      
          console.log('Posting new comment:', {
            ticketId: selectedTicket.id,
            user: userProfile.id,
            message: comment,
            internal: userProfile.admin === 1 || userProfile.tuner === 1 ? 1 : 0,
          });
      
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ticketcom`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ticketId: selectedTicket.id,
              user: userProfile.id,
              message: comment,
              internal: userProfile.admin === 1 || userProfile.tuner === 1 ? 1 : 0,
            }),
          });
      
          if (!response.ok) {
            const error = await response.json();
            console.error('Error posting comment:', error);
            return;
          }
      
          console.log('Comment posted successfully');
      
          // Oppdater `message` i ticket-tabellen
          const updateResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/updateTicketMessage`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ticketId: selectedTicket.id,
              message: newMessageStatus,
            }),
          });
      
          if (!updateResponse.ok) {
            const updateError = await updateResponse.json();
            console.error('Error updating ticket message:', updateError);
            return;
          }
      
          console.log('Ticket message updated successfully');
      
          // Send varsel basert på rollen til brukeren som poster
          const notificationTitle = `SUPPORTTICKET - (${userProfile.name}) `;
          const notificationBody = `${selectedTicket.title}: ${comment}`;
      
          if (userProfile.admin === 1 || userProfile.tuner === 1) {
            // Varsel til vanlige brukere
            await sendNotification(
              selectedTicket.mid, // Bruker `ticket.mid` som mottaker
              notificationTitle,
              notificationBody,
              {
                ticketId: String(selectedTicket.id),
                sender: String(userProfile.name),
                parent: String(selectedTicket.parentId || ''), // Sørg for at parent er inkludert
              }
            );
          } else {
            // Varsel til admin
            await sendNotificationToAdmin(notificationTitle, notificationBody, {
              ticketId: String(selectedTicket.id),
              sender: String(userProfile.name),
              parent: String(selectedTicket.parentId || ''), // Sørg for at parent er inkludert
            });
          }
      
          setComment('');
          fetchTicketComments(selectedTicket.id); // Oppdater kommentarer
          fetchTickets(); // Oppdater ticketlisten
        } catch (error) {
          console.error('Error in handleAddComment:', error);
        }
      };
      
    const getPriorityColor = (priority) => {
        switch (priority) {
            case 1:
                return 'green';
            case 2:
                return 'orange';
            case 3:
                return 'red';
            default:
                return 'grey';
        }
    };

    const getUserInfo = (userId) => {
        const user = members.find((member) => String(member.id) === String(userId));
        if (!user) {
            return { name: 'Unknown User', optiInfo: '' };
        }
        const isAdminOrTuner = user.admin === 1 || user.tuner === 1;
        if (isAdminOrTuner) {
            return { name: user.name, optiInfo: 'Optifiles' };
        }
        if (!user.did) {
            return { name: user.name, optiInfo: 'Unknown Company' };
        }
        const parentCompany = members.find((member) => String(member.id) === String(user.did));
        if (!parentCompany) {
            return { name: user.name, optiInfo: 'Unknown Company' };
        }
        return { name: user.name, optiInfo: parentCompany.name };
    };
    
    const sendNotification = async (userId, title, body, data = {}) => {
        console.log('Preparing to send notification:');
        console.log('UserID:', userId);
        console.log('Title:', title);
        console.log('Body:', body);
        console.log('Data:', data);
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userProfile.token}`, // Legg til token for autentisering om nødvendig
            },
            body: JSON.stringify({ userId, title, body, data }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to send notification');
          }
      
          const result = await response.json();
          console.log('Notification sent successfully:', result);
        } catch (error) {
          console.error('Error sending notification:', error);
        }
      };
      
    const sendNotificationToAdmin = async (title, body, data = {}) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title, body, data }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to send notification to admin');
            }
            const result = await response.json();

        } catch (error) {
            console.error('Error sending notification to admin:', error);
        }
    };


    const handleCreateTicket = async () => {
      if (!newTicket.title || !newTicket.vehicle || !newTicket.tool || !newTicket.priority) {
        alert('All fields are required!');
        return;
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tickets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title: newTicket.title,
            vehicle: newTicket.vehicle,
            tool: newTicket.tool,
            priority: newTicket.priority, // Bruker verdien fra dropdown til priority
            status: 1, // Status settes alltid til 1 (åpen ticket)
            mid: userProfile.did, // Brukerens firma-ID
            user: userProfile.id, // Brukerens ID
          }),
        });
    
        const notificationTitle = `${userProfile.companyName} (${userProfile.name})`;
        const notificationBody = `SUPPORTTICKET - ${newTicket.tool}
    ${newTicket.title}: ${newTicket.vehicle}`;
    
        const notificationData = {
          messageId: `ticket-${new Date().getTime()}`, // Unik melding-ID som streng
          sender: `${userProfile.name}`, // Brukerens navn
          userId: `${userProfile.id}`, // Brukerens ID som streng
          mid: `${userProfile.did}`, // Brukerens firma-ID som streng
        };
    
        await sendNotificationToAdmin(notificationTitle, notificationBody, notificationData);
    
        if (response.ok) {
          setNewTicket({ title: '', vehicle: '', tool: '', priority: 1 }); // Tilbakestill ny ticket
          fetchTickets(); // Oppdater listen over tickets
          toggleModal();
        } else {
          console.error('Failed to create ticket');
        }
      } catch (error) {
        console.error('Error creating ticket:', error);
      }
    };
    
    

    const handleFileChange = async (e) => {
        const file = e.target.files[0]; // Hent den valgte filen
      
        if (!file) {
          console.log('No file selected');
          return;
        }
      
        console.log('File selected:', file);
      
        const formData = new FormData();
        formData.append('ticketFile', file); // `ticketFile` må samsvare med multer-konfigurasjonen
        formData.append('ticketId', selectedTicket.id);
        formData.append('userId', userProfile.id);
      
        try {
          console.log('Uploading file...');
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadTicketFile`, {
            method: 'POST',
            body: formData,
          });
      
          if (!response.ok) {
            throw new Error('File upload failed');
          }
      
          const data = await response.json();
          console.log('File uploaded successfully:', data);
      
          // Oppdater kommentarer for å inkludere opplastingen
          fetchTicketComments(selectedTicket.id);
        } catch (error) {
          console.error('Error uploading file:', error);
        } finally {
          // Tilbakestill filinput
          e.target.value = '';
        }
      };
      
      const handleDeleteMessage = async (commentId) => {
        if (!window.confirm('Are you sure you want to delete this message?')) {
          return;
        }
      
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/deleteTicketComment`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ commentId }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to delete comment');
          }
      
          // Oppdater kommentarer etter sletting
          fetchTicketComments(selectedTicket.id);
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      };

      const handleToggleStatus = async () => {
        const newStatus = selectedTicket.status === 1 ? 0 : 1;
      
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/toggleTicketStatus`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ticketId: selectedTicket.id,
              status: newStatus,
            }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to toggle ticket status');
          }
      
          // Oppdater lokalt etter toggling
          setSelectedTicket((prev) => ({ ...prev, status: newStatus }));
          fetchTickets(); // Oppdater listen over tickets
        } catch (error) {
          console.error('Error toggling ticket status:', error);
        }
      };
      

    return (
        <div className="ticket-container">

            <div className="ticket-header">
                <h2>SUPPORT TICKETS</h2>

                <button className="ticket-create-button" onClick={toggleModal}>
                    Create New Support Ticket
                </button>
                
            </div>
            <p>
            Support tickets are for all <b>non-vehicle / non-technical</b> related inquiries directed to the staff.<br></br>
            Please use the Supportforum for technical / vehicle-related support
                </p>
            {/* Tabellen */}
            <table className="ticket-table">
            <thead>
            <tr>
    <th>ID</th>
    {userProfile.admin === 1 || userProfile.tuner === 1 ? <th>Priority</th> : null}
    <th>Title</th>
    <th>Category</th>
    {userProfile.admin === 1 || userProfile.tuner === 1 ? <th>Company Name</th> : null}
    <th>Status</th>
    <th>Alert</th> {/* Ny kolonne for varsel */}
    <th>Created</th>
  </tr>
</thead>
<tbody>
  {tickets.map((ticket) => {
    const companyName = members.find((member) => member.id === ticket.mid)?.name || 'Unknown';
    const showAlert =
      ticket.status && // Kun for Open tickets
      ((ticket.message === 1 && (userProfile.admin === 1 || userProfile.tuner === 1)) ||
        (ticket.message === 2 && userProfile.admin !== 1 && userProfile.tuner !== 1));

    return (
      <tr key={ticket.id} onClick={() => handleRowClick(ticket)}>
        <td>{ticket.id}</td>
        {userProfile.admin === 1 || userProfile.tuner === 1 ? (
          <td style={{ color: getPriorityColor(ticket.priority) }}>{ticket.priority}</td>
        ) : null}
        <td>{ticket.title}</td>
        <td>{ticket.tool || 'N/A'}</td>
        {userProfile.admin === 1 || userProfile.tuner === 1 ? <td>{companyName}</td> : null}
        <td
          style={{
            color: ticket.status ? '#28a745' : '#dc3545', // Grønn for Open, Rød for Closed
            fontWeight: 'bold',
          }}
        >
          {ticket.status ? 'Open' : 'Closed'}
        </td>
        <td>
        {showAlert ? (
  <span
    style={{
      display: 'inline-block', // Påkrevd for transformasjoner
      fontSize: '20px',
      color: 'green',
      animation: 'pulse-only 1.5s infinite',
    }}
    role="img"
    aria-label="Alert"
  >
    ⚠️
  </span>
) : (
  '' // Tom celle for ingen varsel
)}


</td>

        <td>{new Date(ticket.created).toLocaleDateString('no-NO')}</td>
      </tr>
    );
  })}
</tbody>

            </table>
            {selectedTicket && (
  <div className="ticket-modal">
    <div className="ticket-modal-content">
      <div className="modal-header">
        <button className="close-btn" onClick={() => setSelectedTicket(null)}>CLOSE</button>
      </div>
      <h3 className="ticket-title">{selectedTicket.title}</h3>
      <div className="ticket-info">
      <p>
    <strong>Status:</strong>{' '}
    <button
      className={`status-button ${selectedTicket.status ? 'ticketopen' : 'ticketclosed'}`}
      onClick={userProfile.admin === 1 ? handleToggleStatus : undefined} // Bare klikkbar for admin
      disabled={userProfile.admin !== 1} // Deaktiver for ikke-admin brukere
    >
      {selectedTicket.status ? 'Open' : 'Closed'}
    </button>
  </p>
        <p><strong>Created:</strong> {new Date(selectedTicket.created).toLocaleDateString()}</p>
        <p><strong>{selectedTicket.tool}</strong><br /></p>
        <p><strong></strong><br /> {selectedTicket.vehicle}</p>
      </div>

      {/* Scrollbar for kommentarer */}
      <div className="ticket-comments-container">
  <ul className="ticket-comments-list">
    {ticketComments.map((comment) => {
      const isOwnComment = String(comment.user) === String(userProfile.id);
      const { name, optiInfo } = getUserInfo(comment.user);
      const isImage = comment.url && (comment.url.endsWith('.jpg') || comment.url.endsWith('.png'));
      const isOptifiles = optiInfo === 'Optifiles';

      return (
        <li
          key={comment.id}
          className={`ticket-comment-item ${isOwnComment ? 'own-comment' : 'other-comment'}`}
        >
          <div className="ticket-comment-header">
            <strong className="ticket-comment-user">{name}</strong>
            <span>
              {new Date(comment.date).toLocaleDateString('no-NO', { timeZone: 'UTC' })}{' '}
              {new Date(comment.date).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}
            </span>

            {userProfile.admin === 1 && (
              <button
                className="delete-message-button"
                onClick={() => handleDeleteMessage(comment.id)}
              >
                <img
                  src="/images/red-trash-can-icon.svg"
                  alt="Delete"
                  className="delete-icon"
                />
              </button>
            )}


          </div>
          <div
            className="ticket-comment-optiinfo"
            style={{ color: isOptifiles ? 'red' : 'green' }} // Sett rød for Optifiles og grønn for selskap
          >
            {optiInfo}
          </div>
          <p className="ticket-comment-message">
            {comment.message ||
              (comment.url &&
                (isImage ? (
                  <a href={comment.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={comment.url}
                      alt="Thumbnail"
                      style={{ width: '350px', height: '220px', objectFit: 'cover' }}
                    />
                  </a>
                ) : (
                    <a href={comment.url} download className="download-link">
                    {decodeURIComponent(comment.url.split('/').pop())}
                  </a>
                )))}
          </p>
        </li>
      );
    })}
    <div ref={commentsEndRef} />
  </ul>
</div>


      {/* Tekstområde for ny kommentar */}
      <div className="comment-input-container">
      <button
    className="upload-button"
    onClick={() => document.getElementById('file-input').click()}
    disabled={!selectedTicket.status} // Deaktiver når ticketen er Closed
  >
    +
  </button>
  <input
    type="file"
    id="file-input"
    style={{ display: 'none' }}
    onChange={handleFileChange}
    disabled={!selectedTicket.status} // Deaktiver når ticketen er Closed
  />
  <textarea
    value={comment}
    onChange={(e) => setComment(e.target.value)}
    placeholder="Message..."
    className="ticket-comment-input"
    onKeyDown={(e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleAddComment();
      }
    }}
    disabled={!selectedTicket.status} // Deaktiver når ticketen er Closed
  />
  </div>
    </div>
  </div>
)}


            {/* Popup Modal */}
            {showModal && (
  <div className="ticket-modal-background" >
    <div className="ticket-modal-content" onClick={(e) => e.stopPropagation()}>
    <div className="modal-header">
        <button className="close-btn" onClick={toggleModal}>CLOSE</button>
      </div>
      <h3 className="ticket-modal-title">Create New Support Ticket</h3>

      {/* Subject */}
      <div className="form-group">
        <label htmlFor="ticket-subject">Subject</label>
        <input
          id="ticket-subject"
          type="text"
          value={newTicket.title}
          onChange={(e) => setNewTicket({ ...newTicket, title: e.target.value })}
          placeholder="Enter a brief subject"
          className="input-field"
        />
      </div>

      {/* Description */}
      <div className="form-group">
        <label htmlFor="ticket-description">Description</label>
        <textarea
          id="ticket-description"
          value={newTicket.vehicle}
          onChange={(e) => setNewTicket({ ...newTicket, vehicle: e.target.value })}
          rows={6}
          placeholder="Provide detailed information about your request"
          className="textarea-field"
        />
      </div>

      {/* Category */}
      <div className="form-group">

        <select
          id="ticket-category"
          value={newTicket.tool || ''}
          onChange={(e) => setNewTicket({ ...newTicket, tool: e.target.value })}
          className="select-field"
        >
          <option value="" disabled>
            Select a category
          </option>
          <option value="Graphical">Graphical / SoMe material Request</option>
          <option value="Billing">Billing / Webshop</option>
          <option value="MobileApp">Optifiles Portal / MobileApp</option>
          <option value="Vehicle">Vehicle figures request</option>
          <option value="Submit">Submit Info</option>
        </select>
        {newTicket.tool === 'Graphical' && (
            <p className="info-text">
            This is for requesting material for advertising through Social Medias.
            </p>
        )}
                {newTicket.tool === 'Billing' && (
            <p className="info-text">
            This is for issues regarding billing / webshop.
            </p>
        )}
                {newTicket.tool === 'MobileApp' && (
            <p className="info-text">
            This is for feedback, bugreports, feature requests for mobile and website.
            </p>
        )}
                        {newTicket.tool === 'Submit' && (
            <p className="info-text">
            This is for submitting info to staff regarding tools / vehicles etc.
            </p>
        )}
        
        {newTicket.tool === 'Vehicle' && (
          <p className="info-text">
  This is for requesting power figures (hp / nm) for vehicles not in Database only.<br />
  <span className="blinking-red">
    For all vehicle / technical related support, please use the public Supportforum. Technical tickets will not be answered
  </span>
</p>

            
        )}
      </div>
      <div className="form-group">

      <select
        id="ticket-priority"
        value={newTicket.priority || ''} // Setter verdien av priority
        onChange={(e) => setNewTicket({ ...newTicket, priority: parseInt(e.target.value) })} // Oppdaterer priority
        className="select-field"
      >
        <option value="" disabled>
          Select priority
        </option>
        <option value={1}>Low</option>
        <option value={2}>Medium</option>
        <option value={3}>High</option>
      </select>

      </div>

      {/* Buttons */}
      <div className="button-group">
        <button className="admin-button" onClick={handleCreateTicket}>
          Create
        </button>

      </div>
    </div>
  </div>
)}




        </div>
    );
};

export default Tickets;
