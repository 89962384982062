import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import './../../App.css';
import ManualsModal from './Manualsmodal';
import ManualsSearch from './ManualsSearch';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';
import CreateManualModal from './CreateManualModal';
import { useAuth } from '../../auth/AuthProvider';
import { UserContext } from '../../context/UserContext';

export default function Manuals() {
    const { manualId } = useParams(); // Hent manualId fra URL
    const [categories, setCategories] = useState([]); // Categories list
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Search term
    const [selectedCategory, setSelectedCategory] = useState(null); // Selected category
    const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Selected subcategory
    const [manualContent, setManualContent] = useState(null); // Content of the selected manual
    const [isAdmin, setIsAdmin] = useState(true); // Admin mode
    const [isSupport, setIsSupport] = useState(true); 
    const [isTuner, setIsTuner] = useState(true);  
    const [showAdminPanel, setShowAdminPanel] = useState(false); // State for showing/hiding admin panel
    const [subcategories, setSubcategories] = useState([]); // Subcategories list
    const [manualTitles, setManualTitles] = useState([]); // Manual titles list
    const [selectedManual, setSelectedManual] = useState(null); // Selected manual for detail view
    const [showManualsModal, setShowManualsModal] = useState(false);
    const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null); // Track selected sub-subcategory
    const [subSubcategories, setSubSubcategories] = useState([]); // Store sub-subcategories
    const isFetching = useRef(false); // Track ongoing fetches
    const [activeView, setActiveView] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentContext, setCurrentContext] = useState(null); // To store selected category context
    const { authToken } = useAuth();
    const { userProfile, setUserProfile } = useContext(UserContext);

    const [icons, setIcons] = useState([]);
    
    useEffect(() => {
            const fetchUserProfile = async () => {
              try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                  },
                });
                if (response.ok) {
                  const data = await response.json();
          
                  // Logg data for debugging
                  // console.log('User profile data fetched:', data);
          
                  setUserProfile(data);
                  setIsAdmin(data.admin === 1);
                  setIsTuner(data.tuner === 1);
                  setIsSupport(data.support === 1);
                } else {
                  throw new Error('Failed to fetch user profile');
                }
              } catch (error) {
                console.error('Error fetching user profile:', error);
              }
            };
          
            if (authToken) {
              fetchUserProfile();
            }
          }, [authToken, setUserProfile]);
    


        useEffect(() => {
            const fetchIcons = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/manuals-icons`); // Endpoint for icons
                    if (response.ok) {
                        const data = await response.json();
                        setIcons(data);
                    } else {
                        console.error('Failed to fetch icons');
                    }
                } catch (error) {
                    console.error('Error fetching icons:', error);
                }
            };

            fetchIcons();
        }, []);



    useEffect(() => {
        fetchCategories();
    }, []);

    const clearAllStates = () => {
        // console.log("Clearing all states...");
        setManualTitles([]);
        setSelectedManual(null);
        setActiveView('');
    };

    const handleSearch = useCallback(async () => {
        // console.log('Button clicked. Starting search for:', searchTerm);

        // Clear all states before performing search
        if (clearAllStates) {
            // console.log('Clearing all states...');
            clearAllStates();
        }

        if (setActiveView) {
            // console.log('Setting active view to "search"...');
            setActiveView('search');
        }

        if (!searchTerm.trim()) {
            // console.log('Search term is empty. Clearing results.');
            setSearchResults([]);
            setManualTitles && setManualTitles([]); // Clear manuals when search is empty
            return;
        }

        try {
            // console.log(`Fetching search results for query: ${searchTerm}`);
            const response = await fetch(
                `${process.env.REACT_APP_SITE_URL}/ocsearch/manuals?query=${encodeURIComponent(searchTerm)}`
            );
            // console.log('Search API Response:', response);

            if (!response.ok) {
                throw new Error('Failed to fetch search results');
            }

            const data = await response.json();
            // console.log('Search Results Data:', data);

            setSearchResults(data);
            setManualTitles && setManualTitles(data); // Update main content with search results
            setSelectedManual && setSelectedManual(null); // Clear manual selection when searching
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchResults([]);
        }
    }, [searchTerm, setManualTitles, setSelectedManual, setActiveView, clearAllStates]);

   

    // Fetch categories from API
    const fetchCategories = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/categories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            // console.log('Fetched categories:', data);
            setCategories(data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetch subcategories based on selected category
    const fetchSubcategories = async (categoryId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            // console.log('Subcategories fetched:', data);
            setSubcategories(data);
            setSelectedSubSubcategory(null); // Clear sub-subcategory when switching subcategories
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    // Fetch sub-subcategories based on selected subcategory
    const fetchSubSubcategories = async (subcategoryId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}/sub-subcategories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            // console.log('Sub-subcategories fetched:', data);
            setSubSubcategories(data);
        } catch (error) {
            console.error("Error fetching sub-subcategories:", error);
        }
    };
    
    // ikke fjern
    
    //  const fetchManualTitles = useCallback(async (id, level) => {
    //     if (!id || isFetching.current) return;

    //     isFetching.current = true; // Mark fetch in progress
    //     try {
    //         const apiUrl =
    //             level === 'category'
    //                 ? `${process.env.REACT_APP_SITE_URL}/oc/manuals/category/${id}`
    //                 : level === 'subcategory'
    //                 ? `${process.env.REACT_APP_SITE_URL}/oc/manuals/subcategory/${id}`
    //                 : `${process.env.REACT_APP_SITE_URL}/oc/manuals/${id}`;

    //         const response = await fetch(apiUrl);
    //         if (!response.ok) {
    //             throw new Error(`Failed to fetch manuals: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         // console.log(`Fetched manuals for ${level} ID ${id}:`, data);

    //         const sortedManuals = Array.isArray(data)
    //         ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    //         : [];

    //         setManualTitles(sortedManuals);
    //         setSelectedManual(null);
    //         setManualContent('');
    //     } catch (error) {
    //         console.error(`Error fetching manual titles for ${level} ID ${id}:`, error.message);
    //     } finally {
    //         isFetching.current = false; // Reset fetch state
    //     }
    // }, []);

    const fetchManualTitles = useCallback(async (id, level) => {
        // console.log(`Starting fetch for manuals at level: ${level} with ID: ${id}`);
    
        if (!id || isFetching.current) {
            // console.log("Fetch aborted: Missing ID or another fetch is already in progress.");
            return;
        }
    
        isFetching.current = true; // Mark fetch in progress
    
        try {
            const apiUrl =
                level === 'category'
                    ? `${process.env.REACT_APP_SITE_URL}/oc/manuals/category/${id}`
                    : level === 'subcategory'
                    ? `${process.env.REACT_APP_SITE_URL}/oc/manuals/subcategory/${id}`
                    : `${process.env.REACT_APP_SITE_URL}/oc/manuals/${id}`;
    
            // console.log(`Fetching from API: ${apiUrl}`);
    
            const response = await fetch(apiUrl);
            // console.log('API Response:', response);
    
            if (!response.ok) {
                throw new Error(`Failed to fetch manuals: ${response.status}`);
            }
    
            const data = await response.json();
            // console.log('Fetched Manuals Data:', data);
    
            const sortedManuals = Array.isArray(data)
                ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : [];
    
            setManualTitles(sortedManuals);
            // console.log('Updated manual titles:', sortedManuals);
    
            setSelectedManual(null);
            setManualContent('');
        } catch (error) {
            console.error(`Error fetching manual titles for ${level} ID ${id}:`, error.message);
        } finally {
            isFetching.current = false; // Reset fetch state
            // console.log("Fetch completed.");
        }
    }, []);
    

    useEffect(() => {
        let timeout;
        if (selectedCategory && !selectedSubcategory) {
            // console.log("Fetching manuals for category");
            timeout = setTimeout(() => fetchManualTitles(selectedCategory.id, 'category'), 100);
        } else if (selectedSubcategory && !selectedSubSubcategory) {
            // console.log("Fetching manuals for subcategory");
            timeout = setTimeout(() => fetchManualTitles(selectedSubcategory.id, 'subcategory'), 100);
        } else if (selectedSubSubcategory) {
            // console.log("Fetching manuals for sub-subcategory");
            timeout = setTimeout(() => fetchManualTitles(selectedSubSubcategory.id, 'subsubcategory'), 100);
        }

        return () => {
            // console.log("Cleaning up effect");
            clearTimeout(timeout);
        };
    }, [selectedCategory?.id, selectedSubcategory?.id, selectedSubSubcategory?.id, fetchManualTitles]);

    // Fetch manual content based on manual ID
    // const fetchManualContent = async (manualId) => {
    //     try {
    //         let apiUrl;
    
    //         // Determine the appropriate URL based on the selected level
    //         if (selectedSubSubcategory) {
    //             apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/${selectedSubSubcategory.id}`;
    //         } else if (selectedSubcategory) {
    //             apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/subcategory/${selectedSubcategory.id}`;
    //         } else if (selectedCategory) {
    //             apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/category/${selectedCategory.id}`;
    //         } else {
    //             throw new Error('No valid category, subcategory, or sub-subcategory selected');
    //         }
    
    //         const response = await fetch(apiUrl);
    //         const data = await response.json();
    
    //         // console.log('Fetched data:', data);
    
    //         if (!Array.isArray(data) || data.length === 0) {
    //             throw new Error('No manuals found for this category');
    //         }
    
    //         const manual = data.find(item => item.id === manualId);
    //         // console.log('Selected manual:', manual);
    
    //         if (!manual || !manual.content) {
    //             throw new Error('Manual content not found');
    //         }
    
    //         setManualContent(manual.content || ''); // Set the content
    //         setSelectedManual(manual); // Set the selected manual for breadcrumb
    //     } catch (error) {
    //         console.error('Error fetching manual content:', error.message);
    //     }
    // };

    useEffect(() => {
        if (manualId) {
            // console.log(`Detected manualId in URL: ${manualId}`);
            fetchManualContent(manualId, true); // Bruker direkte fetch
        }
    }, [manualId]);
    

    const fetchManualContent = async (manualId, useDirectFetch = false) => {
        // console.log(`Starting content fetch for manual ID: ${manualId}`);
    
        try {
            let apiUrl;
    
            if (useDirectFetch) {
                // Direkte fetch basert på manualId
                apiUrl = `${process.env.REACT_APP_SITE_URL}/manual/${manualId}`;
            } else if (selectedSubSubcategory) {
                apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/${selectedSubSubcategory.id}`;
            } else if (selectedSubcategory) {
                apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/subcategory/${selectedSubcategory.id}`;
            } else if (selectedCategory) {
                apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/category/${selectedCategory.id}`;
            } else {
                throw new Error('No valid category, subcategory, or sub-subcategory selected');
            }
    
            // console.log(`Fetching content from API: ${apiUrl}`);
    
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch manual content: ${response.status}`);
            }
    
            if (useDirectFetch) {
                // Fallback-scenario: Vi henter direkte fra URL
                const manual = await response.json();
                // console.log('Fetched Manual Data:', manual);
    
                if (!manual || !manual.content) {
                    throw new Error('Manual content not found');
                }
    
                // Oppdater kun manual-content og breadcrumbs
                setManualContent(manual.content || '');
                setSelectedManual(manual);
                setSelectedCategory({ id: manual.category_id });
                setSelectedSubcategory({ id: manual.subcategory_id });
                setSelectedSubSubcategory({ id: manual.subsubcategory_id });
                // console.log('Manual content updated with breadcrumbs.');
                return;
            }
    
            // Standard-scenario: Navigasjon gjennom kategori
            const data = await response.json();
            // console.log('Fetched Manual Data:', data);
    
            if (!Array.isArray(data) || data.length === 0) {
                throw new Error('No manuals found for this category');
            }
    
            const manual = data.find((item) => item.id === manualId);
            // console.log('Selected Manual:', manual);
    
            if (!manual || !manual.content) {
                throw new Error('Manual content not found');
            }
    
            setManualContent(manual.content || '');
            setSelectedManual(manual);
            // console.log('Manual content updated.');
        } catch (error) {
            console.error('Error fetching manual content:', error.message);
        }
    };
    
    
    const handleSearchClick = async () => {
        unstable_batchedUpdates(() => {
            setSelectedCategory(null);
            setSelectedSubcategory(null);
            setSelectedSubSubcategory(null);
            setManualTitles([]);
            setManualContent('');
            setSelectedManual(null);
        });
    };
    

    // Handle category click
    const handleCategoryClick = async (category) => {
        if (selectedCategory?.id === category.id) {
            // If the category is already selected and no subcategory is chosen, reset subcategory-related states
            unstable_batchedUpdates(() => {
                setSelectedSubcategory(null);
                setSelectedSubSubcategory(null);
                setManualTitles([]);
                setManualContent('');
                setSelectedManual(null);
            });
    
            // Fetch subcategories again to refresh the state
            await fetchSubcategories(category.id);
            return;
        }
    
        // Normal category selection process
        unstable_batchedUpdates(() => {
            setSelectedCategory(category);
            setSelectedSubcategory(null);
            setSelectedSubSubcategory(null);
            setManualTitles([]);
            setManualContent('');
            setSelectedManual(null);
        });
    
        await fetchManualTitles(category.id, 'category');
        await fetchSubcategories(category.id);
    };
    

    const handleSubcategoryClick = async (subcategory) => {
        if (selectedSubcategory?.id === subcategory.id) return;
        unstable_batchedUpdates(() => {
            setSelectedSubcategory(subcategory);
            setSelectedSubSubcategory(null);
            setManualTitles([]);
            setManualContent('');
            setSelectedManual(null);
        });

        await fetchManualTitles(subcategory.id, 'subcategory');
        await fetchSubSubcategories(subcategory.id);
    };

    const handleSubSubcategoryClick = async (subSubcategory) => {
        if (selectedSubSubcategory?.id === subSubcategory.id) return;
        unstable_batchedUpdates(() => {
            setSelectedSubSubcategory(subSubcategory);
            setManualTitles([]);
            setManualContent('');
            setSelectedManual(null);
        });

        await fetchManualTitles(subSubcategory.id, 'subsubcategory');
    };

    // Handle manual click (fetch content)
    const handleManualClick = async (manual) => {
        await fetchManualContent(manual.id);
    };

    const handleCreateManualClick = () => {
        if (!selectedCategory && !selectedSubcategory && !selectedSubSubcategory) {
          alert('Please select a category, subcategory, or sub-subcategory first!');
          return;
        }
    
        // Store the current context before opening the modal
        setCurrentContext({
          categoryId: selectedCategory?.id || null,
          subcategoryId: selectedSubcategory?.id || null,
          subSubcategoryId: selectedSubSubcategory?.id || null,
        });
    
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setCurrentContext(null);
      };

   
    // Clear manual content (when clicking the breadcrumb to go back)
    // const clearManualContent = () => {
    //     unstable_batchedUpdates(() => {
    //         setManualContent('');
    //         setSelectedManual(null);
    //     });
    // };

    // const clearManualContent = () => {
    //     unstable_batchedUpdates(() => {
    //         setManualContent('');
    //         setSelectedManual(null);
    //         setManualTitles([]); // Reset manual titles
    //         setSelectedCategory(null); // Clear category selection
            
    //         setSelectedSubSubcategory(null); // Clear sub-subcategory selection
    //     });
    // };

    const handleBackToCategories = () => {
        setSelectedCategory(null); 
        setSubcategories([]); 
        setManualTitles([]); 
        setManualContent(''); 
        setSelectedSubcategory(null); 
        setSelectedSubSubcategory(null); 
        setSelectedManual(null); 
    };
    
    const handleBackToSubcategories = async () => {
        setSelectedSubcategory(null); 
        setSelectedSubSubcategory(null); 
        setManualTitles([]); 
        setManualContent(''); 
        setSelectedManual(null); 
    
        // Fetch manuals for the category again
        if (selectedCategory) {
            await fetchManualTitles(selectedCategory.id, 'category');
        }
    };
    
    const handleBackToSubSubcategories = async () => {
        setSelectedSubSubcategory(null); 
        setManualTitles([]); 
        setManualContent(''); 
        setSelectedManual(null); 
    
        // Fetch manuals for the subcategory again
        if (selectedSubcategory) {
            await fetchManualTitles(selectedSubcategory.id, 'subcategory');
        }
    };
    
    const handleBackToManualsList = async () => {
        setSelectedManual(null); 
        setManualContent(''); 
    
        if (selectedSubSubcategory) {
            await fetchManualTitles(selectedSubSubcategory.id, 'subsubcategory');
        } else if (selectedSubcategory) {
            await fetchManualTitles(selectedSubcategory.id, 'subcategory');
        }
    };
    
      
      
      

    const toggleAdminPanel = () => {
        setShowManualsModal(!showManualsModal);
    };

    // Render manual content
    const renderManualContent = () => {
     
        if (selectedManual) {
            return (
                <div className="manuals-message">
                    <h3 className="manuals-user-info">{selectedManual?.title}</h3>
                    <div className="manuals-message-content"  dangerouslySetInnerHTML={{ __html: manualContent }} />
                    <br></br>
                </div>
            );
        }
    
        return <p>No manuals available.</p>;
    };
    

    const handleOpenSearchModal = () => {
        setIsSearchModalOpen(true);
    };

    const handleCloseSearchModal = () => {
        setIsSearchModalOpen(false);
    };

    const handleManualSelect = (manual) => {
        // console.log('Manual selected:', manual);
        setIsSearchModalOpen(false); // Close the modal after selection
    };

    return (
        <div>
            <h2>Knowledge database</h2>
    
            {/* Main Layout */}
            <div className="manuals-center-layout">
                {/* Sidebar Menu */}
                <div className="manuals-center-sidebar">
                <div
                    className="manuals-center-category"
                    onClick={handleSearchClick}
                >
                    <div className="manuals-center-category-title">
                        Search
                    </div>
                </div>


                    <h3>Categories</h3>
                    {categories.length > 0 ? (
                        categories
                            .filter(cat => cat.title.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map(category => (
                                <div key={category.id} className="manuals-center-category">
                                    <div
                                        className="manuals-center-category-title"
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        {category.title}
                                    </div>
                                </div>
                            ))
                    ) : (
                        <p>No categories available</p>
                    )}
    
                    {isAdmin && (
                        <div className="manuals-modal-buttons">
                            <button onClick={toggleAdminPanel}>
                                {showManualsModal ? 'Close Admin Panel' : 'Open Admin Panel'}
                            </button>
    
                            {showManualsModal && <ManualsModal onClose={toggleAdminPanel} />}
                        </div>
                    )}


{/* {(isAdmin || isTuner || isSupport) && (selectedCategory || selectedSubcategory || selectedSubSubcategory) && (
      <div className="manuals-modal-buttons">
                        <button
                        onClick={handleCreateManualClick}
                        disabled={!selectedCategory && !selectedSubcategory && !selectedSubSubcategory}
                        >
                        Create Manual
                        </button>
                        </div>
                        )} */}
                </div>
    
                {/* Content Area */}
                <div className="manuals-center-content">
                

{/* <div className="manuals-center-breadcrumb">
  <span className="breadcrumb-link" onClick={handleBackToCategories}>
    Start
  </span>
  {selectedCategory && (
    <>
      {' > '}
      <span
        className="breadcrumb-link"
        onClick={handleBackToSubcategories}
      >
        {selectedCategory.title}
      </span>
    </>
  )}
  {selectedSubcategory && (
    <>
      {' > '}
      <span
        className="breadcrumb-link"
        onClick={handleBackToSubSubcategories}
      >
        {selectedSubcategory.title}
      </span>
    </>
  )}
  {selectedSubSubcategory && (
    <>
      {' > '}
      <span
        className="breadcrumb-link"
        onClick={handleBackToManualsList}
      >
        {selectedSubSubcategory.title}
      </span>
    </>
  )}
  {selectedManual && (
    <>
      {' > '}
      <span className="breadcrumb-link">{selectedManual.title}</span>
      <div className='breadcrumb-button-container'>
      <div className="manuals-modal-buttons">
      <button
                onClick={() => {
                    const manualLink = `${window.location.origin}/manual/${selectedManual.id}`;
                    navigator.clipboard.writeText(manualLink);
                    alert(`Link copied: ${manualLink}`);
                }}
            >
                Copy Hyperlink
            </button>
            </div>
            </div>
    </>
  )}

{(isAdmin || isTuner || isSupport) && (selectedCategory || selectedSubcategory || selectedSubSubcategory) && (
      <div className="manuals-modal-buttons">
                        <button
                        onClick={handleCreateManualClick}
                        disabled={!selectedCategory && !selectedSubcategory && !selectedSubSubcategory}
                        >
                        Create Manual
                        </button>
                        </div>
                        )}
</div> */}

<div className="manuals-center-breadcrumb">
  <div className="breadcrumb-links">
    <span className="breadcrumb-link" onClick={handleBackToCategories}>
      Start
    </span>
    {selectedCategory && (
      <>
        {' > '}
        <span
          className="breadcrumb-link"
          onClick={handleBackToSubcategories}
        >
          {selectedCategory.title}
        </span>
      </>
    )}
    {selectedSubcategory && (
      <>
        {' > '}
        <span
          className="breadcrumb-link"
          onClick={handleBackToSubSubcategories}
        >
          {selectedSubcategory.title}
        </span>
      </>
    )}
    {selectedSubSubcategory && (
      <>
        {' > '}
        <span
          className="breadcrumb-link"
          onClick={handleBackToManualsList}
        >
          {selectedSubSubcategory.title}
        </span>
      </>
    )}
    {selectedManual && (
      <>
        {' > '}
        <span className="breadcrumb-link">{selectedManual.title}</span>
      </>
    )}
  </div>

  <div className="breadcrumb-buttons-container">
    {selectedManual && (
      <button
        
        onClick={() => {
          const manualLink = `${window.location.origin}/manual/${selectedManual.id}`;
          navigator.clipboard.writeText(manualLink);
          alert(`Link copied: ${manualLink}`);
        }}
      >
        Copy Hyperlink
      </button>
    )}

    {(isAdmin || isTuner || isSupport) &&
      (selectedCategory || selectedSubcategory || selectedSubSubcategory) && (
        <button
          onClick={handleCreateManualClick}
          
        >
          Create Manual
        </button>
      )}
  </div>
</div>

      

    {/* Content Rendering */}
{!selectedCategory && !selectedSubcategory && !selectedSubSubcategory && manualTitles.length === 0 && (
    <ManualsSearch
        setManualTitles={setManualTitles}
        setSelectedManual={setSelectedManual}
        fetchManualContent={fetchManualContent}
        clearAllStates={clearAllStates}
    />
)}
    
                  

                   {/* Subcategories */}
{selectedCategory && !selectedSubcategory && subcategories.length > 0 && (
   

    <div className="manuals-categories-grid">
  {subcategories.map((subcategory) => (
    <div
      key={subcategory.id}
      className="manuals-category-card"
      onClick={() => handleSubcategoryClick(subcategory)}
    >
      {/* Left-side content */}
      <div className="manuals-category-content">
      
        <span className="manuals-category-header">{subcategory.title} </span>
        <span className="manuals-category-body">{subcategory.description}</span>
      </div>
      
      {/* Right-side icon */}
      {subcategory.icon && (
        <img
          src={`/assets/manualsicons/${subcategory.icon}`}
          alt="Icon"
          className="manuals-category-icon"
        />
      )}
    </div>
    
    
        ))}
    </div>
)}

{selectedSubcategory && !selectedSubSubcategory && subSubcategories.length > 0 && (
    <div className="manuals-categories-grid">
        {subSubcategories.map((subSubcategory) => (
            <div
                key={subSubcategory.id}
                className="manuals-category-card"
                onClick={() => handleSubSubcategoryClick(subSubcategory)}
            >
                {/* Left-side content */}
                <div className="manuals-category-content">
                    <h4 className="manuals-category-header">{subSubcategory.title}</h4>
                    <p className="manuals-category-body">{subSubcategory.description}</p>
                </div>

                {/* Right-side icon */}
                {subSubcategory.icon && (
                    <img
                        src={`/assets/manualsicons/${subSubcategory.icon}`}
                        alt="Icon"
                        className="manuals-category-icon"
                    />
                )}
            </div>
        ))}
    </div>
)}


{manualTitles.length > 0 && !selectedManual && (
    <div className="manuals-title-list">
        <h3>Manuals</h3>
        <div>
        
      </div>
        {manualTitles
            .filter(manual => {
                // Ensure correct rendering:
                if (selectedSubSubcategory) {
                    return manual.subsubcategory_id === selectedSubSubcategory.id;
                } else if (selectedSubcategory) {
                    return !manual.subsubcategory_id && manual.subcategory_id === selectedSubcategory.id;
                } else if (selectedCategory) {
                    return !manual.subsubcategory_id && !manual.subcategory_id && manual.category_id === selectedCategory.id;
                }
                return false;
            })
            .map(manual => (
                <div
                    key={manual.id}
                    className="manuals-topic"
                    onClick={() => handleManualClick(manual)}
                >
                    {manual.title}
                </div>
            ))}
    </div>
)}

    
                    {/* Fallback for No Manuals */}
                    {selectedCategory && manualTitles.length === 0 && !selectedManual && (
                        <div className="">
                            <p></p>
                        </div>
                    )}
    
                    {/* Manual Content Section */}
                    {selectedManual && (
                        <div className="content">
                            {renderManualContent()}
                            
                        </div>
                    )}

                {isModalOpen && (
                        <CreateManualModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                context={{
                    category: selectedCategory,
                    subcategory: selectedSubcategory,
                    subSubcategory: selectedSubSubcategory,
                }}
                    />

      )}
                     
                </div>
                
            </div>
            
        </div>
        
    );
    
}
