import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const Development = () => {
    const [period, setPeriod] = useState('30');
    const [selectedMember, setSelectedMember] = useState('');
    const [members, setMembers] = useState([]);
    const [chartData, setChartData] = useState({ datasets: [] });
    const [uploadCounts, setUploadCounts] = useState({ currentCount: 0, previousCount: 0 });
    const [medianData, setMedianData] = useState({ currentMedian: 0, previousMedian: 0 });
    const [percentChange, setPercentChange] = useState({ change: 0 });
    const [memberUploadStats, setMemberUploadStats] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_SITE_URL + '/members/uploads-stats')
            .then(response => response.json())
            .then(data => {
                setMemberUploadStats(data);
            })
            .catch(error => console.error('Error fetching member upload stats:', error));
    }, []);

    const calculateChange = (current, previous) => {
        if (previous === 0) return current > 0 ? 100 : 0;
        return ((current - previous) / previous * 100).toFixed(2);
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_SITE_URL + '/members/opti')
            .then(response => response.json())
            .then(data => {
                setMembers(data);
            })
            .catch(error => console.error('Error fetching members:', error));
    }, []);

    useEffect(() => {
        const memberParam = selectedMember ? `&mid=${selectedMember}` : '';
        fetch(process.env.REACT_APP_SITE_URL + `/uploads/development-data?period=${period}${memberParam}`)
            .then(response => response.json())
            .then(data => {
                const processedData = processChartData(data, period);
                setChartData(processedData.chartData);
                setUploadCounts(processedData.uploadCounts);
                setMedianData(processedData.medianData);
                setPercentChange(processedData.percentChange);
            })
            .catch(error => console.error('Error fetching development data:', error));
    }, [period, selectedMember]);

    const processChartData = (data, period) => {
        const segments = 10;
        const now = moment();
        let currentPeriodDays;
        let previousPeriodDays;
        let startDate, previousPeriodStartDate, endDate, previousEndDate;

        switch (period) {
            case '30':
                currentPeriodDays = 30;
                previousPeriodDays = 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '60':
                currentPeriodDays = 60;
                previousPeriodDays = 60;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '90':
                currentPeriodDays = 90;
                previousPeriodDays = 90;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '6m':
                currentPeriodDays = 6 * 30;
                previousPeriodDays = 6 * 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '12m':
                currentPeriodDays = 12 * 30;
                previousPeriodDays = 12 * 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case 'short':
                currentPeriodDays = 30;
                previousPeriodDays = 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(previousPeriodDays, 'days');
                previousEndDate = startDate;
                break;
            case 'mid':
                currentPeriodDays = 60;
                previousPeriodDays = 60;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(previousPeriodDays, 'days');
                previousEndDate = startDate;
                break;
            case 'long':
                currentPeriodDays = 90;
                previousPeriodDays = 90;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(previousPeriodDays, 'days');
                previousEndDate = startDate;
                break;
            default:
                currentPeriodDays = 30;
                previousPeriodDays = 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
        }

        const currentPeriodData = Array(segments).fill(0);
        const previousPeriodData = Array(segments).fill(0);

        let currentCount = 0;
        let previousCount = 0;

        data.forEach(item => {
            const createdDate = moment(item.created);
            if (createdDate.isBetween(startDate, now)) {
                currentCount++;
                const segment = Math.floor((currentPeriodDays - now.diff(createdDate, 'days') - 1) / (currentPeriodDays / segments));
                currentPeriodData[segment]++;
            } else if (createdDate.isBetween(previousPeriodStartDate, previousEndDate)) {
                previousCount++;
                const segment = Math.floor((previousPeriodDays - previousEndDate.diff(createdDate, 'days') - 1) / (previousPeriodDays / segments));
                previousPeriodData[segment]++;
            }
        });

        const currentMedian = calculateMedian(currentPeriodData);
        const previousMedian = calculateMedian(previousPeriodData);

        const change = calculatePercentChange(currentMedian, previousMedian);

        return {
            chartData: {
                labels: Array.from({ length: segments }, (_, i) => {
                    const segmentStart = startDate.clone().add(i * (currentPeriodDays / segments), 'days');
                    const segmentEnd = segmentStart.clone().add(currentPeriodDays / segments - 1, 'days');
                    return `${segmentStart.format('MM/DD')} - ${segmentEnd.format('MM/DD')}`;
                }),
                datasets: [
                    {
                        label: 'Current Period',
                        data: currentPeriodData,
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y', // Dette vil bli standard nå
                    },
                    {
                        label: 'Previous Period',
                        data: previousPeriodData,
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        yAxisID: 'y', // Bruker samme y-akse som 'Current Period'
                    }
                ]
                
            },
            uploadCounts: {
                currentCount,
                previousCount
            },
            medianData: {
                currentMedian,
                previousMedian
            },
            percentChange: {
                change
            }
        };
    };

    const calculateMedian = (data) => {
        const sortedData = data.slice().sort((a, b) => a - b);
        const mid = Math.floor(sortedData.length / 2);

        return sortedData.length % 2 !== 0 ? sortedData[mid] : (sortedData[mid - 1] + sortedData[mid]) / 2;
    };

    const calculatePercentChange = (current, previous) => {
        if (previous === 0) return current > 0 ? 100 : 0;
        return ((current - previous) / previous * 100).toFixed(2);
    };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    const handleMemberChange = (event) => {
        setSelectedMember(event.target.value);
    };

    const containerStyle = {
        padding: '20px',
        backgroundColor: '#121212',  // Dark background
        color: '#ffffff',  // White text
        borderRadius: '8px',

        margin: '0 auto',
        marginTop: '40px',
    };

    const selectStyle = {
        marginBottom: '20px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#333333',
        color: '#ffffff',
        border: 'none',
        marginRight: '10px',
    };

    const chartContainerStyle = {
        height: '500px',
    };

    const medianContainerStyle = {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px',
    };

    const medianBoxStyle = {
        padding: '10px',
        backgroundColor: '#333333',
        color: '#ffffff',
        borderRadius: '4px',
        textAlign: 'center',
    };

    const percentChangeStyle = {
        marginTop: '10px',
        fontSize: '14px',
    };

    const medianChartData = {
        labels: ['Current Period', 'Previous Period'],
        datasets: [
            {
                label: 'Median Uploads',
                data: [medianData.currentMedian, medianData.previousMedian],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)'
                ],
                borderWidth: 1,
            }
        ]
    };

    const tableHeaderStyle = {
        padding: '10px',
        backgroundColor: '#444444',
        textAlign: 'left',
        borderBottom: '2px solid #555555',
    };
    
    const tableCellStyle = {
        padding: '10px',
        borderBottom: '1px solid #555555',
    };

    

    return (

        
        <div style={containerStyle}>
<div style={{ marginBottom: '40px' }}>
    <h2 style={{ color: '#ffffff' }}>Member Upload Statistics</h2>
    <table style={{ width: '100%', backgroundColor: '#333333', color: '#ffffff', borderCollapse: 'collapse', margin: '20px 0' }}>
    <thead>
    <tr>
        <th style={tableHeaderStyle}>Member Name</th>
        <th style={tableHeaderStyle}>Uploads<br></br> 30 Days</th>
        <th style={tableHeaderStyle}>Uploads<br></br>30-60<br></br> Days Ago</th>
        <th style={tableHeaderStyle}>% (30-60 Days)</th>
        <th style={tableHeaderStyle}>Uploads<br></br> 90 Days</th>
        <th style={tableHeaderStyle}>Uploads<br></br>90-180<br></br> Days Ago</th>
        <th style={tableHeaderStyle}>% (90-180 Days)</th>
        <th style={tableHeaderStyle}>Uploads<br></br> 90 Days<br></br>(Last Year)</th>
        <th style={tableHeaderStyle}>% Change<br></br>(Last Year vs 90 Days)</th>
        <th style={tableHeaderStyle}>Average Uploads<br></br>Per Month<br></br>(Last 90 Days)</th>
        <th style={tableHeaderStyle}>Expected</th> {/* Blank kolonne */}
        <th style={tableHeaderStyle}>Result</th> {/* Blank kolonne */}
    </tr>
</thead>

        <tbody>
        {memberUploadStats.map((stat, index) => (
                <tr key={stat.memberId}>
  <td style={tableCellStyle}>{stat.memberName}</td>
  <td style={tableCellStyle}>{stat.last30Days}</td>
  <td style={tableCellStyle}>{stat.last30to60Days}</td>
  <td style={tableCellStyle}>{stat.percentChange30To60}%</td>
  <td style={tableCellStyle}>{stat.last90Days}</td>
  <td style={tableCellStyle}>{stat.last90to180Days}</td>
  <td style={tableCellStyle}>{stat.percentChange90To180}%</td>
  <td style={tableCellStyle}>{stat.lastYear90Days}</td>
  <td
  style={{
    ...tableCellStyle,
    backgroundColor: !stat.lastYear90Days
      ? 'rgba(0, 0, 255, 0.1)' // Nesten gjennomsiktig blå for N/A eller null
      : ((stat.last90Days - stat.lastYear90Days) / stat.lastYear90Days) * 100 > 0
      ? 'green' // Grønn for positiv prosent
      : 'red', // Rød for negativ prosent
    color: !stat.lastYear90Days ? '#000' : '#fff', // Svart tekst for blå bakgrunn, hvit for andre
  }}
>
  {stat.lastYear90Days > 0
    ? `${(((stat.last90Days - stat.lastYear90Days) / stat.lastYear90Days) * 100).toFixed(2)}%`
    : 'N/A'}
</td>

  <td style={tableCellStyle}>{stat.averageUploadsPerMonth}</td>
  <td style={tableCellStyle}>
                <input
                    type="number"
                    style={{ width: '80px' }}
                    defaultValue={stat.expectedValue || ''}
                    onBlur={(e) => {
                        const newValue = parseFloat(e.target.value);

                        // Avoid unnecessary calls for empty or unchanged values
                        if (!isNaN(newValue) && newValue !== stat.expectedValue) {
                            // Update backend
                            fetch(process.env.REACT_APP_SITE_URL + '/members/expected', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    memberId: stat.memberId,
                                    expectedValue: newValue
                                })
                            })
                                .then((response) => {
                                    if (response.ok) {
                                        // console.log(`Expected value for Member ID ${stat.memberId} updated to ${newValue}`);
                                    } else {
                                        // console.error('Failed to update expected value:', response.statusText);
                                    }
                                })
                                .catch((error) => {
                                    console.error('Error updating expected value:', error);
                                });

                            // Update the local state to trigger re-render
                            setMemberUploadStats((prevStats) =>
                                prevStats.map((s, i) =>
                                    i === index
                                        ? {
                                              ...s,
                                              expectedValue: newValue,
                                              resultPercentage: ((newValue - stat.averageUploadsPerMonth) / stat.averageUploadsPerMonth) * 100
                                          }
                                        : s
                                )
                            );
                        }
                    }}
                />
            </td>
            <td
  style={{
    ...tableCellStyle,
    backgroundColor: !stat.expectedValue
      ? 'rgba(0, 0, 255, 0.1)' // Nesten gjennomsiktig blå for NA eller ingen verdi
      : ((stat.averageUploadsPerMonth - stat.expectedValue) / stat.expectedValue) * 100 > 0
      ? 'green' // Grønn for positiv prosent
      : 'red', // Rød for negativ prosent
    color: '#fff', // Gjør teksten hvit for bedre kontrast mot grønn og rød
  }}
>
  {stat.expectedValue
    ? `${(((stat.averageUploadsPerMonth - stat.expectedValue) / stat.expectedValue) * 100).toFixed(2)}%`
    : 'NA'}
</td>


</tr>

            ))}
        </tbody>
    </table>
</div>


            <div>
                <select style={selectStyle} value={period} onChange={handlePeriodChange}>
                    <option value="30">Last 30 days (vs last year)</option>
                    <option value="60">Last 60 days (vs last year)</option>
                    <option value="90">Last 90 days (vs last year)</option>
                    <option value="6m">Last 6 months (vs last year)</option>
                    <option value="12m">Last 12 months (vs last year)</option>
                    <option value="short">Shorttime dev (30vs30-60)</option>
                    <option value="mid">Midtime dev (60vs60-120)</option>
                    <option value="long">Longtime dev (90vs90-180)</option>
                </select>
                <select style={selectStyle} value={selectedMember} onChange={handleMemberChange}>
                    <option value=''>All Members</option>
                    {members.map(member => (
                        <option key={member.id} value={member.id}>{member.name}</option>
                    ))}
                </select>
            </div>
            <div style={medianContainerStyle}>
                <div style={medianBoxStyle}>
                    <h3>Current Period Uploads</h3>
                    <p>{uploadCounts.currentCount}</p>
                    <p style={percentChangeStyle}>Change from Previous Period: {percentChange.change}%</p>
                </div>
                <div style={medianBoxStyle}>
                    <h3>Previous Period Uploads</h3>
                    <p>{uploadCounts.previousCount}</p>
                </div>
            </div>
            <div style={chartContainerStyle}>
            <Line data={chartData} options={{
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                color: '#ffffff'  // White text for the legend
            }
        },
        title: {
            display: true,
            text: 'Uploads Comparison Over Time',
            color: '#ffffff'  // White text for the title
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#ffffff'  // White text for x-axis
            }
        },
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
                color: '#ffffff'  // White text for y-axis
            },
            title: {
                display: true,
                text: 'Uploads',
                color: '#ffffff'  // White text for y-axis title
            }
        }
    }
}} />

            </div>
            <div style={chartContainerStyle}>
                <Bar data={medianChartData} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                color: '#ffffff'  // White text for the legend
                            }
                        },
                        title: {
                            display: true,
                            text: 'Median Uploads Comparison',
                            color: '#ffffff'  // White text for the title
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: '#ffffff'  // White text for x-axis
                            }
                        },
                        y: {
                            ticks: {
                                color: '#ffffff'  // White text for y-axis
                            },
                            title: {
                                display: true,
                                text: 'Median Uploads',
                                color: '#ffffff'  // White text for y-axis title
                            }
                        }
                    }
                }} />
            </div>
        </div>
    );
};

export default Development;
