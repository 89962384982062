import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function WeekPlanner({ tasks, onTaskClick }) {
    const [currentDate, setCurrentDate] = useState(moment()); // Dagens dato som utgangspunkt
    const [weekTasks, setWeekTasks] = useState([]);

    useEffect(() => {
        const startOfWeek = currentDate.clone().startOf('week').add(1, 'day'); // Mandag
        const endOfWeek = currentDate.clone().endOf('week'); // Søndag

        // Filtrer oppgaver som er relevante for inneværende uke
        const filteredTasks = tasks.filter(task => {
            const taskStart = moment(task.datetime);
            const taskEnd = task.enddatetime ? moment(task.enddatetime) : taskStart;
            return !task.done && taskStart.isBefore(endOfWeek) && taskEnd.isAfter(startOfWeek);
        });

        // Kartlegg oppgaver til uken
        const tasksForWeek = filteredTasks.map(task => {
            const taskStart = moment(task.datetime);
            const taskEnd = task.enddatetime ? moment(task.enddatetime) : taskStart;
            const startDayIndex = Math.max(0, taskStart.diff(startOfWeek, 'days')); // Indeks for startdag
            const endDayIndex = Math.min(6, taskEnd.diff(startOfWeek, 'days')); // Indeks for sluttdag

            return {
                ...task,
                gridColumn: `${startDayIndex + 1} / ${endDayIndex + 2}`, // Beregn grid-kolonner
            };
        });

        setWeekTasks(tasksForWeek);
    }, [currentDate, tasks]);

    // Bla til neste eller forrige uke
    const changeWeek = (direction) => {
        setCurrentDate(currentDate.clone().add(direction, 'week'));
    };

    const formatTime = (dateTimeString) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: false };
        return new Date(dateTimeString).toLocaleTimeString('no-NO', options);
    };

    return (
        <div className="week-planner">
            <div className="week-header">
                <div className="week-info">
                    <h3>{currentDate.format('MMMM YYYY')}</h3>
                    <h4>Week {currentDate.isoWeek()}</h4>
                </div>
                <div className="week-navigation">
                    <button onClick={() => changeWeek(-1)}>◀ Previous</button>
                    <button onClick={() => changeWeek(1)}>Next ▶</button>
                </div>
            </div>
            <div className="week-grid">
                {[...Array(7)].map((_, i) => {
                    const date = currentDate.clone().startOf('week').add(i + 1, 'days');
                    return (
                        <div className="day-column" key={i}>
                            <div className="day-header">
                                <div className="day-name">{date.format('dddd')} {date.format('DD')}</div>
                            </div>
                        </div>
                    );
                })}
                {weekTasks.map(task => (
                    <div
                        key={task.id}
                        className="task-item"
                        style={{
                            gridColumn: task.gridColumn,
                        }}
                        onClick={() => {
                            // console.log('Task clicked:', task); // Legg til logging her
                            onTaskClick(task);
                        }}
                    >
                        <span>{task.vehicle} </span>
                        <span>
                            {formatTime(task.datetime)}
                            {task.enddatetime && ` → ${moment(task.enddatetime).format('HH:mm')}`}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}
