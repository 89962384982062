import React, { useState, useEffect } from 'react';
import defaultModelImage from '../assets/modelimage.png';
import defaultDynoImage from '../assets/dynoimage.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

const DatabaseClassic = ({ country }) => {
  const [vehicleModels, setVehicleModels] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [copiedOpti, setCopiedOpti] = useState(false);
  const [copiedPower, setCopiedPower] = useState(false);
    const [viewImage, setViewImage] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SITE_URL}/vehicle-models`)
      .then((response) => response.json())
      .then((data) => setVehicleModels(data))
      .catch((error) => console.error('Error fetching vehicle models:', error));
  }, []);

  const types = [...new Set(vehicleModels.map((model) => model.typeName))];
  const manufacturers = selectedType
    ? [...new Set(vehicleModels.filter((model) => model.typeName === selectedType).map((model) => model.manufacturerName))]
    : [];
  const ranges = selectedManufacturer
    ? [...new Set(vehicleModels.filter((model) => model.manufacturerName === selectedManufacturer).map((model) => model.rangeName))]
    : [];
  const models = selectedRange
    ? vehicleModels.filter((model) => model.rangeName === selectedRange)
    : [];

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setSelectedManufacturer(null);
    setSelectedRange(null);
    setSelectedModel(null);
  };

  const openImage = (imageUrl) => {
    setViewImage(imageUrl);
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if (type === 'opti') {
        setCopiedOpti(true);
        setTimeout(() => setCopiedOpti(false), 2500);
      } else if (type === 'power') {
        setCopiedPower(true);
        setTimeout(() => setCopiedPower(false), 2500);
      }
    }, (err) => {
      console.error('Failed to copy text to clipboard', err);
    });
  };


  const handleManufacturerSelect = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setSelectedRange(null);
    setSelectedModel(null);
  };

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    setSelectedModel(null);
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  return (
    <div className="database-classic-container">
      <div className="breadcrumbs">
        {selectedType && (
          <span onClick={() => handleTypeSelect(null)}>Type</span>
        )}
        {selectedManufacturer && (
          <span onClick={() => handleManufacturerSelect(null)}> > Manufacturer</span>
        )}
        {selectedRange && (
          <span onClick={() => handleRangeSelect(null)}> > Range</span>
        )}
        {selectedModel && (
          <span onClick={() => handleModelSelect(null)}> > Model</span>
        )}
      </div>

      {!selectedType && (
        <div className="type-selection">
          {types.map((type) => (
            <div key={type} onClick={() => handleTypeSelect(type)} className="clickable-item">
              <img 
                src={`/images/types/${type.toLowerCase()}.svg`} 
                alt={type} 
                className="type-icon"
              />
              <span>{type}</span>
            </div>
          ))}
        </div>
      )}

      {selectedType && !selectedManufacturer && (
        <div className="manufacturer-selection">
          {manufacturers.map((manufacturer) => (
            <div
              key={manufacturer}
              onClick={() => handleManufacturerSelect(manufacturer)}
              className="clickable-item"
            >
              <img 
                src={`/images/manufacturers/${manufacturer.toLowerCase()}.webp`} 
                alt={manufacturer} 
                className="manufacturer-image"
              />
              {/* {manufacturer} */}
            </div>
          ))}
        </div>
      )}

      {selectedManufacturer && !selectedRange && (
        <div className="range-selection">
          <ul className="range-list">
            {ranges.map((range) => (
              <li key={range} onClick={() => handleRangeSelect(range)} className="range-item">
                <img
                  src={`/images/manufacturers/${selectedManufacturer.toLowerCase()}.webp`}
                  alt={selectedManufacturer}
                  className="list-manufacturer-image"
                />
                {range}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedRange && !selectedModel && (
        <div className="model-selection">
          <ul className="model-list">
          {models.map((model) => (
            <li key={model.id} onClick={() => handleModelSelect(model)} className="model-item">
              <img
                src={`/images/manufacturers/${selectedManufacturer.toLowerCase()}.webp`}
                alt={selectedManufacturer}
                className="list-manufacturer-image"
              />
              {model.modelName} 
              {/* - {model.fueltype} */}
              <span className="model-statsclassic">
                {model.fueltype !== 3 && (
                  <span className="model-opti">
                    Opti: <b>{model.power_opti} Hp / {model.torque_opti} Nm</b>
                  </span>
                )}
                <span className="model-power">
                  Power: <b>{model.power} Hp / {model.torque} Nm</b>
                </span>
              </span>
            </li>
          ))}

          </ul>
        </div>
      )}

      {selectedModel && (



                  <div>
                    <div className="model-name-display">
                      <h2>{selectedModel.modelName}</h2>
                    </div>
                    <div className="model-expanded-details">
                    <span className="databasemodal-orivalue">
                      <div className="db3image-container">
                            <img
                              src={selectedModel.modelimage || defaultModelImage}
                              alt="Model Image"
                              className="detail-image"
                              onClick={() => openImage(selectedModel.modelimage || defaultModelImage)}
                            />
                       </div>
                      </span>
                      <div className="text-details">
                        <div class="databasemodal-details">
                          <div class="databasemodal-fullrow">
                            <div class="databasemodal-label">
                              Original: 
                              <span class="databasemodal-orivalue">
                                {selectedModel.power_original} Hp / {selectedModel.torque_original} Nm
                              </span>
                            </div>
                          </div>

                          {selectedModel.fueltype !== 3 && (
                          <div class="databasemodal-row">
                            <div class="databasemodal-left">
                                <span 
                                  className={`copy-iconopti ${copiedOpti ? 'copied' : ''}`} 
                                  onClick={() => copyToClipboard(`${selectedModel.power_opti} Hp / ${selectedModel.torque_opti} Nm`, 'opti')}
                                >
                                  <FontAwesomeIcon icon={copiedOpti ? faCheck : faCopy} />
                                </span>
                            </div>
                            <div class="databasemodal-right">
                                  <div class="databasemodal-optivalue">
                                    Opti: 
                                    <span class="databasemodal-orivalue">
                                      {selectedModel.power_opti} Hp / {selectedModel.torque_opti} Nm
                                      <br />
                                      <span className="databasemodal-difference">
                                        {`+${selectedModel.power_opti - selectedModel.power_original} Hp / +${selectedModel.torque_opti - selectedModel.torque_original} Nm`}
                                      </span>
                                    </span>
                                  </div>      
                            </div>
                          </div>
                        )}

                          <div class="databasemodal-row">
                            <div class="databasemodal-left">
                              <span 
                                className={`copy-iconpower ${copiedPower ? 'copied' : ''}`} 
                                onClick={() => copyToClipboard(`${selectedModel.power} Hp / ${selectedModel.torque} Nm`, 'power')}
                              >
                                <FontAwesomeIcon icon={copiedPower ? faCheck : faCopy} />
                              </span>
                            </div>
                            <div class="databasemodal-right">
                              <div class="databasemodal-powervalue">
                                Power: 
                                <span class="databasemodal-orivalue">
                                  {selectedModel.power} Hp / {selectedModel.torque} Nm
                                  <br />
                                  <span className="databasemodal-difference">
                                    {`+${selectedModel.power - selectedModel.power_original} Hp / +${selectedModel.torque - selectedModel.torque_original} Nm`}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                    </div>
                      <div class="databasemodal-label">
                      Notes:
                        <span class="databasemodal-orivalue">
                        {selectedModel.notes}
                        </span>
                      </div>
                      </div>
                      <span className="databasemodal-orivalue">
                      <div className="db3image-container">
                      {selectedModel.dynoimage ? (
                      <img
                        src={selectedModel.dynoimage}
                        alt="Dyno Image"
                        className="detail-image"
                        onClick={() => openImage(selectedModel.dynoimage)}
                      />
                    ) : (
                      <div className="dyno-chart-container">

                            <img
                              src={selectedModel.dynoimage || defaultDynoImage}
                              alt="Dyno Image"
                              className="detail-image"
                              onClick={() => openImage(selectedModel.dynoimage || defaultDynoImage)}
                                                    />

                              {/* <DynoChart
                                powerOriginal={model.power_original}
                                torqueOriginal={model.torque_original}
                                powerOpti={model.power_opti}
                                torqueOpti={model.torque_opti}
                              /> */}
                            </div>
                          )}

                       </div>
                      </span>
                    </div>
                    <div className="db3split">
                    <div className="dbcolumn">
                      <div className="databasemodal-label">
                        OBD Location:
                        <span className="databasemodal-orivalue">
                          {selectedModel.obd_location}
                          <br />
                          <div className="db3image-container">

                              <img
                                src={selectedModel.obdimage || defaultModelImage}
                                alt="OBD Picture"
                                className="littledetail-image"
                                onClick={() => openImage(selectedModel.obdimage || defaultModelImage)}
                              />

                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="dbcolumn">
                      <div className="databasemodal-label">
                        ECU Location:
                        <span className="databasemodal-orivalue">
                          {selectedModel.ecu_location}
                          <br />
                          <div className="db3image-container">

                              <img
                                src={selectedModel.ecuimage || defaultModelImage}
                                alt="ECU Picture"
                                className="littledetail-image"
                                onClick={() => openImage(selectedModel.ecuimage || defaultModelImage)}
                              />

                          </div>
                        </span>
                      </div>
                    </div>                    <div className="dbcolumn">
                      <div className="databasemodal-label">
                        ACM Location:
                        <span className="databasemodal-orivalue">
                          {selectedModel.acm_location}
                          <br />
                          <div className="db3image-container">

                              <img
                                src={selectedModel.acmimage || defaultModelImage}
                                alt="ACM Picture"
                                className="littledetail-image"
                                onClick={() => openImage(selectedModel.acmimage || defaultModelImage)}
                              />
                          </div>
                        </span>
                      </div>
                    </div>
                    </div>
                  </div>
      )}
    </div>
  );
};

export default DatabaseClassic;
