import React, { useState, useEffect } from 'react';

const LatestForumTopics = () => {
    const [latestTopics, setLatestTopics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchLatestTopics = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/latest-forum-topics`);
                if (response.ok) {
                    const data = await response.json();
                    console.log("Fetched topics:", data); // Debugging
                    setLatestTopics(data);
                } else {
                    console.error('Failed to fetch latest forum topics');
                }
            } catch (error) {
                console.error('Error fetching latest forum topics:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchLatestTopics();
    }, []);
    

    return (
        <div className="chart123123">
            {isLoading ? (
                <p>Loading...</p>
            ) : latestTopics.length === 0 ? (
                <p>No topics available.</p>
            ) : (
<ul className="latest-topics-list">
    {latestTopics.map((topic) => (
        <li key={topic.topic_id}>
            <span className="topic-title">{topic.title}</span>
            <span className="topic-meta">
                {topic.last_message_date !== "No Date"
                    ? new Date(topic.last_message_date).toLocaleDateString('no-NO')
                    : "No Date"}{" "}
                by {topic.last_user}
            </span>
        </li>
    ))}
</ul>


            )}
        </div>
    );
};

export default LatestForumTopics;
