import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './auth/AuthProvider';
import { UserProvider } from './context/UserContext';
import Login from './components/Login';
import TopBar from './components/TopBar';
import FilePortal from './components/FilePortal';
import Database from './components/Database';
import Upload from './components/Upload';
import Support from './components/opticenter/Support';
import Opticenter from './components/opticenter/Opticenter';
import Profilesite from './components/Profilesite';
import Adminsite from './components/Adminsite';
import FileDetails from './components/FileDetails';
import NotFound from './components/NotFound';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ManualDetail from './components/opticenter/ManualDetail';
//import useFCM from './components/useFCM';
//import { onMessage } from "firebase/messaging";
//import { messaging } from './firebase'; // Adjust the import path as needed
import View from './components/View'; // Pass på at stien er korrekt


import './App.css';

function ProtectedRoute({ children }) {
    const { authToken } = useAuth();
    return authToken ? children : <Navigate to="/login" />;
}

function App() {

    const [showDatabaseModal, setShowDatabaseModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showAdminModal, setShowAdminModal] = useState(false);

    const openDatabaseModal = () => setShowDatabaseModal(true);
    const closeDatabaseModal = () => setShowDatabaseModal(false);
    const openUploadModal = () => setShowUploadModal(true);
    const closeUploadModal = () => setShowUploadModal(false);
    const openProfileModal = () => setShowProfileModal(true);
    const closeProfileModal = () => setShowProfileModal(false);
    const openAdminModal = () => setShowAdminModal(true);
    const closeAdminModal = () => setShowAdminModal(false);


    return (
        <AuthProvider>
            <UserProvider>
                <Router>
                    <AppContent
                        openDatabaseModal={openDatabaseModal}
                        openUploadModal={openUploadModal}
                        openProfileModal={openProfileModal}
                        openAdminModal={openAdminModal}
                        showDatabaseModal={showDatabaseModal}
                        closeDatabaseModal={closeDatabaseModal}
                        showUploadModal={showUploadModal}
                        closeUploadModal={closeUploadModal}
                        showProfileModal={showProfileModal}
                        closeProfileModal={closeProfileModal}
                        showAdminModal={showAdminModal}
                        closeAdminModal={closeAdminModal}
                    />
                </Router>
            </UserProvider>
        </AuthProvider>
    );
}

function AppContent(props) {
    // Using useLocation hook within a Router context
    const location = useLocation();

    return (
        <>
            {location.pathname !== '/login' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password' && (
                <TopBar
                    openDatabaseModal={props.openDatabaseModal}
                    openUploadModal={props.openUploadModal}
                    openProfileModal={props.openProfileModal}
                    openAdminModal={props.openAdminModal}
                />
            )}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<ProtectedRoute><FilePortal /></ProtectedRoute>} />
                <Route path="/support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
                <Route path="/opticenter" element={<ProtectedRoute><Opticenter /></ProtectedRoute>} />
                <Route path="/profilesite" element={<ProtectedRoute><Profilesite /></ProtectedRoute>} />
                <Route path="/adminsite" element={<ProtectedRoute><Adminsite /></ProtectedRoute>} />
                <Route path="/file/:id" element={<ProtectedRoute><FileDetails /></ProtectedRoute>} />
                <Route path="*" element={<NotFound />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/View" element={<ProtectedRoute><View /></ProtectedRoute>} />
                <Route
    path="/manual/:manualId"
    element={
        <ProtectedRoute>
            <Opticenter>
                <ManualDetail />
            </Opticenter>
        </ProtectedRoute>
    }
/>


                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <FilePortal setActiveAdminComponent={props.setActiveAdminComponent} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/adminsite"
                    element={
                        <ProtectedRoute>
                            <Adminsite activeComponent={props.activeAdminComponent} />
                        </ProtectedRoute>
                    }
                />
           


            </Routes>
            {props.showDatabaseModal && <Database onClose={props.closeDatabaseModal} />}
            {props.showUploadModal && <Upload onClose={props.closeUploadModal} />}
            {props.showProfileModal && <Profilesite onClose={props.closeProfileModal} />}
            {props.showAdminModal && <Adminsite onClose={props.closeAdminModal} />}
        </>
    );
}

export default App;
