import React, { useState, useEffect } from 'react';

const AlertCenter = () => {
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [filter, setFilter] = useState('All'); // All, Opti, Opti Norge, Opti Sverige
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getnotificationslog`);
        const data = await response.json();
        setNotifications(data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);


  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/users`);
        const data = await response.json();
        const optiMembers = data
          .filter((member) => member.opti === 1)
          .sort((a, b) => a.name.localeCompare(b.name));
        setMembers(optiMembers);
        setFilteredMembers(optiMembers);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching members:', error);
        setIsLoading(false);
      }
    };

    fetchMembers();
  }, []);

  useEffect(() => {
    switch (filter) {
      case 'Opti':
        setFilteredMembers(members.filter((member) => member.name.toLowerCase().includes('optituning')));
        break;
      case 'Opti Norge':
        setFilteredMembers(
          members.filter(
            (member) => member.country === 'NO' && !member.name.toLowerCase().includes('vp')
          )
        );
        break;
      case 'Opti Sverige':
        setFilteredMembers(
          members.filter(
            (member) => member.country === 'SE' && !member.name.toLowerCase().includes('vp')
          )
        );
        break;
      default:
        setFilteredMembers(members);
    }
  }, [filter, members]);

  const handleSelectAll = () => {
    if (selectedMembers.length === filteredMembers.length) {
      setSelectedMembers([]); // Hvis alle allerede er valgt, fjern valgene
    } else {
      setSelectedMembers(filteredMembers.map((member) => member.id)); // Velg alle
    }
  };

  const handleSelectMember = (id) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((memberId) => memberId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSendAlert = async () => {
    if (!title || !message) {
      alert('Title and message are required');
      return;
    }

    setIsLoading(true);
    try {
      const endpoint = selectedMembers.length === 0
        ? '/send-mass-notification'
        : '/send-group-notification';

      const payload = {
        title,
        body: message,
        data: {}
      };

      if (selectedMembers.length > 0) {
        payload.userIds = selectedMembers;
      }

      const response = await fetch(`${process.env.REACT_APP_SITE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Failed to send notifications');
      }

      const result = await response.json();
      alert(`Notifications sent successfully!\nSuccessful: ${result.summary.successful}\nFailed: ${result.summary.failed}`);

      // Clear form
      setTitle('');
      setMessage('');
      setSelectedMembers([]);

    } catch (error) {
      console.error('Error sending notifications:', error);
      alert('Failed to send notifications. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="alertcenter-container">
      {/* Venstre kolonne */}
      <div className="alertcenter-column">
        <div className="alertcenter-filters">
          <label>
            <input
              type="radio"
              value="All"
              checked={filter === 'All'}
              onChange={() => setFilter('All')}
            />
            All
          </label>
          <label>
            <input
              type="radio"
              value="Opti"
              checked={filter === 'Opti'}
              onChange={() => setFilter('Opti')}
            />
            Opti
          </label>
          <label>
            <input
              type="radio"
              value="Opti Norge"
              checked={filter === 'Opti Norge'}
              onChange={() => setFilter('Opti Norge')}
            />
            Opti Norge
          </label>
          <label>
            <input
              type="radio"
              value="Opti Sverige"
              checked={filter === 'Opti Sverige'}
              onChange={() => setFilter('Opti Sverige')}
            />
            Opti Sverige
          </label>
        </div>
        {isLoading ? (
          <p className="alertcenter-loading">Loading members...</p>
        ) : (
          <div className="alertcenter-list">
                        <button onClick={handleSelectAll} className="alertcenter-button">Select/Unselect All</button>
            <ul>
              {filteredMembers.map((member) => (
                <li
                  key={member.id}
                  className={`alertcenter-list-item ${
                    selectedMembers.includes(member.id) ? 'alertcenter-selected' : ''
                  }`}
                  onClick={() => handleSelectMember(member.id)}
                >
                  {`${member.name} - ${member.id}`}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="alertcenter-alert-form">
          <h4>Send Alert</h4>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="alertcenter-input"
          />
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="alertcenter-textarea"
          />
          <button onClick={handleSendAlert} className="alertcenter-button">
            Send Alert
          </button>
        </div>
      </div>

      {/* Høyre kolonne */}
      <div className="alertcenter-column">
        <table className="custom-alert-table">
          <thead>
            <tr>
              <th>Til</th>
              <th>Tittel</th>
              <th>Melding</th>
              <th>Dato</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification) => (
              <tr key={notification.id}>
                <td>{notification.to || 'N/A'}</td>
                <td>{notification.title}</td>
                <td>{notification.body}</td>
                <td>{new Date(notification.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


    </div>
  );
};

export default AlertCenter;
