import React, { useState, useEffect } from 'react';
import './Messages.css';
export default function Messages() {
    const [messages, setMessages] = useState([]); // Liste over meldinger
    const [newMessage, setNewMessage] = useState({ title: '', message: '' }); // For ny melding
    const [previewMessage, setPreviewMessage] = useState(''); // Forhåndsvisning av meldingen
    const [selectedMessage, setSelectedMessage] = useState(null); // Valgt melding for visning
    const [showDetails, setShowDetails] = useState(false); // Kontroll for detaljvisning

    // Hent meldinger
    const fetchMessages = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages`);
            const data = await response.json();
            if (response.ok) {
                setMessages(data);
            } else {
                console.error('Failed to fetch messages:', data.message);
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    // Opprett en ny melding
    const createMessage = async () => {
        try {
            // Konverter linjeskift til <br> før meldingen sendes til backend
            const formattedMessage = newMessage.message.replace(/\n/g, '<br>');
    
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...newMessage, message: formattedMessage }), // Lagre meldingen med <br>
            });
    
            if (response.ok) {
                setNewMessage({ title: '', message: '' });
                setPreviewMessage('');
                fetchMessages(); // Oppdater meldingslisten
            } else {
                console.error('Failed to create message.');
            }
        } catch (error) {
            console.error('Error creating message:', error);
        }
    };
    

    const handleMessageChange = (e) => {
        const input = e.target.value;

        // Konverter linjeskift til <br>
        const formatted = input.replace(/\n/g, '<br>');

        setNewMessage({ ...newMessage, message: input }); // Lagre meldingen som råtekst
        setPreviewMessage(formatted); // Oppdater forhåndsvisning
    };

    // Sett inn fet tekst rundt markert område
    const addBoldToSelection = () => {
        const textarea = document.getElementById('messageTextarea');
        const { selectionStart, selectionEnd } = textarea;

        if (selectionStart === selectionEnd) {
            // Hvis ingen tekst er markert
            console.warn('No text selected to make bold.');
            return;
        }

        const message = newMessage.message;
        const before = message.substring(0, selectionStart);
        const selected = message.substring(selectionStart, selectionEnd);
        const after = message.substring(selectionEnd);

        const updatedMessage = `${before}<b>${selected}</b>${after}`;

        setNewMessage({ ...newMessage, message: updatedMessage });
        setPreviewMessage(updatedMessage.replace(/\n/g, '<br>')); // Oppdater forhåndsvisning
    };

    useEffect(() => {
        fetchMessages();
    }, []);


    // Slett en melding
    const deleteMessage = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                fetchMessages(); // Oppdater meldingslisten
            } else {
                console.error('Failed to delete message.');
            }
        } catch (error) {
            console.error('Error deleting message:', error);
        }
    };

    // Hent detaljvisning for melding
    const fetchMessageDetails = (message) => {
        setSelectedMessage(message);
        setShowDetails(true);
    };

    useEffect(() => {
        fetchMessages();
    }, []);

    return (
        <div className="admin-messages-container">
            {/* Opprett ny melding */}
            <div className="admin-messages-new-message">
                <h2>Create a new message</h2>
                <input
                    type="text"
                    placeholder="Title"
                    value={newMessage.title}
                    onChange={(e) => setNewMessage({ ...newMessage, title: e.target.value })}
                />
                <div className="formatting-buttons">
                    <button onClick={addBoldToSelection}>Add Bold</button>
                </div>
                <textarea
                    id="messageTextarea"
                    placeholder="Message"
                    value={newMessage.message}
                    onChange={handleMessageChange}
                />
                <h3>Preview</h3>
                <div
                    className="message-preview"
                    dangerouslySetInnerHTML={{ __html: previewMessage }}
                ></div>
                <button onClick={createMessage}>Create</button>
            </div>

            {/* Liste over meldinger */}
            <div className="admin-messages-list">
                <h2>Existing Messages</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Read By</th>
                            <th>Confirmed By</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {messages.map((message) => (
                            <tr key={message.id}>
                                <td onClick={() => setSelectedMessage(message)} style={{ cursor: 'pointer' }}>
                                    {message.title}
                                </td>
                                <td>{message.readby ? JSON.parse(message.readby).length : 0}</td>
                                <td>{message.confirmedby ? JSON.parse(message.confirmedby).length : 0}</td>
                                <td>
                                    <button onClick={() => deleteMessage(message.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Detaljvisning for melding */}
            {showDetails && selectedMessage && (
                <div className="admin-messages-details">
                    <h2>Message Details</h2>
                    <h3>{selectedMessage.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: selectedMessage.message }}></p>
                    <h4>Read By:</h4>
                    <ul>
                        {selectedMessage.readby ? JSON.parse(selectedMessage.readby).map((userId) => (
                            <li key={userId}>User ID: {userId}</li>
                        )) : <li>No users have read this message.</li>}
                    </ul>
                    <h4>Confirmed By:</h4>
                    <ul>
                        {selectedMessage.confirmedby ? JSON.parse(selectedMessage.confirmedby).map((userId) => (
                            <li key={userId}>User ID: {userId}</li>
                        )) : <li>No users have confirmed this message.</li>}
                    </ul>
                    <button onClick={() => setShowDetails(false)}>Close</button>
                </div>
            )}
        </div>
    );
}