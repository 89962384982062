import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const FinanceOverview = () => {
    const [chartData, setChartData] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/finance-overview/${year}`);
                const data = await response.json();
                // console.log('Fetched chart data:', data);

                // Forbered grafdata
                const parsedData = data.map(item => ({
                    totalCharged: Number(item.total_charged),
                    externalRatio: Number(item.external_ratio),
                    combinedRatio: Number(item.made_ratio) + Number(item.derived_ratio),
                    swiftecRatio: Number(item.swiftec_ratio),
                    ecudRatio: Number(item.ecud_ratio),
                    hundredRatio: Number(item.hundred_ratio),
                }));

                const labels = [
                    'January', 'February', 'March', 'April', 'May', 'June',
                    'July', 'August', 'September', 'October', 'November', 'December'
                ];

                if (isMounted) {
                    setChartData({
                        labels,
                        datasets: [
                            {
                                label: 'Total Charged',
                                data: parsedData.map(item => item.totalCharged),
                                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                                borderColor: 'rgba(75, 192, 192, 1)',
                                borderWidth: 1,
                                type: 'bar',
                                yAxisID: 'y', // Sørg for at dette kobles til riktig akse
                            },
                            {
                                label: 'External Ratio %',
                                data: parsedData.map(item => item.externalRatio),
                                borderColor: 'rgba(255, 99, 132, 1)',
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                fill: false,
                                type: 'line',
                                yAxisID: 'y2', // Sørg for at dette kobles til prosentaksen
                                tension: 0.3,
                            },
                            {
                                label: 'Made & Derived %',
                                data: parsedData.map(item => item.combinedRatio),
                                borderColor: 'rgba(54, 162, 235, 1)',
                                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                                fill: false,
                                type: 'line',
                                yAxisID: 'y2',
                                tension: 0.3,
                            },
                            {
                                label: 'Swiftec %',
                                data: parsedData.map(item => item.swiftecRatio),
                                borderColor: 'rgba(255, 159, 64, 1)',
                                backgroundColor: 'rgba(255, 159, 64, 0.5)',
                                fill: false,
                                type: 'line',
                                yAxisID: 'y2',
                                tension: 0.3,
                            },
                            {
                                label: '100 %',
                                data: parsedData.map(item => item.hundredRatio),
                                borderColor: 'rgba(201, 203, 207, 1)',
                                backgroundColor: 'rgba(201, 203, 207, 0.5)',
                                fill: false,
                                type: 'line',
                                yAxisID: 'y2',
                                tension: 0.3,
                            },
                        ],
                    });
                }
            } catch (error) {
                console.error('Error fetching chart data:', error);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [year]);

    const handleYearChange = (e) => {
        setYear(Number(e.target.value));
    };

    return (
        <div>
            <label htmlFor="year">Select Year:</label>
            <select id="year" value={year} onChange={handleYearChange}>
                {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map(y => (
                    <option key={y} value={y}>{y}</option>
                ))}
            </select>
            {loading ? (
                <p>Loading chart...</p>
            ) : (
                <div style={{ height: '400px', width: '800px', marginTop: '20px' }}>
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: `Total Credits Withdrawn & Origins for ${year}`,
                                },
                                tooltip: {
                                    callbacks: {
                                        label: (tooltipItem) => {
                                            if (tooltipItem.dataset.label.endsWith('%')) {
                                                return `${tooltipItem.dataset.label} : ${tooltipItem.raw.toFixed(2)}%`;
                                            }
                                            return `${tooltipItem.dataset.label}: ${tooltipItem.raw.toLocaleString()}`;
                                        },
                                    },
                                },
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    max: 10000,
                                    min: 0,
                                    ticks: {
                                        stepSize: 1000,
                                        callback: function (value) {
                                            return value.toLocaleString();
                                        },
                                    },
                                },
                                y2: {
                                    position: 'right',
                                    grid: {
                                        drawOnChartArea: true,
                                    },
                                    beginAtZero: true,
                                    min: 0,
                                    max: 100,
                                    ticks: {
                                        stepSize: 10,
                                        callback: function (value) {
                                            return `${value}%`;
                                        },
                                    },
                                },
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default FinanceOverview;
