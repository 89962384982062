import React, { useState, useCallback } from 'react';

const ManualsSearch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedManual, setSelectedManual] = useState(null);

    const handleSearch = useCallback(async () => {
        // console.log('Starting search for:', searchTerm);

        if (!searchTerm.trim()) {
            setSearchResults([]);
            setSelectedManual(null);
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_SITE_URL}/ocsearch/manuals?query=${encodeURIComponent(searchTerm)}`
            );
            if (!response.ok) {
                throw new Error('Failed to fetch search results');
            }

            const data = await response.json();
            // console.log('Search Results:', data);
            setSearchResults(data);
            setSelectedManual(null);  // Clear manual content on search
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchResults([]);
        }
    }, [searchTerm]);

    const handleManualClick = async (manualId) => {
        // console.log('Clicked Manual ID:', manualId);

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manual/${manualId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch manual content');
            }

            const manual = await response.json();
            // console.log('Fetched Manual:', manual);

            if (!manual || !manual.content) {
                throw new Error('Manual content not found');
            }

            setSelectedManual(manual);
        } catch (error) {
            console.error('Error fetching manual content:', error.message);
            setSelectedManual(null);
        }
    };

    const renderContent = () => {
        if (selectedManual) {
            return (
                <div className="manuals-message">
                    <button className='confirm-button' onClick={() => setSelectedManual(null)}>Back to Search Results</button>
                    <h3 className="manuals-user-info">{selectedManual.title || 'Untitled Manual'}</h3>
                    <div className="manuals-message-content"
                        dangerouslySetInnerHTML={{
                            __html: selectedManual.content || '<p>No content available.</p>',
                        }}
                    />
                    <br></br>
                </div>
            );
        }

        return (
            <div>
                {searchResults.map((manual) => (
                    <li className="manuals-topic" key={manual.id} onClick={() => handleManualClick(manual.id)}>
                        {manual.name || `Manual ID: ${manual.id}`}
                    </li>
                ))}
            </div>
        );
    };

    return (
        <div className="">
            <div className="manuals-title-list">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className='confirm-button' style={{marginLeft: '5px'}} onClick={handleSearch}>Search</button>
            </div>
            <div className="search-results-content2">{renderContent()}</div>
        </div>
    );
};

export default ManualsSearch;
