import React, { useState, useEffect, useRef } from 'react';
import OptischoolAdmin from './OptischoolAdmin';
import './Optischool.css';

function Optischool({ userProfile }) { // Mottar userProfile som prop
  const [categories, setCategories] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedModules, setExpandedModules] = useState({}); // Keeps modules for each category
  const [expandedModule, setExpandedModule] = useState(null);
  const [videos, setVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [answers, setAnswers] = useState({}); // Tracks selected answers
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const videoRef = useRef(null); // Reference to the video element
  const [disableSubmit, setDisableSubmit] = useState(false); // Disable submit button

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/categories`);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchModules = async (catid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/modules?catid=${catid}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching modules:', error);
      return [];
    }
  };

  const fetchVideos = async (modulid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/videos?modulid=${modulid}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching videos:', error);
      return [];
    }
  };

  const fetchQuizQuestions = async (videoid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/questions?videoid=${videoid}`);
      const data = await response.json();
      setQuizQuestions(data);
    } catch (error) {
      console.error('Error fetching quiz questions:', error);
    }
  };

  const handleCategoryClick = async (category) => {
    if (videoRef.current) {
      videoRef.current.pause(); // Stop video
      videoRef.current.load(); // Reset video
    }

    if (expandedCategory === category.catid) {
      setExpandedCategory(null); // Collapse category
      setExpandedModules((prev) => ({ ...prev, [category.catid]: null }));
    } else {
      setExpandedCategory(category.catid); // Expand category
      setExpandedModule(null); // Reset expanded module
      const modules = await fetchModules(category.catid);
      setExpandedModules((prev) => ({ ...prev, [category.catid]: modules }));
      setSelectedVideoUrl(null); // Clear video
    }
  };

  const handleModuleClick = async (module) => {
    if (videoRef.current) {
      videoRef.current.pause(); // Stop video
      videoRef.current.load(); // Reset video
    }

    if (expandedModule === module.modulid) {
      setExpandedModule(null); // Collapse module
      setVideos([]); // Clear videos
    } else {
      setExpandedModule(module.modulid); // Expand module
      const videos = await fetchVideos(module.modulid);
      setVideos(videos);
      setSelectedVideoUrl(null); // Clear video
    }
  };

  const handleVideoClick = (video) => {
    if (videoRef.current) {
      videoRef.current.pause(); // Stop current video
      videoRef.current.load(); // Reset video
    }
    setActiveVideo(video.videoid);
    setSelectedVideoUrl(video.videourl);
    fetchQuizQuestions(video.videoid); // Fetch quiz questions for the selected video
  };

  const handleVideoEnd = () => {
    console.log('Video has ended. Showing quiz modal.');
    setFeedback('');
    setShowQuizModal(true);
  };

  const handleAnswerSelect = (questionId, answerId) => {
    setAnswers((prev) => ({ ...prev, [questionId]: answerId }));
  };

  const handleSubmitAnswers = async () => {
    try {
        setDisableSubmit(true); // Disable submit button after click
      const formattedAnswers = quizQuestions.map((question) => ({
        quizid: question.id, // Quiz ID
        mid: userProfile.id, // Brukerens ID
        questionid: question.questionid, // Spørsmålets ID
        answerid: answers[question.questionid], // Valgte svaralternativ
      }));
  
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/elearning/answers`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answers: formattedAnswers }),
      });
  
      if (response.ok) {
        const feedbackData = await response.json(); // Henter tilbakemelding fra serveren
        const correctAnswers = feedbackData.correctAnswers; // Array med riktige svar
  
        // Sjekker om alle svar er riktige
        const allCorrect = quizQuestions.every(
          (question) => correctAnswers[question.questionid] === answers[question.questionid]
        );
        const feedbackElement = document.querySelector('.quiz-feedback p');
      feedbackElement.style.animation = allCorrect ? 'blinkGreen 1s infinite' : 'blinkRed 1s infinite';


        if (allCorrect) {
            setFeedback('You answered all questions correctly!');
            feedbackElement.style.animation = 'blinkGreen 1s infinite';
          setTimeout(() => {

            setShowQuizModal(false);
            setShowFeedback(false);
            setAnswers({}); // Nullstill valgte svar
            setDisableSubmit(false); // Re-enable submit button
          }, 5000);


          
        } else {
            setFeedback('Some answers were incorrect. Please try again.');
            feedbackElement.style.animation = 'blinkRed 1s infinite';

          setTimeout(() => {

            setShowQuizModal(false);
            setShowFeedback(false);
            setAnswers({}); // Nullstill valgte svar
            setDisableSubmit(false); // Re-enable submit button
          }, 5000);
        }
  
        // Marker spørsmål som riktige eller feil
        const updatedQuestions = quizQuestions.map((question) => ({
          ...question,
          isCorrect: correctAnswers[question.questionid] === answers[question.questionid],
        }));
  
        setQuizQuestions(updatedQuestions);
        setShowFeedback(true); // Vis tilbakemelding
      } else {
        console.error('Error submitting answers:', await response.text());
      }
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };
  


  return (
    <div className="optischool-container">
      <h1>Optischool</h1>
      <button onClick={() => setShowAdminPanel(!showAdminPanel)}>
        {showAdminPanel ? 'Hide Admin Panel' : 'Show Admin Panel'}
      </button>

      {showAdminPanel ? (
        <OptischoolAdmin />
      ) : (
        <div className="optischool-layout">
          <div className="optischool-sidebar">
            {categories.map((category) => (
              <div key={category.catid}>
                <div
                  className={`category-box ${expandedCategory === category.catid ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.name}
                </div>
                {expandedCategory === category.catid && (
                  <div className="modules-list">
                    {(expandedModules[category.catid] || []).map((module) => (
                      <div key={module.modulid}>
                        <div
                          className={`module-box ${expandedModule === module.modulid ? 'active' : ''}`}
                          onClick={() => handleModuleClick(module)}
                        >
                          {module.name}
                        </div>
                        {expandedModule === module.modulid && (
                          <div className="videos-list">
                            {videos.map((video) => (
                              <div
                                key={video.videoid}
                                className={`video-box ${activeVideo === video.videoid ? 'active' : ''}`}
                                onClick={() => handleVideoClick(video)}
                              >
                                {video.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="optischool-video-player">
            {selectedVideoUrl ? (
              <video
                ref={videoRef}
                controls
                src={selectedVideoUrl}
                autoPlay
                width="100%"
                height="100%"
                onEnded={handleVideoEnd} // Trigger quiz modal on video end
              >
                Your browser does not support the video tag.
              </video>
            ) : (
              <p>Select a video to play</p>
            )}
          </div>

          {showQuizModal && (
            <div className="quiz-modal">
              <h2>Quiz</h2>
              <div className="quiz-content">
                {quizQuestions.map((question) => (
                  <div key={question.questionid} className="quiz-question">
                    <p>{question.question}</p>
                    <ul>
                      {[1, 2, 3, 4].map((optionIndex) => {
                        const answerText = question[`answer${optionIndex}`];
                        if (!answerText) return null;

                        return (
                          <li
                            key={optionIndex}
                            className={`quiz-answer ${answers[question.questionid] === optionIndex ? 'selected' : ''}`}
                            onClick={() => handleAnswerSelect(question.questionid, optionIndex)}
                          >
                            {answerText}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="quiz-feedback">
                <p>{feedback}</p>

                {!disableSubmit && ( // Rendrer kun knappen hvis disableSubmit er false
  <button
    onClick={handleSubmitAnswers}
    disabled={Object.keys(answers).length !== quizQuestions.length}
  >
    Submit Answers
  </button>
)}

              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Optischool;
