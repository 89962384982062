import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import logo from '../assets/logo.png'; // Juster stien til den faktiske plasseringen

export default function TopBar({ openDatabaseModal, openUploadModal }) {
    const { authToken } = useAuth(); // Hent authToken
    const [userId, setUserId] = useState(null); // Brukerens ID
    const [pendingFilesCount, setPendingFilesCount] = useState(0); // State for antall filer i kø
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0); // State for uleste meldinger
    const nuh = new Date().toLocaleTimeString('no-NO', { timeZone: 'Europe/Oslo' });
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(5); // Start med 5 sekunder

    const fetchUserIdFromToken = () => {
        try {
            if (authToken) {
                const tokenParts = authToken.split('.');

                if (tokenParts.length < 3) {
                    console.error('Invalid JWT token format.');
                    return null;
                }
                const decodedPayload = JSON.parse(atob(tokenParts[1])); // Dekod JWT-payload

                if (!decodedPayload.userId) {
                    console.error('userId not found in decoded token payload.');
                    return null;
                }
                return decodedPayload.userId; // Returner "userId" fra payloaden
            } else {
                console.warn('No auth token available.');
            }
        } catch (error) {
            console.error('Error decoding auth token:', error);
        }
        return null;
    };
    
    const fetchUnreadMessagesCount = async () => {
        if (!userId) {
            console.warn('fetchUnreadMessagesCount called without a valid userId');
            return;
        }
    
        // console.log('Fetching unread messages count and details for userId:', userId);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages/unread-count?userId=${userId}`);
            const data = await response.json();
    
            if (response.ok) {
                // console.log('Unread messages count fetched:', data.unreadCount);
                // console.log('Unread messages details fetched:', data.unreadMessages);
                setUnreadMessagesCount(data.unreadCount);
                handlePopupLogic(data.unreadMessages); // Kall popup-logikk med meldingsdetaljer
            } else {
                console.error('Failed to fetch unread messages count:', data.message);
            }
        } catch (error) {
            console.error('Error fetching unread messages count and details:', error);
        }
    };
    
    const handlePopupLogic = (messages) => {
        const now = new Date();
        const threeDaysAgo = new Date(now);
        threeDaysAgo.setDate(now.getDate() - 3);
    
        const sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(now.getDate() - 7);
    
        const oldMessages = messages.filter((message) => {
            const createdDate = new Date(message.created);
            return createdDate <= threeDaysAgo;
        });
    
        if (oldMessages.length > 0) {
            const isOlderThanSevenDays = oldMessages.some((message) => {
                const createdDate = new Date(message.created);
                return createdDate <= sevenDaysAgo;
            });
    
            setPopupMessage(
                <>
                    You have {oldMessages.length} unread messages older than {isOlderThanSevenDays ? '7' : '3'} days.
                    <br />
                    Please read messages to avoid getting your work interrupted.
                </>
            );
            
    
            if (isOlderThanSevenDays) {
                setCloseButtonDisabled(true); // Deaktiver close-knappen
                setCountdown(5); // Sett nedtellingen til 5 sekunder
                const countdownInterval = setInterval(() => {
                    setCountdown((prev) => {
                        if (prev <= 1) {
                            clearInterval(countdownInterval); // Stopp nedtellingen når den når 0
                            setCloseButtonDisabled(false); // Aktiver close-knappen
                            return 0;
                        }
                        return prev - 1; // Reduser nedtellingen
                    });
                }, 1000); // Oppdater hvert sekund
            }
    
            setShowPopup(true); // Vis popup
        } else {
            setShowPopup(false); // Ingen popup hvis ingen meldinger er eldre enn 3 dager
        }
    };
    

    

    const fetchPendingFilesCount = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/pending-count`);
            const data = await response.json();

            if (response.ok) {
                // console.log('Pending files count fetched:', data.pendingCount);
                setPendingFilesCount(data.pendingCount);
            } else {
                console.error('Failed to fetch pending files count:', data.message);
            }
        } catch (error) {
            console.error('Error fetching pending files count:', error);
        }
    };

    useEffect(() => {
        if (authToken) {
            const id = fetchUserIdFromToken();
            if (id) {
                // console.log('User ID retrieved from token:', id);
                setUserId(id); // Sett userId
            }
        }
    }, [authToken]);

    // Hent uleste meldinger når userId er satt
    useEffect(() => {
        if (userId) {
            // console.log('User ID set. Fetching unread messages count...');
            fetchUnreadMessagesCount();
        }
    }, [userId]);
    



    useEffect(() => {
        const interval = setInterval(() => {
            fetchPendingFilesCount(); // Hent filer i kø

            if (userId) {
                fetchUnreadMessagesCount(); // Hent uleste meldinger kun hvis userId er satt
            }
        }, 30000); // Hent data hvert 30. sekund

        return () => clearInterval(interval); // Rydd opp når komponenten demonteres
    }, [userId]); // Avhengighet til userId


    


    const getQueueClass = () => {
        if (pendingFilesCount === 0) {
            return 'queue-none';
        } else if (pendingFilesCount >= 1 && pendingFilesCount <= 3) {
            return 'queue-low';
        } else if (pendingFilesCount >= 4 && pendingFilesCount <= 5) {
            return 'queue-medium';
        } else if (pendingFilesCount >= 6) {
            return 'queue-high';
        }
    };
    const navigate = useNavigate();
    return (
        <nav>
            <img 
                src={logo} 
                alt="Logo" 
                className="logo" 
                onClick={() => navigate('/')} // Redirects to the FilePortal
                style={{ cursor: 'pointer' }} // Gjør det tydelig at logoen er klikkbar
            />
            <ul class="topbarlinks">
                <li><Link to="/" onClick={openUploadModal}>UPLOAD</Link></li>
                <li><Link to="/">FILEPORTAL</Link></li>
                <li><Link to="/" onClick={openDatabaseModal}>DATABASE</Link></li>
                <li><Link to="/opticenter">SUPPORT</Link></li>
            </ul>

            {unreadMessagesCount > 0 && (
                <div className="messagenumbercontainer">            
                    <div className="messagenumbertext">
                    📩 {unreadMessagesCount} Unread messages
                    </div>
                </div>
            )}
            <div className="queuecontainer">            
                <div className={`queuetext ${getQueueClass()}`}>
                    {pendingFilesCount} FILES IN QUEUE {nuh}
                </div>
            </div>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button
                            onClick={() => setShowPopup(false)}
                            disabled={closeButtonDisabled}
                            style={{ opacity: closeButtonDisabled ? 0.5 : 1 }}
                        >
                            {closeButtonDisabled ? `Close (${countdown}s)` : 'Close'}
                        </button>
                    </div>
                </div>
            )}



        </nav>
    );
}
