import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function ManualsManualManager({
  categories,
  fetchCategories,
//   selectedCategory,
//   setSelectedCategory,
//   selectedSubcategory,
//   setSelectedSubcategory,
onClose,
}) {
  const [manualTitle, setManualTitle] = useState('');
  const [manualContent, setManualContent] = useState('');
  const [manuals, setManuals] = useState([]);
  const [editingManualId, setEditingManualId] = useState(null);
  const [editManualTitle, setEditManualTitle] = useState('');
  const [editManualContent, setEditManualContent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);

  const [subcategories, setSubcategories] = useState([]);
  const [subSubcategories, setSubSubcategories] = useState([]);

  const quillRef = useRef(null);

  
  // Fetch manuals when a sub-subcategory is selected
  useEffect(() => {
    if (selectedSubSubcategory) {
      fetchManuals(selectedSubSubcategory.id);
    } else {
      setManuals([]); // Reset manuals if no sub-subcategory selected
    }
  }, [selectedSubSubcategory]);

//   const fetchManuals = async (subSubcategoryId) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/${subSubcategoryId}`);
//         const data = await response.json();
//         setManuals(Array.isArray(data) ? data : []); // Ensure it's always an array
//     } catch (error) {
//         console.error('Error fetching manuals:', error);
//         setManuals([]); // Reset to empty array on error
//     }
// };



const fetchManuals = async (categoryId) => {
  try {
      if (!categoryId) {
          setManuals([]); // Clear manuals if no category is selected
          return;
      }

      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/category/${categoryId}`);
      if (!response.ok) {
          throw new Error(`Failed to fetch manuals for category ID: ${categoryId}`);
      }

      const data = await response.json();
      setManuals(Array.isArray(data) ? data : []); // Ensure it's always an array
  } catch (error) {
      console.error('Error fetching manuals:', error);
      setManuals([]); // Reset to an empty array on error
  }
};



  // Fetch subcategories when a category is selected
  const handleCategoryChange = async (categoryId) => {
    const category = categories.find((cat) => cat.id === Number(categoryId));
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setSelectedSubSubcategory(null);
    setSubcategories([]); // Reset subcategories and sub-subcategories
    setSubSubcategories([]);

    if (category) {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${category.id}/subcategories`);
        const data = await response.json();
        setSubcategories(data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    }
  };

  // Fetch sub-subcategories when a subcategory is selected
  const handleSubcategoryChange = async (subcategoryId) => {
    const subcategory = subcategories.find((sub) => sub.id === Number(subcategoryId));
    setSelectedSubcategory(subcategory);
    setSelectedSubSubcategory(null);
    setSubSubcategories([]); // Reset sub-subcategories

    if (subcategory) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategory.id}/sub-subcategories`
        );
        const data = await response.json();
        setSubSubcategories(data);
      } catch (error) {
        console.error('Error fetching sub-subcategories:', error);
      }
    }
  };

  const handleCreateManual = async () => {
    const manualData = {
      category_id: selectedCategory?.id || null,
      subcategory_id: selectedSubcategory?.id || null,
      subsubcategory_id: selectedSubSubcategory?.id || null,
      title: manualTitle.trim(),
      content: manualContent.trim(),
    };
  
    console.log('Payload being sent to backend:', manualData);
  
    if (!manualData.title || !manualData.content) {
      alert('Title and content are required.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(manualData),
      });
  
      if (response.ok) {
        const newManual = await response.json();
        console.log('Manual created successfully:', newManual);
        setManuals((prevManuals) => [...prevManuals, newManual]);
        setManualTitle('');
        setManualContent('');
      } else {
        const errorData = await response.json();
        console.error('Error creating manual:', errorData);
        alert(`Failed to create manual: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error creating manual:', error);
    }
  };
  
  
    // Edit a manual
    const handleEditManual = (manual) => {
        setEditingManualId(manual.id);
        setEditManualTitle(manual.title);
        setEditManualContent(manual.content);
    };

    // Update a manual
    // const handleUpdateManual = async () => {
    //     if (!editManualTitle.trim() || !editManualContent.trim()) {
    //         alert('All fields are required to update a manual.');
    //         return;
    //     }

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/${editingManualId}`, {
    //             method: 'PUT',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({
    //                 title: editManualTitle,
    //                 content: editManualContent,
    //             }),
    //         });

    //         if (response.ok) {
    //             setManuals((prevManuals) =>
    //                 prevManuals.map((manual) =>
    //                     manual.id === editingManualId ? { ...manual, title: editManualTitle, content: editManualContent } : manual
    //                 )
    //             );
    //             setEditingManualId(null);
    //             setEditManualTitle('');
    //             setEditManualContent('');
    //         } else {
    //             alert('Failed to update manual.');
    //         }
    //     } catch (error) {
    //         console.error('Error updating manual:', error);
    //     }
    // };

    const handleUpdateManual = async () => {
      if (!editManualTitle.trim() || !editManualContent.trim()) {
          alert('All fields are required to update a manual.');
          return;
      }
  
      try {
          const response = await fetch(
              `${process.env.REACT_APP_SITE_URL}/oc/manuals/${editingManualId}`, {
                  method: 'PUT',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                      title: editManualTitle,
                      content: editManualContent,
                  }),
              }
          );
  
          if (response.ok) {
              setManuals((prevManuals) =>
                  prevManuals.map((manual) =>
                      manual.id === editingManualId ? { ...manual, title: editManualTitle, content: editManualContent } : manual
                  )
              );
              setEditingManualId(null);
              setEditManualTitle('');
              setEditManualContent('');
          } else {
              const errorData = await response.json();
              alert(`Failed to update manual: ${errorData.error}`);
          }
      } catch (error) {
          console.error('Error updating manual:', error);
      }
  };
  

    // Delete a manual
    const handleDeleteManual = async (manualId) => {
        if (!window.confirm('Are you sure you want to delete this manual?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/${manualId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setManuals((prevManuals) => prevManuals.filter((manual) => manual.id !== manualId));
            } else {
                alert('Failed to delete manual.');
            }
        } catch (error) {
            console.error('Error deleting manual:', error);
        }
    };

    // Reactquill
    const handleImageUpload = () => {
      console.log("Image upload handler triggered");
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
    
      input.onchange = async () => {
        const file = input.files[0];
        console.log("Selected file:", file);
        if (!file) {
          alert("No file selected");
          return;
        }
    
        const formData = new FormData();
        formData.append("manualFile", file);
        console.log("FormData prepared:", formData);
    
        try {
          const res = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadManualFile`, {
            method: "POST",
            body: formData,
          });
    
          if (!res.ok) {
            throw new Error("Image upload failed");
          }
    
          const { fileUrl } = await res.json();
          console.log("File uploaded successfully. URL:", fileUrl);
    
          const quill = quillRef.current?.getEditor();
          if (!quill) {
            console.error("Quill instance not found");
            return;
          }
    
          const range = quill.getSelection();
          quill.insertEmbed(range.index, "image", fileUrl);
        } catch (error) {
          console.error("Error uploading image:", error);
          alert("Failed to upload image");
        }
      };
    };


    const modules = useMemo(
      () => ({
        toolbar: {
          container: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
          ],
          handlers: {
            image: handleImageUpload, // Custom handler for image uploads
          },
        },
      }),
      [] // Dependencies array to avoid re-creating the 'modules' object unnecessarily
    );

    return (
      <div>
        <h4>Manage Manuals</h4>
    
        {/* Dropdown for Categories */}
        {/* <div>
          <label htmlFor="category">Select Category</label>
          <select
            id="category"
            value={selectedCategory?.id || ''}
            onChange={async (e) => {
              const category = categories.find((cat) => cat.id === Number(e.target.value));
              setSelectedCategory(category);
              setSelectedSubcategory(null); // Reset subcategory
              setSelectedSubSubcategory(null); // Reset sub-subcategory
              setSubSubcategories([]); // Reset sub-subcategories
    
              if (category) {
                try {
                  const response = await fetch(
                    `${process.env.REACT_APP_SITE_URL}/oc/categories/${category.id}/subcategories`
                  );
                  const data = await response.json();
                  setSelectedCategory({ ...category, subcategories: data }); // Attach subcategories to category
                } catch (error) {
                  console.error('Error fetching subcategories:', error);
                }
              }
            }}
          >
            <option value="">-- Select Category --</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
            ))}
          </select>
        </div> */}

<div>
    <label htmlFor="category">Select Category</label>
    <select
        id="category"
        value={selectedCategory?.id || ''}
        onChange={(e) => {
            const category = categories.find((cat) => cat.id === Number(e.target.value));
            setSelectedCategory(category);
            fetchManuals(category?.id); // Fetch manuals based on the category ID
        }}
    >
        <option value="">-- Select Category --</option>
        {categories.map((category) => (
            <option key={category.id} value={category.id}>
                {category.title}
            </option>
        ))}
    </select>
</div>
  
    
        {/* Dropdown for Subcategories */}
        {selectedCategory && selectedCategory.subcategories && (
          <div>
            <label htmlFor="subcategory">Select Subcategory</label>
            <select
              id="subcategory"
              value={selectedSubcategory?.id || ''}
              onChange={async (e) => {
                const subcategory = selectedCategory.subcategories.find(
                  (sub) => sub.id === Number(e.target.value)
                );
                setSelectedSubcategory(subcategory);
                setSelectedSubSubcategory(null); // Reset sub-subcategory
                setSubSubcategories([]); // Reset sub-subcategories
    
                if (subcategory) {
                  try {
                    const response = await fetch(
                      `${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategory.id}/sub-subcategories`
                    );
                    const data = await response.json();
                    setSubSubcategories(data);
                  } catch (error) {
                    console.error('Error fetching sub-subcategories:', error);
                  }
                }
              }}
            >
              <option value="">-- Select Subcategory --</option>
              {selectedCategory.subcategories.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.title}
                </option>
              ))}
            </select>
          </div>
        )}
    
        {/* Dropdown for Sub-Subcategories */}
        {selectedSubcategory && (
          <div>
            <label htmlFor="subsubcategory">Select Sub-Subcategory</label>
            <select
              id="subsubcategory"
              value={selectedSubSubcategory?.id || ''}
              onChange={(e) =>
                setSelectedSubSubcategory(
                  subSubcategories.find((sub) => sub.id === Number(e.target.value))
                )
              }
            >
              <option value="">-- Select Sub-Subcategory --</option>
              {subSubcategories.map((subSubcategory) => (
                <option key={subSubcategory.id} value={subSubcategory.id}>
                  {subSubcategory.title}
                </option>
              ))}
            </select>
          </div>
        )}
    
        {/* Manuals Section */}
        <div>
          <input
            type="text"
            placeholder="Search manuals..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
    
          {manuals.length > 0 ? (
            <ul id="manuals-list">
              {manuals
                .filter((manual) =>
                  manual.title?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((manual) => (
                  <li key={manual.id}>
                    {editingManualId === manual.id ? (

                      <div className="manuals-modal-overlay">
                       <div className="manuals-modal-content" onClick={(e) => e.stopPropagation()}>
        
                        {/* Close Button */}
                        <button className="manuals-modal-close-button" onClick={() => setEditingManualId(null)}>
                          ✖
                        </button>
                        <div className="manuals-modal-main-content">
                        <h2> editing man</h2>
                        <input
                          type="text"
                          value={editManualTitle}
                          onChange={(e) => setEditManualTitle(e.target.value)}
                        />
                        <ReactQuill value={editManualContent} onChange={setEditManualContent} />
                        <button onClick={handleUpdateManual}>Save</button>
                        <button onClick={() => setEditingManualId(null)}>Cancel</button>
                      </div>
                      </div>
                      </div>
                    ) : (
                      <div  className="manuals-modal-categories-list-item">
                        <span>{manual.title || 'Untitled Manual'}</span>
                        
                        <div>
                        <button onClick={() => handleEditManual(manual)}>Edit</button>
                        <button onClick={() => handleDeleteManual(manual.id)}>Delete</button>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          ) : (
            <p>No manuals available for this selection.</p>
          )}
        </div>
    
        {/* Create Manual Section */}
        {console.log("Rendering Create Manual Section")} 
        <h4>Create New Manual</h4>
        <input
          type="text"
          value={manualTitle}
          onChange={(e) => setManualTitle(e.target.value)}
          placeholder="Manual Title"
        />
        <div>
        <ReactQuill
        ref={quillRef}
    value={manualContent}
    onChange={setManualContent}
    modules={modules} // Using the 'modules' constant with your handlers
    theme="snow"
  />
</div>

        <button
          onClick={handleCreateManual}
          disabled={!selectedCategory && !selectedSubcategory && !selectedSubSubcategory}
        >
          Create Manual
        </button>
      </div>
    );
  }    