import React from 'react';
import './Finances.css';
import FinancesOnline from './FinancesOnline';
import FinanceOverview from './FinanceOverview';
import FinancesInsights from './FinancesInsights';
import FinancesMetrics from './FinancesMetrics';



const Finances = () => {
    return (
        <div className="grid-container">
            <div className="grid-item grid-item-small">
                <FinancesOnline />
            </div>
            <div className="grid-item grid-item-insights">
                <FinancesInsights />
            </div>
            <div className="grid-item grid-item-finance">
                <FinanceOverview />
            </div>
            <div className="grid-item grid-item-metrics">
                <FinancesMetrics />
            </div>
        </div>
    );
};

export default Finances;
