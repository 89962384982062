import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function CreateManualModal({ isOpen, onClose, context }) {
    const [manualTitle, setManualTitle] = useState('');
    const [manualContent, setManualContent] = useState('');
    const quillRef = useRef(null);
  
    const handleCreateManual = async () => {
      const manualData = {
        category_id: context?.category?.id || null,
        subcategory_id: context?.subcategory?.id || null,
        subsubcategory_id: context?.subSubcategory?.id || null,
        title: manualTitle.trim(),
        content: manualContent.trim(),
      };
  
      console.log('Payload being sent to backend:', manualData);
  
      if (!manualData.title || !manualData.content) {
        alert('Title and content are required.');
        return;
      }
  
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(manualData),
        });
  
        if (response.ok) {
          const newManual = await response.json();
          console.log('Manual created successfully:', newManual);
          alert('Manual created successfully!');
          setManualTitle('');
          setManualContent('');
          onClose(); // Close the modal after successful creation
        } else {
          const errorData = await response.json();
          console.error('Error creating manual:', errorData);
          alert(`Failed to create manual: ${errorData.error || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Error creating manual:', error);
        alert('An unexpected error occurred while creating the manual.');
      }
    };

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) {
        alert('No file selected');
        return;
      }

      const formData = new FormData();
      formData.append('manualFile', file);

      try {
        const res = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadManualFile`, {
          method: 'POST',
          body: formData,
        });

        if (!res.ok) {
          throw new Error('Image upload failed');
        }

        const { fileUrl } = await res.json();

        const quill = quillRef.current?.getEditor();
        if (!quill) {
          console.error('Quill instance not found');
          return;
        }

        const range = quill.getSelection();
        quill.insertEmbed(range.index, 'image', fileUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image');
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
      ],
      handlers: {
        image: handleImageUpload,
      },
    },
  }), []); // Ensure useMemo is always called in the same order

  if (!isOpen) return null;

  return (
    <div className="manuals-modal-overlay">
      <div className="create-modal-content" 
      onClick={(e) => e.stopPropagation()}

        >
        {/* Close Button */}
        
        <button className="manuals-modal-close-button  admin-button" onClick={onClose}>
          ✖
        </button>
        
            <div className="manuals-modal-main-content">
                <h2>Create Manual</h2>
                <p>
                    <strong>Adding manual to: </strong>
                    <span>
                    {context?.category?.title && (
                        <>
                            <strong>{context.category.title}</strong>   
                       </>
                        )}
                    </span>
                    <span>
                    {context?.subcategory?.title && (
                        <>
                            <p style={{ color: 'red', display: 'inline', fontWeight: 'bolder'}}> -> </p> <strong>{context?.subcategory?.title}</strong>
                        </>
                        )}
                    </span>
                    <span>
                    {context?.subSubcategory?.title && (
                        <>
                            <p style={{ color: 'red', display: 'inline', fontWeight: 'bolder' }}> -> </p> <strong>{context?.subSubcategory?.title}</strong>
                        </>
                        )}
                    </span>
                </p>
                <div style={{width: '100%'}}>
                    <div>
                        <input
                        type="text"
                        value={manualTitle}
                        onChange={(e) => setManualTitle(e.target.value)}
                        placeholder="Manual Title"
                        />
                        </div>
                        <div>
                            
                        <ReactQuill
                        ref={quillRef}
                    value={manualContent}
                    onChange={setManualContent}
                    modules={modules} // Using the 'modules' constant with your handlers
                    theme="snow"
                    
                />

                </div>
                </div>
                <div className='left-buttons'>
                <button className="admin-button" onClick={handleCreateManual}>Create</button>
                <button className="admin-button" onClick={onClose}>Cancel</button>
                </div>
                <div style={{margin: '0px'}}>
                <p ><strong>PLEASE BE NOTED:</strong></p>
                <p style={{margin: '0px'}}>You are able to create manuals in every category regardless if the main category have sub categories.</p>
                <p style={{margin: '0px'}}>If the main category contains <strong>any subcategories</strong>, place the manual under the correct subcategory to avoid conflicts.
                    
                    </p> 
                </div>  
            </div>
      </div>
      
    </div>
  );
}
