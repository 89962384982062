import React, { useState, useEffect } from 'react';

export default function Orders() {
    const [orders, setOrders] = useState([]);
    const [memberProfiles, setMemberProfiles] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchMemberProfile = async (mid) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/members/profile/${mid}`);
            const data = await response.json();
            setMemberProfiles(prev => ({
                ...prev,
                [mid]: data.name
            }));
        } catch (error) {
            console.error(`Error fetching member profile for ${mid}:`, error);
            setMemberProfiles(prev => ({
                ...prev,
                [mid]: 'Unknown Member'
            }));
        }
    };

    const fetchOrders = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/orders`);
            const data = await response.json();
            setOrders(data);

            // Fetch member profiles for all unique member IDs
            const uniqueMemberIds = [...new Set(data.map(order => order.mid))];
            await Promise.all(uniqueMemberIds.map(mid => fetchMemberProfile(mid)));

            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setLoading(false);
        }
    };

    const handleDownload = async (orderId, invoiceFile) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/get-invoice/${orderId}`);
            const data = await response.json();

            // Use the invoiceUrl from the response
            const link = document.createElement('a');
            link.href = data.invoiceUrl;
            link.download = invoiceFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading invoice:', error);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    const formatCurrency = (amount) => {
        return parseFloat(amount).toLocaleString('nb-NO', {
            style: 'currency',
            currency: 'NOK'
        });
    };

    if (loading) {
        return <div>Loading orders...</div>;
    }

    return (
        <div>
            <h3>Orders</h3>
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Dealer</th>
                        <th>Date</th>
                        <th>Credits</th>
                        <th>Total</th>
                        <th>VAT</th>
                        <th>Invoice</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{memberProfiles[order.mid] || 'Loading...'}</td>
                            <td>{formatDate(order.created)}</td>
                            <td>{order.credits}</td>
                            <td>{formatCurrency(order.total)}</td>
                            <td>{formatCurrency(order.vat)}</td>
                            <td>
                            <button
                                    onClick={() => handleDownload(order.id, order.invoice_file)}
                                    className="download-button"
                                >
                                    Download Invoice
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}