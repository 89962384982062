import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import './Finances.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const FinancesMetrics = () => {
    const [metrics, setMetrics] = useState(null);
    const [historicalData, setHistoricalData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMetrics = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/finance-stats`);
                const data = await response.json();
                // console.log('Fetched metrics data:', data);

                setMetrics(data);
                setHistoricalData(data.historical || []);
            } catch (error) {
                console.error('Error fetching metrics data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMetrics();
    }, []);

    if (loading || !metrics) {
        return <p>Loading metrics...</p>;
    }

    const metricTitles = {
        day: 'Today',
        week: 'This Week',
        month: 'This Month',
        year: 'This Year',
        total: 'Total',
    };

    // Line chart data for income over time and avg income per upload
    const lineChartData = {
        labels: historicalData.map(item => item.date),
        datasets: [
            {
                label: 'Total Income (NOK)',
                data: historicalData.map(item => item.totalIncome),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                tension: 0.3,
            },
            {
                label: 'Avg Income per Upload (NOK)',
                data: historicalData.map(item => item.avgIncomePerUpload),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                tension: 0.3,
            },
        ],
    };

    return (
        <div className="metrics-container">
            <div className="metrics-grid">
                {/* Beskrivelser */}
                <div className="metric-header"></div>
                <div className="metric-header">Today</div>
                <div className="metric-header">This Week</div>
                <div className="metric-header">This Month</div>
                <div className="metric-header">This Year</div>
                <div className="metric-header">Total</div>
    
                {/* Data Rows */}
                <div className="metric-item"><strong>Uploads</strong></div>
                {Object.entries(metrics).map(([key, value]) => (
                    <div key={`${key}-uploads`} className="metric-item">
                        {value.total_uploads || 0}
                    </div>
                ))}
    
                <div className="metric-item"><strong>Credits Withdrawn</strong></div>
                {Object.entries(metrics).map(([key, value]) => (
                    <div key={`${key}-credits`} className="metric-item">
                        {value.total_credits || 0} credits
                    </div>
                ))}
    
                <div className="metric-item"><strong>Total Income from credits</strong></div>
                {Object.entries(metrics).map(([key, value]) => (
                    <div key={`${key}-income`} className="metric-item">
                        {(value.total_income || 0).toLocaleString()} NOK
                    </div>
                ))}
    
                <div className="metric-item"><strong>Avg Income per Upload</strong></div>
                {Object.entries(metrics).map(([key, value]) => (
                    <div key={`${key}-avg-income`} className="metric-item">
                        {value.total_uploads > 0
                            ? ((value.total_income || 0) / value.total_uploads).toFixed(2)
                            : 0}{' '}
                        NOK
                    </div>
                ))}
            </div>
        </div>
    );
    
    
};

export default FinancesMetrics;
