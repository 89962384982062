import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import './Finances.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const FinancesInsights = () => {
    const [insightsData, setInsightsData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchInsights = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/finance-insights`);
                const data = await response.json();
                // console.log('Fetched insights data:', data);
                setInsightsData(data);
            } catch (error) {
                console.error('Error fetching insights data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInsights();
    }, []);

    if (loading || !insightsData) {
        return <p>Loading insights...</p>;
    }

    // Extract data for the historical chart
    const { historicalData } = insightsData;

    // Validate and prepare data for the line chart
    const historicalChartData = {
        labels: historicalData.map(data => data.date),
        datasets: [
            {
                label: 'Credits Withdrawn',
                data: historicalData.map(data => data.credits || 0),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                tension: 0.3,
                fill: true,
            },
            {
                label: 'Files Uploaded',
                data: historicalData.map(data => data.uploads || 0),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                tension: 0.3,
                fill: true,
            },
        ],
    };

    return (
        <div className="insights-container">
            <div className="line-chart" style={{ height: '300px', width: '100%', marginTop: '20px' }}>
                <Line
                    data={historicalChartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Historical Evolvement',
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: 'Date',
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 12, // Begrens antall datoer som vises
                                },
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Count',
                                },
                                beginAtZero: true,
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
    
};

export default FinancesInsights;
