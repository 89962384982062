import React, { useEffect, useState } from 'react';

const RssFeed = () => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchLatestNews();
    }, []);

    const fetchLatestNews = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/latest-news`);
            const data = await response.json();
            console.log("Fetched news:", data); // Logg responsen
            setNews(data);
        } catch (error) {
            console.error('Error fetching latest news:', error);
            setNews([]); // Sett en tom array som fallback
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <div className="c1">
            {isLoading ? (
                <p>Loading...</p>
            ) : !Array.isArray(news) || news.length === 0 ? ( // Sjekk om `news` er en array
                <p>No news available.</p>
            ) : (
                <ul className="rss-feed-list">
                    {news.map((item) => (
                        <li key={item.id}>
                            <span className="rssnews-title">{item.title}</span>
                            <span className="rssnews-date">
                                {new Date(item.created).toLocaleDateString('no-NO')}
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
    
};

export default RssFeed;
