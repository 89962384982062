import React, { useEffect, useState } from 'react';
import './Finances.css';

const UsersOnline = () => {
    const [adminTuners, setAdminTuners] = useState([]);
    const [userStatus, setUserStatus] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [allMembers, setAllMembers] = useState([]);

    useEffect(() => {
        const fetchAdminTuners = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/adminmembers`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch members: ${response.status} ${response.statusText}`);
                }

                const members = await response.json();
                const filteredMembers = members.filter(
                    (member) => member.admin === 1 || member.tuner === 1
                );

                setAdminTuners(filteredMembers);
                setAllMembers(members); // Lagre alle medlemmer for den nye listen
            } catch (error) {
                console.error('Error fetching members:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchUserStatus = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/online-users`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch online users: ${response.status} ${response.statusText}`);
                }

                const statusData = await response.json();
                setUserStatus(statusData);
            } catch (error) {
                console.error('Error fetching user status:', error);
            }
        };

        fetchAdminTuners();
        fetchUserStatus();

        const interval = setInterval(fetchUserStatus, 10000);
        return () => clearInterval(interval);
    }, []);

    const formatLastSeen = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('no-NO', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const getUserStatus = (userId) => {
        const status = userStatus.find(status => status.id === userId);
        if (!status) return { online: false };
        return status;
    };

    const sortedNonAdminTuners = () => {
        return allMembers
            .filter((member) => member.admin !== 1 && member.tuner !== 1) // Ekskluder admin og tunere
            .map((member) => {
                const status = getUserStatus(member.id);
                return {
                    ...member,
                    online: status.online,
                    lastSeen: status.lastSeen || null,
                };
            })
            .sort((a, b) => {
                if (a.online === b.online) {
                    if (a.lastSeen && b.lastSeen) {
                        return new Date(b.lastSeen) - new Date(a.lastSeen);
                    }
                    return 0;
                }
                return a.online ? -1 : 1; // Online members first
            });
    };

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : adminTuners.length > 0 ? (
                <div>
                    <ul className="admin-tuner-list">
                        {adminTuners.map((member) => {
                            const status = getUserStatus(member.id);
                            return (
                                <li key={member.id}>
                                    <span className={`status-dot ${status.online ? 'online' : 'offline'}`}></span>
                                    {member.name}
                                    {!status.online && status.lastSeen && 
                                        ` (Last seen: ${formatLastSeen(status.lastSeen)})`
                                    }
                                </li>
                            );
                        })}
                    </ul>
                    <table className="admin-tuner-table">
                        <thead>
                            <tr>
                                {adminTuners.map((member) => (
                                    <th key={member.id}>{member.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {adminTuners.map((member) => (
                                    <td key={member.id}>{member.total_uploads || 0}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>

                    <ul className="online-members-list">
                        {sortedNonAdminTuners().map((member) => (
                            <li key={member.id}>
                                <span className={`status-dot ${member.online ? 'online' : 'offline'}`}></span>
                                {member.name} 
                                {!member.online && member.lastSeen && 
                                    ` (Last seen: ${formatLastSeen(member.lastSeen)})`}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>No admin or tuner members found.</p>
            )}
        </div>
    );
};

export default UsersOnline;
